import React, { useState, useEffect, useCallback } from 'react';
import {
  IconButton,
  Popover,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from 'react-redux';
import {
  PERMISSION_DESCRIPTIONS,
  PERMISSION_LABELS,
  PERMISSION_MAPPING,
  USER_ROLES,
} from '@/constants/roles';
import {
  useDashboardIdentitySoftDeleteMutation,
  useGetDashboardQuery,
  useGetDashboardRecentQuery,
  useUpdateDashboardSharingMutation,
} from '@/store/apis/dashboardsApi';
import { determineRole } from '../helpers/dashboardHelpers.js';
import style from './Share.module.scss';
import Button from '@/components/Button';

const ChangePermissionUser = ({
  dashboardId,
  identityId,
  currentPermission,
  onPermissionChange,
  isRemovePermission,
}) => {
  const { items } = useSelector((state) => state.dashboards);
  const item = Object.values(items).find((dashboard) => dashboard.dashboardId === dashboardId);
  const removePermissionItem = isRemovePermission ? ['', 'Remove Access'] : [];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPermission, setSelectedPermission] = useState(
    USER_ROLES.VIEWER,
  );
  const [isToRefetch, setIsToRefetch] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [oldPermission, setOldPermission] = useState('');

  const { refetch: refetchGetDashboardRecent } = useGetDashboardRecentQuery(
    {},
    { skip: !isToRefetch },
  );
  const { refetch: refetchGetDashboard } = useGetDashboardQuery(
    { id: item?.id },
    { skip: !isToRefetch },
  );

  const [updateDashboardSharing, { isLoading }] = useUpdateDashboardSharingMutation();
  const [dashboardIdentitySoftDelete] = useDashboardIdentitySoftDeleteMutation();

  useEffect(() => {
    if (currentPermission) {
      setSelectedPermission(determineRole(currentPermission));
    }
  }, [currentPermission]);
  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  }, [showToast]);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handlePermissionChange = useCallback(async (role) => {
    setShowToast(false);
    setIsToRefetch(true);
    setOldPermission(selectedPermission);
    setSelectedPermission(role);

    const updatedPermissions = role
      ? `{${PERMISSION_MAPPING[role].join(', ')}}`
      : '{}';

    handleClosePopover();

    if (onPermissionChange) {
      onPermissionChange(updatedPermissions);
    } else {
      if (updatedPermissions === '{}') {
        dashboardIdentitySoftDelete({
          dashboardId,
          identityId,
        });
      } else {
        updateDashboardSharing({
          dashboardId,
          identityId,
          grantedPermissionTagIds: updatedPermissions,
        });
      }
      setShowToast(true);
    }
    await refetchGetDashboardRecent();
    await refetchGetDashboard();
    setIsToRefetch(false);
  }, [selectedPermission]);

  const handleUndoPermissionChange = useCallback(async () => {
    setIsToRefetch(true);
    setOldPermission(selectedPermission);
    setSelectedPermission(oldPermission);

    const updatedPermissions = oldPermission
      ? `{${PERMISSION_MAPPING[oldPermission].join(', ')}}`
      : '{}';

    setShowToast(false);

    if (onPermissionChange) {
      onPermissionChange(updatedPermissions);
    } else if (updatedPermissions === '{}') {
      dashboardIdentitySoftDelete({
        dashboardId,
        identityId,
      });
    } else {
      updateDashboardSharing({
        dashboardId,
        identityId,
        grantedPermissionTagIds: updatedPermissions,
      });
    }
    await refetchGetDashboardRecent();
    await refetchGetDashboard();
    setIsToRefetch(false);
  }, [oldPermission, selectedPermission]);

  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" gap={1}>
      {showToast && (
        <Box className={style.toast}>
          <Typography variant="caption" color="rgba(0, 0, 0, 0.54)">Permission changed</Typography>
          <Button variant="text" color="primary" onClick={handleUndoPermissionChange}>
            <Typography variant="caption">Undo</Typography>
          </Button>
        </Box>
      )}
      <Box width={108} display="flex" justifyContent="flex-end">
        {isLoading ? (
          <CircularProgress size={16} color="primary" sx={{ marginRight: 1.5 }} />
        ) : (
          <IconButton
            size="small"
            sx={{ borderRadius: 1.5 }}
            onClick={handleOpenPopover}
          >
            <Typography variant="body2">
              {PERMISSION_LABELS[selectedPermission]}
            </Typography>
            <KeyboardArrowDownIcon />
          </IconButton>
        )}
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box padding={0.5} width={300}>
          {[...Object.entries(PERMISSION_LABELS), removePermissionItem].map(
            ([role, label]) => (
              role && role !== USER_ROLES.OWNER && role !== USER_ROLES.CREATOR && (
                <Box
                  key={role}
                  onClick={() => handlePermissionChange(role)}
                  className={style.permissionBox}
                >
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                    position="relative"
                    flexGrow={1}
                    gap={0.5}
                  >
                    <Typography variant="body2" sx={{ width: '100%' }}>
                      {label}
                    </Typography>
                    {role && (
                      <Typography variant="caption" color="gray">
                        {PERMISSION_DESCRIPTIONS[role]}
                      </Typography>
                    )}
                  </Box>
                  {role === selectedPermission && (
                    <CheckIcon fontSize="small" color="inherit" />
                  )}
                </Box>
              )
            ),
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default ChangePermissionUser;
