export const insightTypes = {
  table: 'table',
  heatmap: 'heatmap',
  explore: 'explore',
};

export const insightTypeLabels = {
  [insightTypes.table]: 'Table',
  [insightTypes.heatmap]: 'Heatmap',
  [insightTypes.explore]: 'Explore',
};