import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { api } from '@/constants';

export const audiencesApi = createApi({
  reducerPath: 'audiencesApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${api.hostname}/api/${api.server}/v2/audiences` }),
  tagTypes: ['Audiences'],
  endpoints: (builder) => ({
    getAudiences: builder.query({
      query: ({
        query, popularityMax, tags, take,
      }) => {
        const searchParams = new URLSearchParams();

        if (query) searchParams.append('filter.query', query);
        if (popularityMax) searchParams.append('filter.popularity.max', popularityMax);
        if (take) searchParams.append('take', take);
        if (tags) searchParams.append('filter.tags', tags);

        return `?${searchParams.toString()}`;
      },
      providesTags: ['Audiences'],
    }),
  }),
});

export const {
  useGetAudiencesQuery,
} = audiencesApi;
