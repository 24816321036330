import React from 'react';

import useIdentityCanAccessRoute from '../../hooks/useIdentityCanAccessRoute';
import RequestAccess from './RequestAccess';

const IdentityAware = ({
  element,
}) => {
  const {
    hasAccess,
    identity,
    missingAccess,
    permission,
  } = useIdentityCanAccessRoute();

  if (!identity) {
    return null;
  }

  if (!hasAccess) {
    return (
      <RequestAccess
        missingAccess={missingAccess}
        identity={identity}
        permission={permission}
      />
    );
  }

  return element;
};

export default IdentityAware;
