import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useGetTagsQuery } from '@/store/apis/tagsApi';
import { mergeEntities } from '@/store/slices/entitiesSlice';
import { useCategoryPermUrns } from '@v1/lib/usePermissions';
import { useQlooAPISearchStaging } from '@v1/lib/useFetch';
import { mergeTags } from '@/store/slices/tagsSlice';
import getOmnisearchResults from './getOmnisearchResults';

const coerceArray = (val) => (Array.isArray(val) ? val : val ? [val] : []);

const useSearch = ({
  key, query, types, filters, take = 15,
}) => useQlooAPISearchStaging(
  {
    query,
    take,
    // Add tag search to the list of types.
    types,
    // ...filters,
  },
  {
    key: `omnisearch-${key}`,
    required: [query],
    depends: [query, types, filters],
  },
);

const TAG_TYPES = ['urn:tag'];

const useOmnisearchResults = ({ search, category }) => {
  const dispatch = useDispatch();
  const validCategories = useCategoryPermUrns();
  const effectiveTypes = useMemo(() => (category ? [category] : coerceArray(validCategories)), [category]);

  const { isLoading: isLoadingEntities, result: entityResults } = useSearch({
    query: search,
    types: effectiveTypes,
    take: 10,
  });

  const { data: tagResults, isLoading: isLoadingTags } = useGetTagsQuery({
    query: search,
    take: 20,
    parentType: category || undefined,
    tagTypes: TAG_TYPES,
  }, { skip: !search });

  const { entities, tags, topResult } = useMemo(() => getOmnisearchResults({
    entityResults: entityResults?.body?.results || [],
    search,
    tagResults: tagResults?.results?.tags || [],
  }), [entityResults, search, tagResults]);

  useEffect(() => {
    if (entityResults?.body?.results) {
      dispatch(mergeEntities(entityResults.body.results));
    }
  }, [entityResults]);

  useEffect(() => {
    if (tagResults?.results?.tags) {
      dispatch(mergeTags(tagResults.results.tags));
    }
  }, [tagResults]);

  return {
    entities,
    isEmpty: !topResult && entities.length && !tags.length,
    isLoading: isLoadingTags || isLoadingEntities,
    tags,
    topResult,
  };
};

export default useOmnisearchResults;
