function formatCategoryUrnToCssVar(urn) {
  const urnSplit = urn.split(':');
  let category = urnSplit[urnSplit.length - 1].toLowerCase();

  if (typeof category === 'string' && category.endsWith('s')) {
    category = category.slice(0, -1);
  }

  if (category === 'tv_show') {
    return 'tv';
  }

  if (category === 'person') {
    return 'people';
  }

  return category;
}

export default formatCategoryUrnToCssVar;
