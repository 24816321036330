export const getTagCategories = (tagType) => {
  const parts = (tagType || '').split(':');
  const broaderTerm = (parts[(parts.length || 0) - 1] || '').replace(/_/g, ' ');
  const specificTerm = (parts[(parts.length || 0) - 2] || '').replace(/_/g, ' ');
  return {
    broaderTerm,
    specificTerm,
  };
};

export const formatTagCategories = (tagType) => {
  const { broaderTerm, specificTerm } = getTagCategories(tagType);
  if (broaderTerm) {
    if (specificTerm) {
      return `${broaderTerm} / ${specificTerm}`;
    }

    return broaderTerm;
  }

  return '';
};

export const formatEntityCategories = (entity) => {
  const formattedTypes = (entity.types || []).map((type) => type.split(':').slice(2)).sort((a, b) => b.length - a.length);

  if (formattedTypes.length) {
    return formattedTypes[0].join(' / ');
  }

  return '';
};
