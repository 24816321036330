import clsx from 'clsx';
import React from 'react';
import Button from '../Button';
import styles from './PillPicker.module.scss';

const PillPicker = ({ className, onChange, options, value }) => {
  const handleChange = (selectedValue) => {
    if (value === selectedValue) {
      onChange(null);
      return;
    }

    onChange(selectedValue);
    return;
  }

  return (
    <div
      className={clsx({
        [styles.pillPicker]: true,
        [className]: className,
      })}
    >
      {options.map((option) => (
        <Button
          key={option.value}
          onClick={() => handleChange(option.value)}
          variant="outlined"
          color={option.value === value || (Array.isArray(value) && value.includes(option.value)) ? 'primary' : 'secondary'}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
}
export default PillPicker;
