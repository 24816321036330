import React from 'react';
import { fieldByParamName } from '@/constants';
import styles from './Field.module.scss';
import FieldRenderer from './FieldRenderer';

const Field = ({
  disabled, onChange, paramName, value, noLabel,
}) => {
  const field = fieldByParamName[paramName];
  if (!field) {
    console.error(`Field with paramName ${paramName} not found`);
    return null;
  }

  return (
    <div className={styles.field} key={field}>
      {!noLabel && !!field.label && (
        <div className={styles.fieldLabel}>{field.label}</div>
      )}
      <FieldRenderer disabled={disabled} field={field} onChange={onChange} value={value} />
    </div>
  );
};

export default Field;
