import { useEffect } from 'react';

const Locker = ({ children, lock, releaseLock }) => {
  useEffect(() => {
    lock();

    return () => {
      releaseLock();
    };
  }, []);

  return children;
};

export default Locker;
