import { createSlice } from '@reduxjs/toolkit';

export const tagSlice = createSlice({
  name: 'tags',
  initialState: {
    byId: {},
  },
  reducers: {
    fetchTagStart: (state, action) => {
      const { byId } = state;
      const tagId = action.payload;

      return {
        ...state,
        byId: {
          ...byId,
          [tagId]: {
            ...(byId[tagId] || {}),
          },
        },
      };
    },
    mergeTags: (state, action) => {
      const tags = action.payload;
      const nextById = { ...state.byId };
      tags.forEach((tag) => {
        nextById[tag.tagId] = { ...(state.byId[tag.tagId] || {}), ...tag };
      });

      return {
        ...state,
        byId: nextById,
      };
    },
  },
});

export const {
  fetchTagStart,
  mergeTags,
} = tagSlice.actions;

export default tagSlice.reducer;
