// import GroupIcon from '@mui/icons-material/Group';
import BuildIcon from '@mui/icons-material/Build';
import GridViewIcon from '@mui/icons-material/GridView';
// import GroupIcon from '@mui/icons-material/Group';
import JoinRightIcon from '@mui/icons-material/JoinRight';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// import { ReactComponent as LogIcon } from '../components/icons/log.svg';

export const TABS = {
  dashboards: {
    label: 'Dashboards',
    to: '/dashboards',
    icon: GridViewIcon,
  },

  requests: {
    label: 'Requests',
    to: '/requests',
    alias: '/recs',
    icon: BuildIcon,
  },

  // search: {
  //   label: 'Entities',
  //   to: '/search',
  //   alias: '/entity',
  //   icon: SearchIcon,
  // },

  // tastedive: {
  //   label: 'Tastedive',
  //   to: '/db/admin',
  //   alias: '/db',
  //   icon: StorageIcon,
  // },

  compare: {
    label: 'Compare',
    to: '/compare',
    icon: JoinRightIcon,
  },

  // logs: {
  //   label: 'Logs',
  //   to: '/clientLogs',
  //   icon: LogIcon,
  // },

  // goals: {
  //   label: 'Goals',
  //   to: '/goals',
  //   icon: AssignmentTurnedInIcon,
  // },

  // audienceLabel: {
  //   label: 'Audiences',
  //   to: '/audience_label',
  //   icon: LabelIcon,
  // },

  shareables: {
    label: 'Shareables',
    to: '/shareables',
    icon: MenuBookIcon,
  },

  admin: {
    label: 'Admin',
    to: '/admin',
    icon: AdminPanelSettingsIcon,
  },

  // clients: {
  //   label: 'Clients',
  //   to: '/clients',
  //   icon: GroupIcon,
  // },

};

export const USABLE_PAGES = Object.keys(TABS);
export const SEARCH_ENABLED = !import.meta.env.VITE_LAYOUT_SIMPLE;
export const ENTITY_VIEW_ENABLED = !import.meta.env.VITE_LAYOUT_SIMPLE;
