import { useMemo } from 'react';
import { useSessionContext } from './SessionContext';

const TENANT_PERMS = {
  DEFAULT: {
    'feature.custom-logo': false,
    // 'api.category.urn:entity:actor': true,
    // 'api.category.urn:entity:album': true,
    // 'api.category.urn:entity:artist': true,
    // 'api.category.urn:entity:author': true,
    // 'api.category.urn:entity:book': true,
    'api.category.urn:entity:brand': true,
    // 'api.category.urn:entity:destination': true,
    // 'api.category.urn:entity:director': true,
    // 'api.category.urn:entity:hotel': true,
    // 'api.category.urn:entity:movie': true,
    // 'api.category.urn:entity:person': true,
    // 'api.category.urn:entity:place': true,
    // 'api.category.urn:entity:podcast': true,
    // 'api.category.urn:entity:restaurant': true,
    // 'api.category.urn:entity:tv_show': true,
    // 'api.category.urn:entity:videogame': true,
    'api.route./': true,
    'api.route./geospatial': true,
    'api.route./geospatial/describe': true,
    'api.route./recommendations': true,
    'api.route./recommendations/describe': true,
    // 'feature.entity.qa-image-component': true,
    'feature.request-browser.addRequest': true,
    'feature.request-browser.comparoStats': true,
    // 'feature.request-browser.edit-url': true,
    'feature.request-browser.header': true,
    'feature.request-browser.heatmapControls': true,
    'feature.request-browser.mapControls': true,
    'feature.request-browser.mapInteractions': true,
    'feature.request-browser.requestSummary': true,
    // 'feature.request-browser.serverSelector': true,
    'feature.request-browser.viewTypeToggle': true,
    'page.dashboards': true,
    'page.requests': true,
    'page.shareables': false,
    'page.admin': false,
    'api.crossdomain': true,
    // 'page.details': true,
    // 'feature.details.cross-domain-table': true,
    // 'feature.request-browser.edit-url': true,
    // 'feature.request-browser.openapi-validator': true,
  },
};

TENANT_PERMS.netflix = {
  ...TENANT_PERMS.DEFAULT,
  'api.category.urn:entity:artist': true,
  'api.category.urn:entity:book': true,
  'api.category.urn:entity:movie': true,
  'api.category.urn:entity:place': true,
  'api.category.urn:entity:podcast': true,
  'api.category.urn:entity:person': true,
  'api.category.urn:entity:tv_show': true,
  'api.route./geospatial/heatmap': true,
  'api.route./analysis': true,
  'api.category.urn:entity:videogame': true,
};

TENANT_PERMS.akqa = {
  ...TENANT_PERMS.netflix,
};

TENANT_PERMS.michelin = {
  ...TENANT_PERMS.DEFAULT,
  // 'api.route./entities': true,
  // 'api.route./search': true,
  'api.category.urn:entity:destination': true,
  'api.category.urn:entity:hotel': true,
  'api.category.urn:entity:place': true,
  'api.category.urn:entity:restaurant': true,
};

TENANT_PERMS.friendOfQloo = {
  ...TENANT_PERMS.DEFAULT,
  'api.category.urn:entity:artist': true,
  'api.category.urn:entity:author': true,
  'api.category.urn:entity:book': true,
  'api.category.urn:entity:brand': true,
  'api.category.urn:entity:destination': true,
  'api.category.urn:entity:hotel': true,
  'api.category.urn:entity:movie': true,
  'api.category.urn:entity:person': true,
  'api.category.urn:entity:place': true,
  'api.category.urn:entity:podcast': true,
  'api.category.urn:entity:restaurant': true,
  'api.category.urn:entity:tv_show': true,
  'api.category.urn:entity:videogame': true,
  'api.route./geospatial/heatmap': true,
  'api.route./search': true,
  'api.route./recommendations': true,
  'api.route./trends': true,
  'page.compare': true,
};

TENANT_PERMS.match = {
  ...TENANT_PERMS.friendOfQloo,
};

TENANT_PERMS.ticketmaster = {
  ...TENANT_PERMS.friendOfQloo,
  'api.category.urn:entity:event': true,
};

TENANT_PERMS.wpp = {
  ...TENANT_PERMS.DEFAULT,
  'api.category.urn:entity:artist': true,
  'api.category.urn:entity:author': true,
  'api.category.urn:entity:book': true,
  'api.category.urn:entity:brand': true,
  'api.category.urn:entity:destination': true,
  'api.category.urn:entity:hotel': true,
  'api.category.urn:entity:movie': true,
  'api.category.urn:entity:person': true,
  'api.category.urn:entity:place': true,
  'api.category.urn:entity:podcast': true,
  'api.category.urn:entity:restaurant': true,
  'api.category.urn:entity:tv_show': true,
  'api.category.urn:entity:videogame': true,
  'api.route./geospatial/heatmap': true,
  // 'api.route./search': true,
};

TENANT_PERMS.seek = {
  ...TENANT_PERMS.DEFAULT,
  'feature.custom-logo': true,
  'api.category.urn:entity:artist': true,
  'api.category.urn:entity:brand': true,
  'api.category.urn:entity:movie': true,
  'api.category.urn:entity:person': true,
  'api.category.urn:entity:place': true,
  'api.category.urn:entity:tv_show': true,
  'api.route./analysis': true,
  'api.route./geospatial': true,
  'api.route./geospatial/heatmap': true,
  'api.route./recommendations': true,
  'api.route./search': true,
  'api.route./trends': true,
};

TENANT_PERMS.amex = {
  ...TENANT_PERMS.DEFAULT,
  'api.category.urn:entity:brand': true,
  'api.category.urn:entity:person': true,
  'api.category.urn:entity:place': true,
  'api.route./geospatial': true,
  'api.route./geospatial/heatmap': true,
  'api.route./recommendations': true,
  'api.route./search': true,
};

TENANT_PERMS.qloo = {
  ...TENANT_PERMS.DEFAULT,
  'page.shareables': true,
  'page.admin': false,
};

TENANT_PERMS.shareweb = {
  ...TENANT_PERMS.DEFAULT,
  'page.shareables': true,
  'feature.request-browser.addRequest': false,
  'feature.request-browser.header': false,
  'feature.request-browser.requestSummary': false,
  'feature.request-browser.viewTypeToggle': false,
};

TENANT_PERMS.demotenant = {
  ...TENANT_PERMS.DEFAULT,
  'api.category.urn:entity:artist': true,
  'api.category.urn:entity:book': true,
  'api.category.urn:entity:brand': true,
  'api.category.urn:entity:movie': true,
  'api.category.urn:entity:person': true,
  'api.category.urn:entity:place': true,
  'api.category.urn:entity:podcast': true,
  'api.category.urn:entity:tv_show': true,
  'api.category.urn:entity:videogame': true,

  'api.route./geospatial/describe': false,
  'api.route./recommendations/describe': false,
  'api.route./geospatial/heatmap': true,
  'api.route./search': true,

};

// const getRoles = (user) => {
//   try {
//     return user.tenants.map(({ roles }) => roles.map(({ key }) => key.toLowerCase())).flat();
//   } catch (e) {
//     return [];
//   }
// };
// const hasRole = (user, role) => getRoles(user)?.indexOf(role) > -1;

const usePermission = (...perms) => {
  const { claimValue, sessionContext } = useSessionContext();

  const permResponses = useMemo(() => {
    if (
      sessionContext.loading === true
      || claimValue.loading === true
      || !claimValue.doesSessionExist
      || sessionContext.userId === null
    ) {
      /// session is still loading or we have not logged in yet. So, return
      /// empty permissions.
      return [];
    }

    if (!Array.isArray(claimValue.value) && claimValue.value.length === 0) {
      console.error(`User ${sessionContext.userId} must be assigned a role.`);
    }
    const tenantId = claimValue.value[0];

    const user = {};

    let toReturn = [];

    if (!tenantId) {
      toReturn = perms.map(() => false);
    }
    if (tenantId === undefined) {
      console.warn('usePermission was undefined!!!!');
    }
    if (tenantId === 'qloo') {
      const useperms = TENANT_PERMS[tenantId] || TENANT_PERMS.DEFAULT;
      toReturn = perms.map((p) => useperms[p] !== false);
    } else {
      const useperms = TENANT_PERMS[tenantId] || TENANT_PERMS.DEFAULT;
      toReturn = perms.map((p) => !!useperms[p]);
    }

    // if (hasRole(user, 'admin')) {
    //   console.log('usePermission admin');
    //   toReturn = perms.map(() => true);
    // } else if (hasRole(user, 'client')) {
    //   console.log('usePermission client', perms.length);
    //   toReturn = perms.map((p) => !!CLIENT_PERMS[p]);
    // } else {
    //   console.log('usePermission ??');
    //   toReturn = perms.map(() => false);
    // }

    return toReturn;
  }, [perms, sessionContext, claimValue]);

  return permResponses;
};

export default usePermission;

export const usePermissionObject = (...perms) => {
  const basePermissions = usePermission(...perms);
  const permissions = useMemo(
    () => Object.fromEntries(perms.map((p, ii) => [p, basePermissions[ii]])),
    [perms, basePermissions],
  );
  return permissions;
};
