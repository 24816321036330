import React from 'react';
import styles from './ErrorFallback.module.scss';

const ErrorFallback = ({ backgroundRenderer, errorRenderer }) => {
  return (
    <div className={styles.errorContainer}>
      {backgroundRenderer()}
      <div className={styles.errorOverlay} />
      {errorRenderer()}
    </div>
  );
}

export default ErrorFallback;
