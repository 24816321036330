import MuiTextField from '@mui/material/TextField';

// TODO: Once MUI V5 is released, use number input component

const InputV2 = ({
  type = 'text', min, max, step, disableUnderline, className, onChange, ...rest
}) => {
  const handleChange = (e) => {
    const { value } = e.currentTarget;
    const numericValue = type === 'number' ? Number(value) : value;

    if (type === 'number') {
      if ((min !== undefined && numericValue < min) || (max !== undefined && numericValue > max)) {
        return;
      }
    }
    onChange(value, e);
  };

  return (
    <MuiTextField
      onChange={handleChange}
      type={type}
      InputProps={{
        inputProps: {
          min: type === 'number' ? min : undefined,
          max: type === 'number' ? max : undefined,
          step: type === 'number' && step ? step : undefined,
          type,
          className,
          ...(disableUnderline && {
            style: { padding: '0' },
          }),
        },
        disableUnderline,
      }}
      {...rest}
    />
  );
};

export default InputV2;
