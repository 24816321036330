import React from 'react';
import PanelHeaderSkeleton from './PanelHeaderSkeleton';
import PanelContentSkeleton from './PanelContentSkeleton';
import PanelFooterSkeleton from './PanelFooterSkeleton';

import styles from '../DashboardPanel.module.scss';

const PanelSkeleton = (props) => (
  <div className={styles.panel}>
    <PanelHeaderSkeleton {...props} />
    <PanelContentSkeleton {...props} />
    <PanelFooterSkeleton {...props} />
  </div>
);

export default PanelSkeleton;
