import clsx from 'clsx';
import { Button } from '@/components';
import Menu from '@mui/material/Menu';
import { nestedMenuItemsFromObject } from 'mui-nested-menu';
import React, { useMemo, useState } from 'react';
import { Zap } from 'react-feather';
import { insightTypes, insightTypeLabels } from '@/constants';
import { useCategoryPerms } from '@v1/lib/usePermissions';
import { useDashboardActionsContext } from '../DashboardActionsProvider';
import styles from './DashboardToolbar.module.scss';

const getMenuItemsData = ({ dashboardActions }) => [
  {
    id: insightTypes.table,
    label: insightTypeLabels[insightTypes.table],
    callback: () => {
      dashboardActions.addPanel();
    },
  },
  {
    id: insightTypes.heatmap,
    label: insightTypeLabels[insightTypes.heatmap],
    callback: () => {
      dashboardActions.addHeatmapPanel();
    },
  },
  {
    id: insightTypes.explore,
    label: insightTypeLabels[insightTypes.explore],
    callback: () => {
      dashboardActions.addExplorePanel();
    },
  },
];

const NewInsightPopover = () => {
  const dashboardActions = useDashboardActionsContext();
  const categoryPerms = useCategoryPerms();

  const menuItemsData = useMemo(() => {
    const categories = Object.keys(categoryPerms);
    return getMenuItemsData({ dashboardActions, categories });
  }, [categoryPerms]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const menuItems = nestedMenuItemsFromObject({
    handleClose,
    isOpen: open,
    menuItemsData,
  });

  return (
    <div className={styles.newInsightButtonContainer}>
      <Button onClick={handleClick} variant="text">
        <div className={clsx({
          [styles.newInsightButton]: true,
          [styles.newInsightButtonOpen]: open,
        })}
        >
          <div className={styles.newInsightButtonIcon}>
            <Zap color="#000000" size={12} />
          </div>
          <span className={styles.newInsightButtonText}>
            New Insight
          </span>
        </div>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems}
      </Menu>
    </div>
  );
};

export default NewInsightPopover;
