import styles from './FieldsModal.module.scss';
import Chip from '../Chip';

const FieldsModalChips = ({ chips }) => {
  if (chips && chips.length) {
    return (
      <div className={styles.chips}>
        {chips.map((chip) => (
          <Chip className={styles.chip} {...chip} />
        ))}
      </div>
    );
  }

  return null;
};

export default FieldsModalChips;
