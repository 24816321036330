import { MenuItem } from '@mui/material';
import clsx from 'clsx';
import { Plus } from 'react-feather';

import styles from './PanelParamsModifiers.module.scss';

const ModifierPanelItem = ({ onClick, color, label }) => {
  return (
    <MenuItem
      className={clsx({
        [styles.modifierPanelItem]: true,
        [styles[color]]: !!color,
      })}
      onClick={onClick}
    >
      <Plus
        size={14}
        className={clsx({
          [styles.modifierPanelItemPlus]: true,
          [styles[`${color}Icon`]]: !!color,
        })}
      />
      <span className={styles.modifierPanelItemName}>{label}</span>
    </MenuItem>
  );
};

export default ModifierPanelItem;
