import React from 'react';
import { Alert as MuiAlert } from '@mui/material'

const Alert = ({ message, severity, ...props }) => {
  return (
   <MuiAlert severity={severity} {...props}>{message}</MuiAlert>
  );
};

export default Alert
