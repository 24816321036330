import React from 'react';

import Avatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';
import userInformation from '../../lib/userInformation';

export const useStyles = makeStyles(() => ({
  profileButtonContainer: {
    background: 'none',
    border: 0,
    paddingRight: '0 !important',
    display: 'inline-block',
  },
  profileButton: {
    border: '2px solid white',
    // TODO_SUPER: There is nothing to click on for the user so disable the
    // pointer for now.
    // cursor: 'pointer',
  },

}));

const UserBadge = () => {
  const classes = useStyles();
  const user = userInformation();
  const fullName = `${user?.firstName} ${user?.lastName}`;
  const initials = `${user?.firstName?.[0] || '' }${user?.lastName?.[0] || ''}`;
  return (
    <button
      type="button"
      // TODO_SUPER: Note that Supertokens doesn't have an admin portal
      // onClick={() => {
      //   AdminPortal.show();
      // }}
      className={classes.profileButtonContainer}
    >
      <Avatar
        className={classes.profileButton}
        alt={fullName}
        src={user?.profilePictureUrl}
      >{initials}
      </Avatar>
    </button>
  );
};

export default UserBadge;
