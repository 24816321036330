import React from 'react';
import { Box, Skeleton } from '@mui/material';
import DashboardTableSkeleton from './DashboardTableSkeleton';
import DashboardCardSkeleton from './DashboardCardSkeleton';

const DashboardHomeSkeleton = () => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
            <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
                gap={4}
                padding={3}
                pl={5}
                pr={5}
            >
                <Skeleton variant="text" width={100} height={20} />
                <Skeleton variant="rectangular" width={200} height={40} />
                <Skeleton variant="rectangular" width={150} height={40} />
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'hsl(var(--background-accent))',
                padding: 3,
                paddingLeft: 5,
                width: '100%',
                overflow: 'auto',
                gap: 5,
            }}
            >
                <DashboardCardSkeleton />
                <DashboardTableSkeleton />
            </Box>
        </Box>
    );
};

export default DashboardHomeSkeleton;
