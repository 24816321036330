import DownloadIcon from '@mui/icons-material/Download';
import { useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import {
  bindDialog,
} from 'material-ui-popup-state/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLazyGetInsightQuery } from '@/store/apis/insightsApi';
import { useActiveDashboard } from '@/hooks';
import { getHumanizedQueryError } from '../../FilterTags/helpers';
import downloadCsv from './helpers/downloadCsv';
import getDefaultFileName from './helpers/getDefaultFileName';

const DownloadDialog = ({
  popupState, panelId, serializedInsightsParams, onDownloaded,
}) => {
  const dashboard = useActiveDashboard();
  const [triggerGetInsight] = useLazyGetInsightQuery();
  const [fileName, setFileName] = useState(getDefaultFileName(dashboard, panelId));
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setFileName(e.currentTarget.value);
  };

  const handleDownload = async () => {
    setIsFetching(true);
    setError(null);
    try {
      const panel = dashboard.panels[panelId];
      const data = await triggerGetInsight(serializedInsightsParams).unwrap();

      downloadCsv(fileName, data, panel);

      popupState.close();
      if (onDownloaded) {
        onDownloaded();
      }
    } catch (e) {
      const message = getHumanizedQueryError(e);
      console.log('error', message);
      setError(message);
      Sentry.captureException(e);
    } finally {
      setIsFetching(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleDownload();
    } else {
      e.stopPropagation();
    }
  };

  const handleClose = () => {
    popupState.close();
  };

  return (
    <Dialog
      {...bindDialog(popupState)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Export Data</DialogTitle>
      <DialogContent>
        <Box display="flex" gap={2} flexDirection="column">
          <Alert severity="info">
            Exporting data from this panel will download a CSV file containing all the data displayed in the current panel.
          </Alert>
          <TextField
            required
            fullWidth
            autoFocus
            error={!fileName}
            disabled={isFetching}
            label="File Name"
            value={fileName}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          {error && <Alert severity="error">{error}</Alert>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
        <LoadingButton
          variant="contained"
          disabled={!fileName}
          loading={isFetching}
          loadingPosition="start"
          startIcon={<DownloadIcon />}
          onClick={handleDownload}
        >
          Download CSV
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadDialog;
