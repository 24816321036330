import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyFeaturesForActiveUserGetQuery } from '@/store/apis/corporateApi';
import useIdentityGet from './useIdentityGet';

const matchPath = (path, pattern) => {
  /// Convert the pattern to a regular expression
  const regex = new RegExp(`^${pattern.replace(/\*/g, '.*')}$`);
  return regex.test(path);
};

/**
 * Determines if a user can access a route based on the permissions they have
 * given. Here is how it works:
 * 1) an identity has a list of permissions that can be found in
 *    identity.calculatedPermissionTagIds
 * 2) along with permissions, the user is granted access to one or more features
 * which we pull from the database. A feature grants access to a path and/or
 * more permissions.
 * 3) Using the current route (pulled from useLocation) we try to find a feature
 * that has the same path as the current route. For example, if the current
 * route is /admin/client and there is a feature with grantedPaths of
 * ['/admin*'] then that feature is a match.
 * @returns
 */
const useIdentityCanAccessRoute = () => {
  const location = useLocation();
  const { identity } = useIdentityGet();
  const [state, setState] = useState({
    hasAccess: false,
    identity: null,
    missingAccess: '',
    isLoading: true,
  });

  const [triggerFeaturesForActiveUserGetQuery] = useLazyFeaturesForActiveUserGetQuery();

  useEffect(() => {
    const fetchAccess = async () => {
      if (!identity) {
        setState((prevState) => ({
          ...prevState,
          identity: null,
          hasAccess: false,
          isLoading: false,
        }));
        return;
      }

      setState((prevState) => ({ ...prevState, isLoading: true }));

      try {
        const featuresFound = await triggerFeaturesForActiveUserGetQuery().unwrap();
        const matchingFeatures = featuresFound
          .filter((feat) => feat.grantedPaths.some((pattern) => matchPath(location.pathname, pattern)));

        let missingAccessMessage = matchingFeatures.length === 0 ? location.pathname : '';

        if (featuresFound.length === 0) {
          missingAccessMessage += 'No features found';
        }

        setState({
          identity,
          hasAccess: matchingFeatures.length > 0,
          missingAccess: missingAccessMessage,
          isLoading: false,
        });
      } catch (error) {
        console.error('Failed to fetch features:', error);
        setState((prevState) => ({
          ...prevState,
          hasAccess: false,
          missingAccess: 'Failed to determine access',
          isLoading: false,
        }));
      }
    };

    fetchAccess();
  }, [identity, triggerFeaturesForActiveUserGetQuery, location.pathname]);

  return state;
};

export default useIdentityCanAccessRoute;
