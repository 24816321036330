import React from 'react';
import { Box, Typography } from '@mui/material';
import ShareUserChipAvatar from './ShareUserChipAvatar';

const UserSection = ({
  dashboardId, title, users, onUserClick, checkSelected, showSelectPermission,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography ml={2}>{title}</Typography>
      <Box display="flex" flexDirection="column">
        {users.map((user) => (
          <ShareUserChipAvatar
            key={user.identityId}
            dashboardId={dashboardId}
            user={user}
            onClick={() => onUserClick(user)}
            showSelectPermission={showSelectPermission}
            isSelected={checkSelected(user)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default UserSection;
