import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { ChevronRight } from 'react-feather';
import { MenuItem } from '@mui/material';
import { fieldByParamName } from '@/constants';
import { getIsFieldValueEmpty, isLocationParam } from '@/utils';
import { Field } from '../Field';
import ModalResetButton from '../ModalResetButton/ModalResetButton';
import LocationPopover from '../LocationPopover';
import styles from './FieldsModal.module.scss';

const MultipleParamsBody = ({
  params,
  paramNames,
  baseParams,
  defaultOpenParam,
  renderIndicator,
  onChange,
  onReset,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeParamName, setActiveParamName] = useState(defaultOpenParam);
  const isLocation = isLocationParam(activeParamName);

  const activeFieldCount = useMemo(
    () => paramNames.filter((paramName) => !getIsFieldValueEmpty(params[paramName])),
    [paramNames, params],
  );

  const handleSelect = (nextActiveParamName) => (event) => {
    setActiveParamName(nextActiveParamName);

    if (isLocationParam(nextActiveParamName)) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleLocationClose = () => {
    setAnchorEl(null);
    setActiveParamName(null);
  };

  return (
    <div className={styles.body}>
      <div className={clsx({
        [styles.labels]: true,
        [styles.labelsWithNoActiveParamName]: !activeParamName || (activeParamName && isLocation),
      })}
      >
        {paramNames.map((paramName) => (
          <MenuItem
            key={paramName}
            className={clsx({
              [styles.label]: true,
              [styles.labelActive]: activeParamName === paramName,
            })}
            onClick={handleSelect(paramName)}
          >
            <span className={styles.labelText}>
              {fieldByParamName[paramName].label}
            </span>
            <span className={styles.labelIndicator}>
              {renderIndicator(params[paramName])}
            </span>
            <ChevronRight className={styles.labelIcon} size={12} />
          </MenuItem>
        ))}
        {activeFieldCount && (
          <ModalResetButton onReset={onReset}>
            {`Clear${activeFieldCount > 1 ? ' All' : ''}`}
          </ModalResetButton>
        )}
      </div>
      {!!activeParamName && !isLocation && (
        <div className={styles.activeField}>
          <Field
            disabled={baseParams[activeParamName]}
            onChange={onChange(activeParamName)}
            paramName={activeParamName}
            value={params[activeParamName]}
          />
        </div>
      )}
      <LocationPopover
        location={params[activeParamName]}
        anchorEl={anchorEl}
        onClose={handleLocationClose}
        onChange={onChange(activeParamName)}
      />

    </div>
  );
};

export default MultipleParamsBody;
