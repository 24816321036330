import { useEffect, useRef } from 'react';
import { useVeltClient } from '@veltdev/react';
import {
  deserializeObjectForVelt,
  serializeObjectForVelt,
} from '../helpers/serializeObjectForVelt';

const useLiveState = ({ liveStateDataId, onChange }) => {
  const { client: veltClient } = useVeltClient();
  const subscriptionRef = useRef(false);

  const init = () => {
    const subscription = veltClient
      .getLiveStateSyncElement()
      .getLiveStateData(liveStateDataId, {
        listenToNewChangesOnly: true,
      })
      .subscribe((data) => {
        if (data) {
          const deserializedData = deserializeObjectForVelt(data);
          if (onChange) {
            onChange(deserializedData);
          }
        }
      });

    subscriptionRef.current = subscription;
  };

  const emit = (data) => {
    const serializedData = serializeObjectForVelt(data);

    if (veltClient) {
      veltClient
        .getLiveStateSyncElement()
        .setLiveStateData(liveStateDataId, serializedData);
    }
  };

  const unsubscribe = () => {
    const subscription = subscriptionRef.current;
    if (subscription) {
      subscription.unsubscribe();
    }
  };

  useEffect(() => {
    if (veltClient) {
      init();
    }

    return () => {
      unsubscribe();
    };
  }, [veltClient])

  return {
    emit,
  };
};

export default useLiveState;
