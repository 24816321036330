import dayjs from 'dayjs';
import { useInViewport } from 'react-in-viewport';
import { ResponsiveLine } from '@nivo/line';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@/constants';
import {
  fetchEntityTrendsStart,
  mergeEntityTrends,
} from '@/store/slices/entityTrendsSlice';
import styles from './EntityTrends.module.scss';

const fetchEntityTrends = async (entityId) => {
  const startDate = dayjs().subtract(6, 'months').format('YYYY-MM-DD');
  const url = `${api.hostname}/api/${api.server}/trends/entity?entity_id=${entityId}&start_date=${startDate}`;

  const response = await fetch(url);
  if (response.ok) {
    const result = await response.json();
    return (result.results?.trends || []).map((trend) => ({
      x: trend.date,
      y: trend.population_percent_delta,
    }));
  }

  return [];
};

const EntityTrends = ({ entityId }) => {
  const dispatch = useDispatch();
  const entityTrends = useSelector(
    (state) => state.entityTrends.byId[entityId],
  );
  const elementRef = useRef(null);

  const { enterCount, inViewport } = useInViewport(
    elementRef,
    {},
    { disconnectOnLeave: false },
  );

  const fetchIfNeeded = async () => {
    if (!entityTrends) {
      dispatch(fetchEntityTrendsStart(entityId));
      const trends = await fetchEntityTrends(entityId);
      dispatch(mergeEntityTrends({ entityId, trends }));
    }
  };

  useEffect(() => {
    if (inViewport && enterCount === 1) {
      fetchIfNeeded(entityId);
    }
  }, [enterCount, inViewport]);

  return (
    <div className={styles.container} ref={elementRef}>
      {entityTrends && entityTrends.length ? (
        <div className={styles.chart}>
          <ResponsiveLine
            fill={[]}
            defs={[]}
            useMesh={false} // or true, depending on your needs
            role="img" // or "presentation"
            crosshairType="x" // or "y", "both", "top-left", "top-right", etc.
            enableCrosshair={true} // or false, depending on your needs
            sliceTooltip={(slice) => <div>{/* Custom tooltip content */}</div>}
            debugSlices={false} // or true for debugging
            enableSlices="x" // or "y"
            tooltip={({ point }) => <div>{/* Custom tooltip content */}</div>}
            debugMesh={false} // or true for debugging
            isInteractive={false} // or true
            legends={[]} // Define legends if needed
            lineWidth={2} // Set your preferred line width
            areaBaselineValue={0}
            areaBlendMode="normal" // or other blend modes like "multiply"
            areaOpacity={0.3} // Set desired opacity
            pointLabel="value" // or a function to return a custom label
            enablePointLabel={false} // or true to enable
            pointBorderColor={{ from: 'serieColor' }} // or a specific color
            pointBorderWidth={1}
            pointColor={{ theme: 'background' }} // or a specific color
            pointSize={6} // Set your desired point size
            curve="linear" // or other options like "monotoneX", "step", etc.
            layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
            data={[
              {
                id: 'populationPercentile',
                data: entityTrends,
              },
            ]}
            margin={{
              top: 0,
              bottom: 0,
            }}
            colors={['#408af7']}
            dotSize={0}
            enableArea
            enableGridX={false}
            enableGridY={false}
            enablePoints={false}
            // curve={curve}
            axisBottom={{
              tickValues: [],
            }}
            xFormat="time:%Y-%m-%d"
            xScale={{
              type: 'time',
              format: '%Y-%m-%d',
              useUTC: false,
              precision: 'day',
            }}
            yScale={{
              type: 'linear',
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default EntityTrends;
