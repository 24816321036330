import { MethodContractError } from '@qloo/errors';

const authMiddleTierUrl = import.meta.env.VITE_AUTH_MIDDLE_TIER_URL;
const authMiddleTierPort = import.meta.env.VITE_AUTH_MIDDLE_TIER_PORT || '';

const reactAppWebsiteUrl = window.location.href;
const reactAppWebsitePort = import.meta.env.VITE_WEBSITE_PORT || '';

const env = {
  authMiddleTierUrl,
  authMiddleTierPort,
  authMiddleTierUrlDomain: `${authMiddleTierUrl}${authMiddleTierPort === '' ? '' : ':'}${authMiddleTierPort}`,
  reactAppWebsiteUrl,
  reactAppWebsitePort,
  reactAppWebsiteUrlDomain: reactAppWebsiteUrl,
};

console.debug('Front End Environment Settings:', env);

if (undefined === authMiddleTierUrl) {
  throw new MethodContractError({
    method: 'env-frontend-config.js',
    parameter: 'VITE_AUTH_MIDDLE_TIER_URL',
    message: 'Environment variable VITE_AUTH_MIDDLE_TIER_URL must be defined.',
    errorCode: 'STENVFE001',
  });
}

if (undefined === reactAppWebsiteUrl) {
  throw new MethodContractError({
    method: 'env-frontend-config.js',
    parameter: 'reactAppWebsiteUrl',
    message: 'Unable to get the website URL from reactAppWebsiteUrl.',
    errorCode: 'STENVFE002',
  });
}

export default env;
