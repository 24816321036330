import { createSlice } from '@reduxjs/toolkit';

export const entityTrendsSlice = createSlice({
  name: 'entityTrends',
  initialState: {
    byId: {},
  },
  reducers: {
    fetchEntityTrendsStart: (state, action) => {
      const { byId } = state;
      const entityId = action.payload;

      return {
        ...state,
        byId: {
          ...byId,
          [entityId]: byId[entityId] || [],
        },
      };
    },
    mergeEntityTrends: (state, action) => {
      const { entityId, trends } = action.payload;

      return {
        ...state,
        byId: { ...state.byId, [entityId]: trends },
      };
    },
  },
});

export const {
  fetchEntityTrendsStart,
  mergeEntityTrends,
} = entityTrendsSlice.actions;
