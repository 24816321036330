import { createSlice } from '@reduxjs/toolkit';

const localStorageKey = 'dashboard-tabs';
const writeToLocalStorage = (nextDashboardIds) => {
  window?.localStorage?.setItem(localStorageKey, JSON.stringify(nextDashboardIds));
};

const readFromLocalStorage = () => {
  const dashboardIdsString = window?.localStorage?.getItem(localStorageKey);
  if (dashboardIdsString) {
    return JSON.parse(dashboardIdsString);
  }

  return [];
};

export const dashboardTabsSlice = createSlice({
  name: 'dashboardTabs',
  initialState: {
    dashboardIds: readFromLocalStorage(),
  },
  reducers: {
    addDashboardIdToTabs: (state, action) => {
      const { dashboardIds } = state;
      const dashboardId = action.payload;
      const nextDashboardIds = [...dashboardIds, ...(dashboardIds.includes(dashboardId) ? [] : [dashboardId])];

      return {
        ...state,
        dashboardIds: nextDashboardIds,
      };
    },
    addAndPersistDashboardTabId: (state, action) => {
      const { dashboardIds } = state;
      const dashboardId = action.payload;
      const nextDashboardIds = [...dashboardIds, ...(dashboardIds.includes(dashboardId) ? [] : [dashboardId])];

      writeToLocalStorage(nextDashboardIds);
      return {
        ...state,
        dashboardIds: nextDashboardIds,
      };
    },
    closeDashboardById: (state, action) => {
      const id = action.payload;
      const nextDashboardIds = state.dashboardIds.filter(dashboardId => dashboardId !== id);

      writeToLocalStorage(nextDashboardIds);
      return {
        ...state,
        dashboardIds: nextDashboardIds,
      };
    },
    loadDashboardTabIds: (state, action) => {
      const availableDashboards = action.payload;
      const availableDashboardsBitmap = availableDashboards.reduce((obj, dashboard) => ({
        ...obj,
        [dashboard.id]: 1,
      }), {});

      const dashboardIdsFromLocalStorage = readFromLocalStorage();
      const dashboardIds = dashboardIdsFromLocalStorage.filter((id) => availableDashboardsBitmap[id]);

      return {
        ...state,
        dashboardIds,
      };
    },
  },
});

export const {
  addDashboardIdToTabs,
  addAndPersistDashboardTabId,
  closeDashboardById,
  loadDashboardTabIds,
} = dashboardTabsSlice.actions;
