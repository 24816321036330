import { useRef } from 'react';

const safeDocument = typeof document !== 'undefined' ? document : {};
let scrollBlockCounter = 0;

/**
 * Usage:
 * const [blockScroll, allowScroll] = useScrollBlock();
 */
const useScrollBlock = () => {
  const scrollBlocked = useRef(false);
  const html = safeDocument.documentElement;
  const { body } = safeDocument;

  const blockScroll = () => {
    if (!body || !body.style || scrollBlocked.current) return;

    if (scrollBlockCounter === 0) {
      const scrollBarWidth = window.innerWidth - html.clientWidth;
      body.style.setProperty('--scroll-bar-width', `${scrollBarWidth}px`);

      html.classList.add('no-scroll');
      body.classList.add('body-no-scroll');
    }

    scrollBlockCounter += 1;
    scrollBlocked.current = true;
  };

  const allowScroll = () => {
    if (!body || !body.style || !scrollBlocked.current) return;

    scrollBlockCounter -= 1;

    if (scrollBlockCounter <= 0) {
      html.classList.remove('no-scroll');
      body.classList.remove('body-no-scroll');
      body.style.removeProperty('--scroll-bar-width');
      scrollBlockCounter = 0;
    }

    scrollBlocked.current = false;
  };

  return [blockScroll, allowScroll];
};

export default useScrollBlock;
