import getNextLayoutItem from './getNextLayoutItem';

const getLayoutItems = (layout, panels) => {
  if (!layout || !layout.length) {
    return [];
  }

  const itemsWithPanel = layout.filter(
    (item) => panels[item.i] && panels[item.i].params,
  );

  const addPanelItem = getNextLayoutItem('add-insight-panel', layout);

  // TODO Re-add panel item after behavior is fixed

  return [
    ...itemsWithPanel,
  ];
};

export default getLayoutItems;
