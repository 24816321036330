import React from 'react';
import styles from './IconWithContent.module.scss';

const IconWithContent = ({ iconComponent, iconSize, content }) => {
  return (
    <div className={styles.container}>
      {React.createElement(iconComponent, { style: { fontSize: iconSize } })}
      {content}
    </div>
  );
};

export default IconWithContent;
