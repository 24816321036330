import React from 'react';
import { InlineButton } from '@/components';

const LocationParam = ({
  location,
  onClick,
  placeholder = 'Anywhere',
  className,
  color,
}) => {
  return (
    <InlineButton
      color={color}
      className={className}
      onClick={onClick}
    >
      {location?.value ? (
        <span>{location?.displayLabel || location.label}</span>
      ) : (
        <span>{placeholder}</span>
      )}
    </InlineButton>
  );
};

export default LocationParam;
