import React from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import TableSkeleton from './Skeleton';
import './Table.scss';

const Table = ({
  columns,
  data,
  enableRowNumbers = false,
  enablePagination = false,
  enableTopToolbar = false,
  enableBottomToolbar = false,
  isLoading,
  renderEmptyRowsFallback,
  onRowClick,
  rowCount,
  pagination,
  sorting,
  onPaginationChange,
  onSortingChange,
}) => {
  const tableOptions = {
    state: { isLoading },
    columns,
    data,
    enableRowNumbers,
    enableGlobalFilter: false,
    enableFilters: false,
    enableTopToolbar,
    enableBottomToolbar,
    enablePagination,
    renderEmptyRowsFallback,
    rowNumberDisplayMode: 'original',
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    columnVirtualizerOptions: false,
    enableHiding: false,
    enableSorting: false,
    enableColumnActions: false,
    muiTableBodyRowProps: (props) => ({
      onClick: onRowClick ? handleRowClick(props.row.original.id) : undefined,
      sx: onRowClick ? { cursor: 'pointer' } : undefined,
    }),
  };

  if (onPaginationChange && pagination) {
    tableOptions.onPaginationChange = onPaginationChange;
    tableOptions.manualPagination = true;
    tableOptions.state = {
      ...tableOptions.state,
      pagination,
    };
    if (rowCount) {
      tableOptions.rowCount = rowCount;
    }
  }
  if (onSortingChange && sorting) {
    tableOptions.enableSorting = true;
    tableOptions.onSortingChange = onSortingChange;
    tableOptions.state = {
      ...tableOptions.state,
      sorting,
    };
  }

  const table = useMaterialReactTable(tableOptions);

  const handleRowClick = (id) => (event) => {
    const parentTd = event.target.closest('td');
    if (parentTd && parentTd.cellIndex > 0) {
      onRowClick(id);
    }
  };

  return (
    <div className="table">
      {isLoading ? (
        <TableSkeleton columns={columns} />
      ) : (
        <MaterialReactTable table={table} />
      )}
    </div>
  );
};

export default Table;
