import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import styles from './Breadcrumb.module.scss';
import { Button } from '@/components';

const Breadcrumb = ({ items, separator = '/', ariaLabel = 'breadcrumb' }) => {
  return (
    <Breadcrumbs
      separator={<span className={styles.separator}>{separator}</span>}
      aria-label={ariaLabel}
    >
      {items.map((item, index) => {
        if (index < items.length - 1) {
          return (
            <Button
              variant="text"
              key={item.label}
              onClick={item.onClick}
              className={styles.link}
            >
              {item.label}
            </Button>
          );
        }
        return (
          <span key={item.label} className={styles.typography}>
            {item.label}
          </span>
        );
      })}
    </Breadcrumbs>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  separator: PropTypes.node,
  ariaLabel: PropTypes.string,
};

export default Breadcrumb;
