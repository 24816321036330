import React from 'react';
import clsx from 'clsx';
import LinearProgress from '@mui/material/LinearProgress';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  progressBar: {
    height: '3px',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: 'rgba(201,206,234,0.8)',
  },
}));

/**
 * `ProgressBar`
 *
 * This component serves as a styled wrapper for the `LinearProgress` component
 * from Material UI. It accepts two optional props: `className` and `sx`.
 * These props allow customization of the component's CSS classes and styles respectively.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} [props.className=defaultClassName] - Optional. CSS class names as a string.
 * @param {Object} [props.sx=defaultStyles] - Optional. Inline styles for the component as an object.
 *
 * @returns The rendered `LinearProgress` element with the provided or default styling.
 *
 * @example
 * // Usage
 * <ProgressBar className="custom-class" sx={{ margin: '20px 0' }} />
 */
const ProgressBar = ({ className = '', sx = {} }) => {
  const classes = useStyles();
  return <LinearProgress className={clsx(classes.progressBar, className)} color="secondary" sx={sx} />;
};

export default ProgressBar;
