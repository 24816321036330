import { paramNames } from './paramNames';

const popovers = {
  demographics: 'demographics',
  interests: 'interests',
  locations: 'locations',
  extras: 'extras',
  [paramNames.filterExternalExists]: paramNames.filterExternalExists,
  ratingRange: 'ratingRange',
  popularityRange: 'popularityRange',
  [paramNames.filterRatingMin]: paramNames.filterRatingMin,
  [paramNames.filterRatingMax]: paramNames.filterRatingMax,
  [paramNames.filterPrice_levelMin]: paramNames.filterPrice_levelMin,
  [paramNames.filterPrice_levelMax]: paramNames.filterPrice_levelMax,
  [paramNames.filterPopularityMin]: paramNames.filterPopularityMin,
  [paramNames.filterPopularityMax]: paramNames.filterPopularityMax,
};

export default popovers;
