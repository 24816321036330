const formatFetchJson = (fetcher = fetch) => (...params) => {
  let toReturn = { ok: false };
  return fetcher(...params)
    .then((results) => {
      const { ok, status, url } = results;
      toReturn = { ok, status, url };
      return results.json();
    })
    .then((body) => ({ ...toReturn, body }))
    .catch((error) => {
      const { url, ...rest } = toReturn;
      // eslint-disable-next-line no-throw-literal
      throw {
        ...rest,
        errorName: error?.name,
        message: error?.toString?.(),
      };
    });
};

export default formatFetchJson;
