import React from 'react';
import clsx from 'clsx';
import { Skeleton } from '@/components';

import styles from './QueryBuilder.module.scss';
import dashboardStyles from '../DashboardHeader/DashboardHeader.module.scss';

const QueryBuilderSkeleton = ({ disabled }) => (
  <div>
    <div className={clsx(dashboardStyles.baseQuery, styles.baseQuerySkeletonWrapper)}>
      <div className={dashboardStyles.picker}>
        <Skeleton
          disabled={disabled}
          width={300}
          baseColor="hsl(var(--orange))"
          highlightColor="hsl(var(--orange-foreground))"
        />
      </div>
          &nbsp;
      <div>from</div>
          &nbsp;
      <div className={dashboardStyles.picker}>
        <Skeleton
          disabled={disabled}
          width={350}
          baseColor="hsl(var(--purple))"
          highlightColor="hsl(var(--purple-foreground))"
        />
      </div>
          &nbsp;
      <div>that likes</div>
          &nbsp;
      <div className={dashboardStyles.picker}>
        <Skeleton
          disabled={disabled}
          width={200}
          baseColor="hsl(var(--blue))"
          highlightColor="hsl(var(--blue-foreground))"
        />
      </div>
    </div>
  </div>
);

export default QueryBuilderSkeleton;
