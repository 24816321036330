import MersenneTwister from 'mersenne-twister';
import Color from 'color';
import paperGen from './paper';
import colors from './colors';

const shapeCount = 4;
const svgns = 'http://www.w3.org/2000/svg';

let generator;

export default function generateIdenticon(diameter, seed) {
  generator = new MersenneTwister(seed);
  // TODO Fix eslint disabled line
  // eslint-disable-next-line no-use-before-define
  const remainingColors = hueShift(colors.slice(), generator);

  // TODO Fix eslint disabled line
  // eslint-disable-next-line no-use-before-define
  const elements = paperGen(diameter, genColor(remainingColors));
  const { container } = elements;

  const svg = document.createElementNS(svgns, 'svg');
  svg.setAttributeNS(null, 'x', '0');
  svg.setAttributeNS(null, 'y', '0');
  svg.setAttributeNS(null, 'width', diameter);
  svg.setAttributeNS(null, 'height', diameter);

  container.appendChild(svg);

  // TODO Fix eslint disabled line
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < shapeCount - 1; i++) {
    // TODO Fix eslint disabled line
    // eslint-disable-next-line no-use-before-define
    genShape(remainingColors, diameter, i, shapeCount - 1, svg);
  }

  return container;
}

function genShape(remainingColors, diameter, i, total, svg) {
  const center = diameter / 2;

  const shape = document.createElementNS(svgns, 'rect');
  shape.setAttributeNS(null, 'x', '0');
  shape.setAttributeNS(null, 'y', '0');
  shape.setAttributeNS(null, 'width', diameter);
  shape.setAttributeNS(null, 'height', diameter);

  const firstRot = generator.random();
  const angle = Math.PI * 2 * firstRot;
  // TODO Fix eslint disabled line
  // eslint-disable-next-line no-mixed-operators
  const velocity = (diameter / total) * generator.random() + (i * diameter / total);

  const tx = Math.cos(angle) * velocity;
  const ty = Math.sin(angle) * velocity;

  const translate = `translate(${tx} ${ty})`;

  const secondRot = generator.random();
  const rot = firstRot * 360 + secondRot * 180;
  const rotate = `rotate(${rot.toFixed(1)} ${center} ${center})`;
  const transform = `${translate} ${rotate}`;
  shape.setAttributeNS(null, 'transform', transform);
  // TODO Fix eslint disabled line
  // eslint-disable-next-line no-use-before-define
  const fill = genColor(remainingColors);
  shape.setAttributeNS(null, 'fill', fill);

  svg.appendChild(shape);
}

// TODO Fix eslint disabled line
// eslint-disable-next-line no-shadow
function genColor(colors) {
  const rand = generator.random();
  const idx = Math.floor(colors.length * generator.random());
  const color = colors.splice(idx, 1)[0];
  return color;
}

const wobble = 30;

// TODO Fix eslint disabled line
// eslint-disable-next-line no-shadow
function hueShift(colors, generator) {
  const amount = generator.random() * 30 - wobble / 2;
  return colors.map((hex) => {
    const color = Color(hex);
    color.rotate(amount);
    return color.hex(); // Atualização para hex()
  });
}
