import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: null,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError(state, action) {
      /// redux uses immer under the hood, so we can mutate the state directly
      // eslint-disable-next-line no-param-reassign
      state.error = action.payload;
    },
    clearError(state) {
      /// redux uses immer under the hood, so we can mutate the state directly
      // eslint-disable-next-line no-param-reassign
      state.error = null;
    },
  },
});

export const {
  setError,
  clearError,
} = errorSlice.actions;

export default errorSlice;
