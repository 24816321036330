import React from 'react';
import { Box, Chip } from '@mui/material';
import { PERMISSION_LABELS, USER_ROLES } from '@/constants/roles';
import { getUserHighestRole } from './helpers/dashboardHelpers';

const PermissionChips = ({ permissions, isUserOwner }) => {
  const role = getUserHighestRole(permissions);

  return (
    <Box display="flex" gap={1} flexWrap="wrap" alignItems="center">
      <Chip
        label={isUserOwner ? PERMISSION_LABELS[USER_ROLES.OWNER] : PERMISSION_LABELS[role]}
        variant="outlined"
        size="small"
        disabled
      />
    </Box>
  );
};

export default PermissionChips;
