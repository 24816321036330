import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { useNavigate, Link } from 'react-router-dom';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import { useSessionContext, useClaimValue } from 'supertokens-auth-react/recipe/session';
import { UserRoleClaim /* PermissionClaim */ } from 'supertokens-auth-react/recipe/userroles';

import Typography from '@mui/material/Typography';
import AppBar from './AppBar';
import useDebounce from '../../lib/useDebounce';
import { ReactComponent as Logo } from '../insights-by-qloo.svg';
import { ReactComponent as LogoSm } from '../insightsSm.svg';
import HeaderSearchField from './HeaderSearchField';
import {
  SEARCH_ENABLED,
  TABS,
  USABLE_PAGES,
} from '../../lib/pages';
import usePermission from '../../lib/usePermissionsBase';
import NavigationButtons from './NavigationButtons';
import UserBadge from './UserBadge';

export const TAB_LIST = Object.entries(TABS)
  .filter(([k]) => !USABLE_PAGES || USABLE_PAGES.indexOf(k) > -1)
  .map(([, v]) => v);

const makeRequestsLink = ({ query }) => `/requests${!!query && (`?${new URLSearchParams({
  requests: `https://api.qloo.com/search?query=${query}&history=${false}`,
}).toString()}`)}`;

const HeaderSearchFieldConnected = (props) => {
  const [query, setQuery] = useState();
  const navigate = useNavigate();
  const deQuery = useDebounce(query, 500);

  useEffect(() => {
    if (deQuery) {
      const link = makeRequestsLink({ query });
      navigate(link);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deQuery]);

  return (
    <HeaderSearchField
      {...props}
      onBlur={() => setQuery('')}
      value={query}
      onUpdateSearch={setQuery}
    />
  );
};

const StyledHeaderSearchFieldConnected = styled(HeaderSearchFieldConnected)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none !important',
  },
}));

export const useStyles = makeStyles((theme) => ({
  logoContainer: {
    minWidth: '80px',
  },
  logo: {
    opacity: 0.9,
    height: '41px',
    fill: 'rgba(0,0,0,0.8)',
    verticalAlign: 'middle',
    marginLeft: '1%',
    width: '10em',
  },
  logoLarge: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    paddingRight: '30px !important',
  },
  LogoSmall: {
    height: '25px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
  clientLogo: {
    height: '55px',
    marginRight: '20px',
    verticalAlign: 'baseline',
    paddingTop: '15px',
  },
}));

const ClientLogo = () => {
  const classes = useStyles();
  const claimValue = useClaimValue(UserRoleClaim);

  if (
    claimValue.loading === true
    || !claimValue.doesSessionExist
  ) {
    // console.log('Session is still loading OR we have not logged in yet.');
    return null;
  }

  const tenantId = claimValue.value[0];
  return (
    <img
      src={`${import.meta.env.PUBLIC_URL}/assets/clients/logo-${tenantId}.svg`}
      alt="logo"
      className={classes.clientLogo}
    />
  );
};

const Header = () => {
  const classes = useStyles();
  const [canShowSearchField, hasCustomLogo] = usePermission('api.route./search', 'feature.custom-logo');
  const sessionContext = useSessionContext();

  if (sessionContext.loading === true) {
    return null;
  }

  return (
    <AppBar>
      <Link to="/">
        <Typography
          variant="h6"
          noWrap
          className={classes.logoContainer}
        >
          {hasCustomLogo ? <ClientLogo />
            : (
              <>
                <LogoSm className={clsx(classes.logo, classes.LogoSmall)} />
                <Logo className={clsx(classes.logo, classes.logoLarge)} />
              </>
            )}
        </Typography>
      </Link>

      <NavigationButtons />

      <div className={classes.grow} />

      {
        !!SEARCH_ENABLED && canShowSearchField && <StyledHeaderSearchFieldConnected />
      }
      <UserBadge />
    </AppBar>
  );
};

export default Header;
