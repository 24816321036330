import DownloadIcon from '@mui/icons-material/Download';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import DownloadDialog from './DownloadDialog';

const MenuItemExportData = ({
  panelId, serializedInsightsParams, onDownloaded, ...props
}) => {
  const popupState = usePopupState({ variant: 'dialog', popupId: 'downloadDialog' });

  return (
    <>
      <MenuItem {...bindTrigger(popupState)} {...props}>
        <ListItemIcon><DownloadIcon /></ListItemIcon>
        <ListItemText>Export Data</ListItemText>
      </MenuItem>
      <DownloadDialog
        popupState={popupState}
        panelId={panelId}
        serializedInsightsParams={serializedInsightsParams}
        onDownloaded={onDownloaded}
      />
    </>
  );
};

export default MenuItemExportData;
