import WhatshotIcon from '@mui/icons-material/Whatshot';
import { Tooltip } from '@mui/material';
import Slider from '../Slider';

import styles from './MapHeatmap.module.scss';

const HeatmapRangeSlider = ({ value, onChange }) => (
  <Tooltip title="Range">
    <div className={styles.sliderContainer}>
      <WhatshotIcon fontSize="small" />
      <Slider
        min={0}
        max={100}
        value={value}
        onChange={onChange}
        step={1}
        withInput
        size="small"
        debounceDelay={0}
        inputProps={{
          variant: 'standard',
          disableUnderline: true,
          sx: { width: '25px' },
          className: styles.heatmapInput,
        }}
      />
    </div>
  </Tooltip>
);

export default HeatmapRangeSlider;
