import React from 'react';
import DashboardBodySkeleton from '../DashboardBody/DashboardBodySkeleton';
import DashboardHeaderSkeleton from '../DashboardHeader/DashboardHeaderSkeleton';

const DashboardViewSkeleton = (props) => (
  <div>
    <DashboardHeaderSkeleton />
    <DashboardBodySkeleton {...props} />
  </div>
);

export default DashboardViewSkeleton;
