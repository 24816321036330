import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api } from '@/constants';
import { getHash, serializeInsightsParams } from '@/utils';

export const tagsApi = createApi({
  reducerPath: 'tagsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${api.hostname}/api/${api.server}/v2/tags` }),
  tagTypes: ['Tags'],
  endpoints: (builder) => ({
    getTags: builder.query({
      query: ({
        query, parentType, tagTypes, limit, take, tags, offset, ...baseParams
      }) => {
        const urlSearchParams = new URLSearchParams();

        const filters = {
          'filter.query': query || undefined,
          'filter.tags': !query ? tags : undefined,
          'filter.parents.types': parentType,
          limit,
          offset,
          take,
          'filter.tag.types': tagTypes,
          'feature.typo_tolerance': query ? true : undefined,
        };

        Object.entries(filters).forEach(([key, value]) => {
          if (value !== undefined) {
            urlSearchParams.set(key, value);
          }
        });

        const serializedBaseParams = serializeInsightsParams(baseParams, {});

        return `?${urlSearchParams.toString()}&${serializedBaseParams}`;
      },
      providesTags: (result, error, args) => [{ type: 'Tags', id: getHash(args) }],
    }),
  }),
});

export const {
  useGetTagsQuery,
} = tagsApi;
