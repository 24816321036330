import React from 'react';
import { Plus } from 'react-feather';
import { Popover, TabButton } from '@/components';
import DashboardTabsAddButtonPanel from './DashboardTabsAddButtonPanel';

const DashboardTabsAddButton = () => (
  <Popover
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    renderContent={({ close }) => <DashboardTabsAddButtonPanel close={close} />}
    renderCustomTrigger={({ open }) => (
      <TabButton
        onClick={open}
        sx={{
          height: '100%',
          width: 38,
          minWidth: 38,
        }}
      >
        <Plus size={18} />
      </TabButton>
    )}
  />
);

export default DashboardTabsAddButton;
