import React from 'react';
import { Skeleton } from '@/components';
import QueryBuilderSkeleton from '../QueryBuilder/QueryBuilderSkeleton';

import styles from './DashboardHeader.module.scss';

const DashboardHeaderSkeleton = () => (
  <div className={styles.container}>
    <div className={styles.queryBuilder}>
      <QueryBuilderSkeleton />
    </div>

    {/* paddingTop 3 was added due to vertical aligning not working optimally  */}
    <div className="skeletonTopPadding">
      <Skeleton width={12} height={25} />
    </div>
  </div>
);

export default DashboardHeaderSkeleton;
