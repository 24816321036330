import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const mapboxApi = createApi({
  reducerPath: 'mapboxApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.mapbox.com' }),
  endpoints: (builder) => ({
    getReverseGeo: builder.query({
      query: (query) => `/geocoding/v5/mapbox.places/${query}.json?access_token=${import.meta.env.VITE_MAPBOX_KEY}`,
    }),
  }),
});

export const {
  useGetReverseGeoQuery,
} = mapboxApi;
