import { paramNames } from '@/constants';

const labelByGender = {
  male: 'Men',
  female: 'Women',
};

const getGenderLabel = (baseQuery) => {
  const gender = baseQuery[paramNames.signalDemographicsGender];

  return labelByGender[gender];
};

export default getGenderLabel;
