import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate, Route, Routes, useParams,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import logout from '@v1/lib/logout';
import userInformation from '@v1/lib/userInformation';
import DashboardPage from '@/screens/Dashboard/DashboardPage';
import NotFound from '@/screens/NotFound/NotFound';
import { setUser, setIdentity } from '@/store/slices/appSlice';
import { useLazyIdentityGetQuery, useLazyIdentitySubordinatesGetQuery } from '@/store/apis/identityApi';
import slugify from '@/utils/slugify';
import Dashboard, { PermissionError } from '@/constants/permissions/dashboard';
import HomePage from './screens/HomePage/HomePage';
import {
  AudienceBatchedRequestProvider,
  EntityBatchedRequestProvider,
  IdentityBatchedRequestProvider,
  TagBatchedRequestProvider,
} from './contexts';
import VeltIdentity from './VeltIdentity';

const findOrganizationId = (identity) => {
  if (identity?.hierarchy) {
    const orgIdentity = identity.hierarchy
      .find((hierarchyItem) => hierarchyItem.childTypeTagId === 'identity.type.company.unit');
    if (orgIdentity) {
      return orgIdentity.parentIdentityId;
    }
  }

  return null;
};

const DashboardWithTitleWrapper = () => {
  const { id, slug } = useParams();
  const dashboard = useSelector((state) => state.dashboards.items[id]);
  const title = dashboard?.title || id || '';

  useEffect(() => {
    const nextSlug = slugify(dashboard?.title);

    // Update url if slug is not present or title changes
    if (nextSlug && (!slug || slug !== nextSlug)) {
      window.history.replaceState(null, null, `/#/dashboards/view/${id}/${nextSlug}`);
    }
  }, [slug, id, dashboard?.title]);

  return (
    <>
      <Helmet>
        <title>Dashboard - {title}</title>
      </Helmet>
      <DashboardPage />
    </>
  );
};

const DashboardNoTabsWrapper = () => (
  <>
    <Helmet>
      <title>Qloo Insight Dashboard View</title>
    </Helmet>
    <DashboardPage />
  </>
);

const DashboardHomeWrapper = () => (
  <>
    <Helmet>
      <title>Qloo Insight Dashboard Database</title>
    </Helmet>
    <HomePage />
  </>
);

const ProtectedRoute = ({ permission, element, throwError = true }) => {
  const { identity } = useSelector((state) => state.app);

  if (
    identity
    && (
      (identity.calculatedPermissionTagIds || []).includes(permission.role)
      || (identity.calculatedPermissionTagIds || []).includes('role.superuser')
    )
  ) {
    return element;
  }

  if (throwError) {
    return (
      <element>
        {(() => {
          throw new PermissionError(permission);
        })()}
      </element>
    );
  }

  return <Navigate to="/" replace />;
};

const Router = () => {
  const dispatch = useDispatch();
  const { identity } = useSelector((state) => state.app);

  const [triggerIdentityGetQuery] = useLazyIdentityGetQuery();
  const [triggerIdentitySubordinates] = useLazyIdentitySubordinatesGetQuery();

  useEffect(() => {
    const init = async () => {
      const userInfo = userInformation();
      if (!userInfo?.id) {
        logout();
        return;
      }

      const identityFound = await triggerIdentityGetQuery({ supertokensExternalId: userInfo.id }).unwrap();
      const organizationId = findOrganizationId(identityFound);
      triggerIdentitySubordinates({ identityId: organizationId });

      const nextUser = {
        userId: userInfo.id,
        name: identityFound.fullName,
        email: userInfo.email,
        identityId: identityFound.identityId,
        organizationId,
      };

      Sentry.setUser({ email: userInfo.email });
      dispatch(setUser(nextUser));
      dispatch(setIdentity(identityFound));
    };

    init();
  }, []);

  if (!identity || !identity.calculatedPermissionTagIds) {
    return null;
  }

  return (
    <VeltIdentity>
      <AudienceBatchedRequestProvider>
        <EntityBatchedRequestProvider>
          <IdentityBatchedRequestProvider>
            <TagBatchedRequestProvider>
              <Routes>
                <Route
                  path="/dashboards"
                  element={(
                    <ProtectedRoute
                      permission={Dashboard.FEATURE}
                      element={<DashboardHomeWrapper />}
                    />
                  )}
                />
                <Route
                  path="/dashboards/view"
                  element={(
                    <ProtectedRoute
                      permission={Dashboard.VIEW}
                      element={<DashboardNoTabsWrapper />}
                    />
                  )}
                />
                <Route
                  path="/dashboards/view/:id"
                  element={(
                    <ProtectedRoute
                      permission={Dashboard.VIEW}
                      element={<DashboardWithTitleWrapper />}
                    />
                  )}
                />
                <Route
                  path="/dashboards/view/:id/:slug"
                  element={(
                    <ProtectedRoute
                      permission={Dashboard.VIEW}
                      element={<DashboardWithTitleWrapper />}
                    />
                  )}
                />
                {/* Not Found Route, place routes above it */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </TagBatchedRequestProvider>
          </IdentityBatchedRequestProvider>
        </EntityBatchedRequestProvider>
      </AudienceBatchedRequestProvider>
    </VeltIdentity>
  );
};

export default Router;
