import React from 'react';
import LogoSmLoading from '@v1/components/LogoSmLoading';
import styles from './EntityImage.module.scss';

const EntityImageLogo = ({ hasError, isLoading }) => {
  if (isLoading) {
    return <LogoSmLoading state="loading" style={styles.entityImageLogo} />;
  }

  if (hasError) {
    return <LogoSmLoading style={styles.entityImageLogo} />;
  }

  return null;
};

export default EntityImageLogo;
