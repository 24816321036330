import clsx from 'clsx';
import React from 'react';
import { Search } from 'react-feather';
import { LoaderBase } from '@v1/components/Loader';
import { Input } from '../Input';
import styles from './Omnisearch.module.scss';

const OmnisearchSearchInput = ({
  onClick, search, inputOnChange, isLoading,
}) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <div
    className={clsx({
      [styles.field]: true,
    })}
    onClick={onClick}
  >
    {isLoading ? <LoaderBase isLoading={isLoading} resetMargin size={18} /> : <Search size={18} strokeWidth={2} />}
    <div className={styles.inputContainer}>
      <Input
        autoFocus
        className={styles.input}
        onChange={inputOnChange}
        placeholder="Search by keyword"
        value={search}
      />
    </div>
  </div>
);

export default OmnisearchSearchInput;
