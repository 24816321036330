import { fieldByParamName } from '@/constants';
import { getIsFieldValueEmpty } from '@/utils';

import ParamLabel from '../ParamLabel';

const getChips = ({
  baseParams, color, onChange, paramNames, params,
}) => {
  const result = [];

  const baseParamsLabels = paramNames
    .filter((paramName) => fieldByParamName[paramName] && !getIsFieldValueEmpty(baseParams[paramName]))
    .map((paramName) => {
      const value = baseParams[paramName];
      return <ParamLabel key={paramName} paramName={paramName} value={value} />;
    });

  if (baseParamsLabels.length) {
    result.push({
      // eslint-disable-next-line react/jsx-no-undef,react/jsx-no-useless-fragment
      label: baseParamsLabels.length === 1 ? baseParamsLabels[0] : <>{baseParamsLabels}</>,
      disabled: true,
    });

    paramNames
      .filter((paramName) => fieldByParamName[paramName] && !getIsFieldValueEmpty(params[paramName]))
      .forEach((paramName) => {
        const value = params[paramName];
        if (Array.isArray(value)) {
          value.forEach((valueItem) => {
            result.push({
              color,
              label: <ParamLabel key={`${paramName}-${valueItem}`} paramName={paramName} value={valueItem} />,
              onDelete: () => {
                onChange({ key: paramName, value: value.filter((vItem) => vItem !== valueItem) });
              },
            });
          });
        } else {
          result.push({
            color,
            label: <ParamLabel key={paramName} paramName={paramName} value={value} />,
            onDelete: () => {
              onChange({ key: paramName, value: null });
            },
          });
        }
      });
  }

  return result;
};

export default getChips;
