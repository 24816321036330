import { ResponsiveCirclePacking } from "@nivo/circle-packing";
import { generateLibTree } from "@nivo/generators";
import { useState } from "react";

const commonProperties = {
  data: generateLibTree(),
  padding: 2,
  id: "name",
  value: "loc",
  labelsSkipRadius: 16,
};

const BubbleChart = () => {
  const [zoomedId, setZoomedId] = useState(null);
  return (
    <ResponsiveCirclePacking
      {...commonProperties}
      enableLabels
      labelsSkipRadius={16}
      labelsFilter={(label) => label.node.height === 0}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      leavesOnly
      zoomedId={zoomedId}
      motionConfig="slow"
      onClick={(node) => {
        setZoomedId(zoomedId === node.id ? null : node.id);
      }}
    />
  );
};

export default BubbleChart;
