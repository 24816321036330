export const TYPES_FILTERS = {
  OWNED_BY_ME: 'Owned by me',
  NOT_OWNED_BY_ME: 'Not owned by me',
  OWNED_BY_ANYONE: 'Owned by anyone',
  MY_FAVORITES: 'My favorites',
};

export const ORDER_TYPES = {
  NAME: 'Name',
  LAST_MODIFIED_BY_ME: 'Last edited by me',
  LAST_MODIFIED: 'Last updated',
  LAST_CREATED: 'Last created',
};
