import React from 'react';
import { Skeleton } from '@/components';

import styles from '../DashboardPanel.module.scss';

const PanelParamsSkeleton = ({ disabled }) => (
  <div className={styles.panelParams}>
    <Skeleton disabled={disabled} width={320} height={39} />
  </div>
);

export default PanelParamsSkeleton;
