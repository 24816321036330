import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TableRow,
} from '@mui/material';
import React from 'react';
import './Table.scss';

const TableSkeleton = ({
  columns,
}) => {
  return (
    <div className="table">
      <TableContainer className="MuiTable-root">
        <Table>
          <TableHead className="MuiTableHead-root">
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id || column.accessorKey} className="MuiTableCell-root">
                  {column.header || column.accessorKey}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className="MuiTableBody-root">
            {[...Array(5)].map(() => (
              <TableRow key={Math.random().toString(36).substr(2, 9)} className="MuiTableRow-root">
                {columns.map((column) => (
                  <TableCell key={`${column.id || column.accessorKey}-skeleton`} className="MuiTableCell-root">
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableSkeleton;
