import clsx from 'clsx';
import React from 'react';
import styles from './Omnisearch.module.scss';
import Tooltip from "@mui/material/Tooltip";

const OmnisearchResultsItem = ({
  className, children, isSelected, toolTipContent, ...rest
}) => (
  <Tooltip title={!!toolTipContent && toolTipContent} placement="bottom" arrow>
    <div
      className={clsx({
        [className]: className,
        [styles.resultsItem]: true,
        [styles.resultsItemSelected]: isSelected,
      })}
      {...rest}
    >
      <div className={clsx({
        [styles.resultsItemBg]: true,
        [styles.resultsItemBgHighlighted]: rest['aria-selected'],
      })}
      />
      {children}
    </div>
  </Tooltip>
);

export default OmnisearchResultsItem;
