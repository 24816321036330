import clsx from 'clsx';
import { urnsToDisplayName } from '@qloo/categories';
import React from 'react';
import { ReactComponent as Logo } from '@v1/components/LogoSm.svg';
import { getColorForEntity, formatEntityCategories } from '@/utils';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';
import EntityImage from '@/components/EntityImage';
import styles from './Omnisearch.module.scss';
import OmnisearchResultsItem from './OmnisearchResultsItem';

const hierarchyFromUrn = (urn) => urn?.replace(/^urn:(tag:)?/, '').split(':');
const isUrn = (maybeUrn) => maybeUrn?.startsWith?.('urn:');
const slugToSentenceCaseName = (slug) => (
  slug?.split(/^[-_]+/).map((word) => word[0].toUpperCase() + word.slice(1)).join(' ')
);

const bestGuessUrnName = (maybeUrn) => (
  !isUrn(maybeUrn)
    ? maybeUrn
    : hierarchyFromUrn(maybeUrn).map(slugToSentenceCaseName)
);

const EntitiesList = ({
  entities,
  entitiesStartIndex,
  entitiesGridClassName,
  getItemProps,
  resetPadding,
  showMini,
  valueBitmap,
}) => (
  <div
    className={clsx({
      [styles.entities]: true,
      [styles.entitiesMini]: showMini,
      [styles.resetPadding]: resetPadding,
    })}
  >
    <div className={clsx([styles.entitiesGrid, entitiesGridClassName])}>
      {entities.map((entity, i) => {
        const hasImage = entity.properties?.images && entity.properties.images.length > 0 && entity.properties.images[0]?.url
          ? entity.properties.images[0].url
          : entity.properties?.image?.url;

        return (
          <OmnisearchResultsItem
            className={clsx([
              styles.entitiesItem,
              styles.button,
            ])}
            isSelected={valueBitmap ? valueBitmap[entity.entity_id] : false}
            {...(getItemProps ? getItemProps({
              index: entitiesStartIndex + i,
              item: entity,
            }) : null)}
            toolTipContent={entity.disambiguation}
          >
            <div className={styles.entitiesItemImageContainer}>
              {hasImage ? <EntityImage className={styles.entitiesItemImage} entity={entity} />
                : <Logo className="logoSvg" />}
            </div>
            <div className={styles.entitiesItemInfo}>
              <span
                className={styles.entityCategoryText}
                style={{
                  '--dynamic-category-color':
                    `var(--category-${formatCategoryUrnToCssVar(entity?.subtype || entity?.types[0])})`,
                }}
              >
                {urnsToDisplayName([entity?.subtype] || entity?.types)?.map(bestGuessUrnName)}
              </span>
              <div className={styles.entitiesItemName}>{entity.name}</div>
              <div className={styles.entitiesItemDisambiguation}>
                {entity.disambiguation || ' '}
              </div>
              <div
                className={styles.entitiesItemCategory}
                style={{ color: `hsl(var(--${getColorForEntity(entity)}-tag-border))` }}
              >
                {formatEntityCategories(entity)}
              </div>
            </div>
          </OmnisearchResultsItem>
        );
      })}
    </div>
  </div>
);

export default EntitiesList;
