import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import Image from './Image';

const useStyles = makeStyles(() => ({
  img: {
    border: '1px solid black',
  },
  container: {
    position: 'relative',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: 'gray',
    fontSize: '1em',
  },
  imageBg: {
    position: 'absolute',
    opacity: 0.1,
    left: 0,
    top: 0,
    height: 'inherit',
    width: 'inherit',
  },
  noImg: {
    fill: 'red',
  },
  text: {
    marginTop: '1em',
  },
}));

const NoImageComponent = ({ width, className }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, className)}>
      <CloseIcon className={clsx(classes.imageBg)} style={{ fontSize: `${width}px` }} />
      <span className={classes.text}>No Image</span>
    </div>
  );
};

const BrokenImageComponent = ({ width, className }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, className)}>
      <BrokenImageIcon className={clsx(classes.imageBg, classes.noImg)} style={{ fontSize: `${width}px` }} />
      <span className={classes.text}>Error</span>
    </div>
  );
};

const ImageHighlightMissing = (props) => (
  <Image
    {...props}
    // BackgroundLoadingComponent={DefaultImage}
    NoImageComponent={NoImageComponent}
    ErrorComponent={BrokenImageComponent}
  />
);

export default ImageHighlightMissing;
