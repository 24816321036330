// hooks/useUserIdentity.js
import { useSelector } from 'react-redux';

function useUserIdentity() {
  const { user } = useSelector((state) => state.app);
  const { byId } = useSelector((state) => state.identities);
  return byId[user.identityId];
}

export default useUserIdentity;
