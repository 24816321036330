const getDashboardCountAndFirstId = (dashboardIds, items) => {
    let count = 0;
    let firstId = null;

    for (const id of dashboardIds) {
        if (items[id]) {
            count++;
            if (!firstId) {
                firstId = id;
            }
        }
    }

    return { count, firstId };
};

export default getDashboardCountAndFirstId;
