import React from 'react';
import DashboardToolbarSkeleton from '../DashboardToolbar/DashboardToolbarSkeleton';
import DashboardLayoutSkeleton from '../DashboardLayout/DashboardLayoutSkeleton';
import styles from './DashboardBody.module.scss';

const DashboardBodySkeleton = (props) => (
  <div className={styles.container}>
    <DashboardToolbarSkeleton {...props} />
    <DashboardLayoutSkeleton {...props} />
  </div>
);

export default DashboardBodySkeleton;
