import clsx from 'clsx';
import React from 'react';
import { useToggle } from '@/hooks';
import EntitiyImageLogo from './EntitiyImageLogo';
import styles from './EntityImage.module.scss';

const getImageUrl = (entity) => {
  if (entity.properties?.images && entity.properties.images.length) {
    return entity.properties.images[0].url;
  }
  return entity.properties?.image?.url;
};

const EntitiyImage = ({ className, entity, placeholder }) => {
  const hasErrorToggle = useToggle(false);
  const isWhiteSquareToggle = useToggle(false);
  const imageUrl = getImageUrl(entity);
  const isLoadingToggle = useToggle(true);
  const isLoadingSourceImageToggle = useToggle();
  const shouldUseSourceUrlToggle = useToggle(false);
  const sourceImageUrl = entity.properties?.image?.source_url;

  const onLoad = (e) => {
    isLoadingToggle.off();
    const { naturalWidth, naturalHeight } = e.target;

    if (naturalWidth === 30 && naturalHeight === 30) {
      isWhiteSquareToggle.on();
      hasErrorToggle.on();
    }
  };

  const onError = () => {
    isLoadingToggle.off();
    hasErrorToggle.on();
    if (sourceImageUrl) {
      isLoadingSourceImageToggle.on();
    }
  };

  const onSourceImageLoad = () => {
    shouldUseSourceUrlToggle.on();
    isLoadingSourceImageToggle.off();
  };

  const getBackgroundImageUrl = () => {
    if (hasErrorToggle.value || isWhiteSquareToggle.value) {
      return placeholder;
    }

    if (shouldUseSourceUrlToggle.value && sourceImageUrl) {
      return sourceImageUrl;
    }

    return imageUrl;
  };

  return (
    <div
      className={clsx({
        [styles.entityImage]: true,
        [className]: className,
      })}
      style={{
        backgroundImage: `url(${getBackgroundImageUrl()})`,
      }}
    >
      <EntitiyImageLogo
        hasError={hasErrorToggle.value || isWhiteSquareToggle.value}
        isLoading={isLoadingToggle.value || isLoadingSourceImageToggle.value}
      />
      {imageUrl && !hasErrorToggle.value && (
        <img
          alt={entity.name}
          onError={onError}
          onLoad={onLoad}
          src={imageUrl}
          style={{ display: 'none' }}
        />
      )}
      {hasErrorToggle.value && sourceImageUrl && (
        <img
          alt={entity.name}
          onError={isLoadingSourceImageToggle.off}
          onLoad={onSourceImageLoad}
          src={sourceImageUrl}
          style={{ display: 'none' }}
        />
      )}
    </div>
  );
};

export default EntitiyImage;
