export const USER_ROLES = {
  ADMIN: 'role.superuser',
  OWNER: 'role.owner',
  CREATOR: 'role.creator',
  VIEWER: 'role.viewer',
  EDITOR: 'role.editor',
  DELETER: 'role.deleter',
  COMMENTER: 'role.commenter',
  CLONE: 'role.clone',
};

export const PERMISSION_LABELS = {
  [USER_ROLES.ADMIN]: 'Admin',
  [USER_ROLES.OWNER]: 'Owner',
  [USER_ROLES.CREATOR]: 'Creator',
  [USER_ROLES.VIEWER]: 'Viewer',
  [USER_ROLES.EDITOR]: 'Editor',
  [USER_ROLES.COMMENTER]: 'Commenter',
  [USER_ROLES.CLONE]: 'Clone',
};

export const PERMISSION_DESCRIPTIONS = {
  [USER_ROLES.ADMIN]: 'Can view, edit, delete and manage.',
  [USER_ROLES.OWNER]: 'Can do all.',
  [USER_ROLES.CREATOR]: 'Can view and edit.',
  [USER_ROLES.VIEWER]: 'Can view content but cannot make any changes.',
  [USER_ROLES.EDITOR]: 'Can view and edit.',
  [USER_ROLES.COMMENTER]: 'Can view content and add comments.',
  [USER_ROLES.CLONE]: 'Can view and clone.',
};

export const PERMISSION_MAPPING = {
  [USER_ROLES.VIEWER]: ['role.viewer'],
  [USER_ROLES.CLONE]: ['role.viewer', 'role.clone'],
  [USER_ROLES.COMMENTER]: ['role.viewer', 'role.commenter'],
  [USER_ROLES.EDITOR]: ['role.viewer', 'role.clone', 'role.editor'],
  [USER_ROLES.DELETER]: ['role.viewer', 'role.editor', 'role.deleter'],
  [USER_ROLES.ADMIN]: ['role.viewer', 'role.editor', 'role.clone', 'role.deleter', 'role.superuser'],
};
