import React from 'react';
import { filterTypes, paramNames } from '@/constants';
import PanelFooterTable from './PanelFooterTable';
import { getQueryError } from '../FilterTags/helpers';
import styles from './DashboardPanel.module.scss';

const PanelFooter = ({
  panelId,
  panelParams,
  panelError,
  hasMorePages,
  hasData,
  updateExplorePagination, explorePagination, hasMoreExplorePages, hasSelectedCategory,
}) => {
  const filterType = panelParams?.[paramNames.filterType];
  const error = getQueryError(panelError);
  const hasError = Boolean(error);

  if (filterTypes.heatmap !== filterType
    && ((filterTypes.explore === filterType && hasSelectedCategory && (hasMoreExplorePages || explorePagination.offset > 0))
    || (filterTypes.explore !== filterType && hasData && !hasError))) {
    return (
      <PanelFooterTable
        panelId={panelId}
        panelParams={panelParams}
        hasMorePages={hasMorePages}
        explorePagination={explorePagination}
        hasMoreExplorePages={hasMoreExplorePages}
        updateExplorePagination={updateExplorePagination}
      />
    );
  }

  // Empty footer
  return (
    <div className={styles.panelFooter} />
  );
};

export default PanelFooter;
