import Downshift from 'downshift';
import React, {
  useMemo,
} from 'react';
import { interestTypes } from '@/constants';
import styles from './Omnisearch.module.scss';
import OmnisearchInner from './OmnisearchInner';

const OmnisearchContainer = ({ onChange, ...props }) => {
  const value = useMemo(() => props.value || [], [props.value]);

  const handleSelection = (itemToBeAdded) => {
    const nextItem = {
      id: itemToBeAdded.entity_id || itemToBeAdded.id,
      name: itemToBeAdded.name,
      type: itemToBeAdded.entity_id ? interestTypes.entity : interestTypes.tag,
    };

    const hasItem = value.some((item) => item.id === nextItem.id);

    if (!hasItem) {
      const nextValue = [
        ...value,
        nextItem,
      ];

      return onChange(nextValue);
    }

    // Remove it from array
    const nextValue = value.filter((item) => item.id !== nextItem.id);

    return onChange(nextValue);
  };

  return (
    <Downshift onChange={handleSelection} selectedItem={null}>
      {(downshift) => (
        <div className={styles.container} {...downshift.getRootProps({}, { suppressRefError: true })}>
          <OmnisearchInner downshift={downshift} onChange={onChange} value={value} />
        </div>
      )}
    </Downshift>
  );
};

export default OmnisearchContainer;
