import {
  Box, Button, ButtonGroup, Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { useToggle } from '@/hooks';

import HeatmapToggle from './HeatmapToggle';
import HeatmapRangeSlider from './HeatmapRangeSlider';
import HeatmapOpacitySlider from './HeatmapOpacitySlider';

const HeatmapControlsWrapper = ({ isCollapsible, ...props }) => {
  const controlsToggle = useToggle();

  return (
    <Box
      position="absolute"
      top="10px"
      left="10px"
      right="10px"
      display="flex"
      justifyContent="space-between"
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Collapse orientation="horizontal" in={!isCollapsible || controlsToggle.value}>
        <HeatmapControls {...props} />
      </Collapse>
      {isCollapsible && (
        <Button
          onClick={controlsToggle.toggle}
          variant="contained"
          color={controlsToggle.value ? 'secondary' : 'muted'}
          size="small"
          disableElevation
          noPadding
        >
          <WhatshotIcon fontSize="small" />
        </Button>
      )}
    </Box>
  );
};

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.gray.main}`,
  marginRight: 10,

  '& .MuiButtonGroup-grouped:not(:last-of-type)': {
    borderColor: theme.palette.gray.main,
  },
}));

const HeatmapControls = ({ heatmapControls, onControlsChange }) => {
  return (
    <Container>
      <ButtonGroup
        color="secondary"
        variant="text"
      >
        <HeatmapToggle value={heatmapControls.heatmapType} onChange={onControlsChange('heatmapType')} />
        <Button disableRipple noPadding sx={{ cursor: 'default' }}>
          <HeatmapRangeSlider value={heatmapControls.heatmapRange} onChange={onControlsChange('heatmapRange')} />
        </Button>
        <Button disableRipple noPadding sx={{ cursor: 'default' }}>
          <HeatmapOpacitySlider value={heatmapControls.heatmapOpacity} onChange={onControlsChange('heatmapOpacity')} />
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default HeatmapControlsWrapper;
