import categories from '@qloo/categories';
import tagsColorList from '@/utils/tagsColorList';

const colorByCategory = Object
  .values(categories).reduce((obj, category, index) => {
    const urnParts = category.urn.split(':');
    const colorIndex = index % tagsColorList.length;
    // Assign the color from the color list
    const color = tagsColorList[colorIndex];
    return { ...obj, [urnParts[urnParts.length - 1]]: color, [category.category]: color };
  }, {});

const urnToParts = (s) => (s || '').split(':').map((part) => part.toLowerCase());

export const getColorForTag = (tag) => {
  const typeParts = urnToParts(tag.type);
  const idParts = urnToParts(tag.id);
  const parentParts = (tag.parents || []).reduce((arr, parent) => [...arr, ...urnToParts(parent.type)], []);
  const match = [...typeParts, ...idParts, ...parentParts].find((part) => colorByCategory[part]);
  if (match) {
    return colorByCategory[match];
  }

  return '';
};

export const getColorForEntity = (entity) => {
  const typesParts = (entity.types || []).map((type) => urnToParts(type));
  const allTypeParts = typesParts.reduce((arr, typePartsArr) => [...arr, ...typePartsArr], []);

  const match = [...allTypeParts].find((part) => colorByCategory[part]);
  if (match) {
    return colorByCategory[match];
  }

  return '';
};
