import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const PermissionsValidatorWrapper = ({ component: Component, permission, additionalProps = {} }) => {
  const { identity } = useSelector((state) => state.app);

  const userIsAuthorizedByPermission = useMemo(() => {
    return !!(identity && (identity.calculatedPermissionTagIds || []).includes(permission));
  }, [identity, permission]);

  return <Component {...additionalProps} isAuthorized={userIsAuthorizedByPermission} />;
};

export default PermissionsValidatorWrapper;
