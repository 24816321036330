const explainRecsGPTPrompt = ({ inputEntities, recsEntities, recsCategoryUrn }) => {
  if (!inputEntities || !recsEntities || !recsCategoryUrn) {
    return false;
  }

  return [
    'Provide a concise, one-sentence summary (within 300 characters) of what these entities '
    + `and tags related to ${recsCategoryUrn} have in common. Focus on the main themes or `
    + `characteristics they share, but do not mention or include '${recsCategoryUrn}' or any `
    + 'similar identifier in your response:',
    ...inputEntities.map(({ name, disambiguation }) => `${name} - ${disambiguation}`),
    ...recsEntities.map(({ name }) => `Tag: ${name}`),
  ]
    .filter((n) => !!n)
    .join('\n');
};

export default explainRecsGPTPrompt;
