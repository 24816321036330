// eslint-disable-next-line arrow-body-style
export const missingMessage = (objectName) => {
  return `Warning: During deserializtion of '${objectName}', we found no value for error.message.`;
};

export const defaultUserMessage = () => 'An error has occurred and we are looking into it.';

/**
 * Base class for all custom errors, extending the native JavaScript Error.
 */
// eslint-disable-next-line no-class/no-class
class BaseError extends Error {
  static ErrorType = 'BaseError';

  /**
   * Guard to verify the error is an instance of BaseError.
   * @param {Error | undefined} error - The error to check.
   * @returns {boolean} True if the error is an instance of BaseError.
   */
  static isBaseError(error) {
    return error instanceof BaseError;
  }

  /**
   * Creates a new BaseError instance.
   * @param {object} options - The error options.
   * @param {string} options.message - The technical error message.
   * @param {string} [options.errorCode] - An optional error code for
   * categorizing the error.
   * @param {string} [options.userMessage] - An optional user-friendly error
   * message.
   * @param {object} [options.details] - An optional object that can contain any
   * details about the error.
   */
  constructor({
    message,
    errorCode = undefined,
    userMessage = undefined,
    details = {},
  }) {
    const finalUserMessage = userMessage || defaultUserMessage();

    /// Append errorCode only if it's defined and not already included
    /// in userMessage
    const codeMessage = (
      errorCode !== undefined
      && !finalUserMessage.includes(errorCode)
    ) ? ` (Code '${errorCode}')` : '';

    super(message);
    this.name = BaseError.ErrorType;
    /// Format the user message to include the error code if it exists
    this.userMessage = `${finalUserMessage}${codeMessage}`;
    this.errorCode = errorCode;
    this.details = details;
  }

  /**
   * Converts the error into a json object.
   * @returns {object} An object representing the error.
   */
  serialize() {
    return {
      name: this.name,
      message: this.message,
      userMessage: this.userMessage,
      errorCode: this.errorCode,
      details: this.details,
      stack: this.stack,
    };
  }

  /**
   * Converts an object into an Error instance.
   * @param {object} errorObject - The error object to convert.
   * @returns {BaseError} An instance of BaseError with properties populated
   * from the errorObject.
   */
  static deserialize(errorObject) {
    const finalMessage = errorObject.message
      || missingMessage(BaseError.ErrorType);

    const error = new this({
      message: finalMessage,
      userMessage: errorObject.userMessage,
      errorCode: errorObject.errorCode,
      details: errorObject.details,
    });
    error.name = BaseError.ErrorType;
    error.stack = errorObject.stack;
    return error;
  }
}

export default BaseError;
