// HACK to replace '.' in our keys, as Velt doesn't accept them
export const serializeObjectForVelt = (obj) => {
  if (Array.isArray(obj)) {
    return obj;
  }
  if (obj === undefined || obj === null) {
    return null;
  }

  if (typeof obj === 'object') {
    return Object.keys(obj)
      .reduce((result, key) => ({ ...result, [key.replace(/\./g, 'DOT')]: serializeObjectForVelt(obj[key]) }), {});
  }

  return obj;
};

export const deserializeObjectForVelt = (obj) => {
  if (Array.isArray(obj)) {
    return obj;
  }

  if (obj === undefined || obj === null) {
    return null;
  }

  if (typeof obj === 'object') {
    return Object.keys(obj).reduce((result, key) => ({ ...result, [key.replace(/DOT/g, '.')]: deserializeObjectForVelt(obj[key]) }), {});
  }

  return obj;
};
