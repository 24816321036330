import React from 'react';
import { Rating as MuiRating } from '@mui/material';
import { Star, AttachMoney } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  emptyIcon: {
    opacity: 0.3,
  },
  moneyIcon: {
    margin: '0 2px',
    color: 'black',
  },
  normalIcon: {
    margin: '0 2px',
  },
});

const Rating = ({
  onChange, value, defaultValue, max, useMoneyIcon,
}) => {
  const classes = useStyles();
  const Icon = useMoneyIcon ? AttachMoney : Star;
  const iconClass = useMoneyIcon ? classes.moneyIcon : classes.normalIcon;

  return (
    <MuiRating
      onChange={(e, nextValue) => onChange(nextValue)}
      value={value}
      defaultValue={defaultValue}
      max={max}
      icon={<Icon className={iconClass} />}
      emptyIcon={<Icon className={`${classes.emptyIcon} ${iconClass}`} />}
    />
  );
};

export default Rating;
