import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as Logo } from './LogoSm.svg';

export const useStyles = makeStyles({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  '@keyframes pulse': {
    '0%': {
      fill: 'white',
    },
    '100%': {
      fill: 'lightgray',
    },
  },
  logoSvg: {
    height: '50px',
    fill: '#EFEFEF',
  },
  logoSvgError: {
    // fill: 'red',
    opacity: 0.5,
  },
  logoText: {
    color: 'lightgray',
  },
  logoSvgPulse: {
    fill: 'white',
    // willChange: 'fill',
    animationName: '$pulse',
    animationDuration: '2s',
    animationTimingFunction: 'linear',
    animationDirection: 'alternate-reverse',
    animationIterationCount: 'infinite',
  },
});

const LogoSmLoading = ({ state, className }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.logoContainer, className)}>
      <Logo className={clsx(
        classes.logoSvg,
        {
          [classes.logoSvgPulse]: state === 'loading',
          [classes.logoSvgError]: state === 'error',
        },
      )}
      />
      {state === 'error'
        && <div className={classes.logoText}>Error loading data</div>}
    </div>
  );
};

export default LogoSmLoading;
