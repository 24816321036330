import { createApi } from '@reduxjs/toolkit/query/react';
import { getHash } from '@/utils';
import fetcherWithKey from '@v1/lib/fetching/fetcherWithKey';

const fetcher = fetcherWithKey();

const baseQuery = ({ baseUrl }) => async ({ url }) => {
  try {
    const result = await fetcher(`gIApi-${url}`, `${baseUrl}/${url}`);
    if (!result.ok) {
      const text = await result.text();
      throw new Error(text || `Request failed: ${baseUrl}/${url}}`);
    }

    const json = await result.json();
    if (json.json) {
      return {
        data: json.json,
      };
    }

    throw new Error(`Property 'json' missing from response': ${JSON.stringify(json)}}`);
  } catch (e) {
    return { error: e?.message };
  }
};

export const gIApi = createApi({
  reducerPath: 'gIApi',
  baseQuery: baseQuery({
    baseUrl: 'https://g.iapi.qloo.com',
  }),
  endpoints: (builder) => ({
    getExplainConcept: builder.query({
      method: 'GET',
      query: ({ name, urn }) => {
        return {
          method: 'GET',
          url: `explain-concept/"${encodeURIComponent(name)}" ${urn}`,
        };
      },
      providesTags: (result, error, arg) => [{ type: 'gIApi', id: getHash(arg) }],
    }),
    getGenreMusic: builder.query({
      method: 'GET',
      query: ({ genre }) => {
        return {
          method: 'GET',
          url: `genre-music/${encodeURIComponent(genre)}`,
        };
      },
    }),
  }),
  tagTypes: ['gIApi'],
});

export const {
  useGetExplainConceptQuery,
  useGetGenreMusicQuery,
} = gIApi;
