import isObject from './isObject';

const getIsFieldValueEmpty = (value) => (
  (Array.isArray(value) && !value.length)
  || value === undefined
  || value === null
  || (isObject(value) && Object.keys(value).length === 0)
);

export default getIsFieldValueEmpty;
