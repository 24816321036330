import { Layer } from '@urbica/react-map-gl';

const HeatmapLayer = ({ heatmapControls }) => {
  return (
    <Layer
      id="heatmap"
      type="heatmap"
      source="heatmapSource"
      paint={{
        // Increase the heatmap weight based on frequency and property magnitude
        'heatmap-weight': [
          'interpolate',
          ['linear'],
          ['get', heatmapControls.heatmapType],
          0, 0,
          7, 1,
        ],
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight
        'heatmap-intensity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          // zoom is 5 (or less) -> intensity 1
          0, 1,
          // zoom is 10 (or greater) -> intensity 3
          20, 5,
        ],
        // Adjust the heatmap radius by zoom level
        'heatmap-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          // zoom <= 1  = 1px
          1, 1,
          // zoom <= 10  = 20px
          10, heatmapControls.heatmapRange,
          // zoom >= 20  = 100px
          20, 344,
        ],
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        'heatmap-color': [
          'interpolate',
          ['linear'],
          ['heatmap-density'],
          0,
          'rgba(33,102,172,0)',
          0.2,
          'rgb(103,169,207)',
          0.4,
          'rgb(209,229,240)',
          0.6,
          'rgb(253,219,199)',
          0.8,
          'rgb(239,138,98)',
          1,
          'rgb(178,24,43)',
        ],
        // Transition from heatmap to circle layer by zoom level
        'heatmap-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          7, heatmapControls.heatmapOpacity,
          9, heatmapControls.heatmapOpacity,
        ],
      }}
    />
  );
};

export default HeatmapLayer;
