import { Box } from '@material-ui/core';
import { Button } from '@/components';
import { useDashboardActionsContext } from '../DashboardActionsProvider';
import { insightTypes, insightTypeLabels } from '@/constants';

const AddInsightPanel = () => {
  const dashboardActions = useDashboardActionsContext();

  const items = [
    {
      id: insightTypes.table,
      label: insightTypeLabels[insightTypes.table],
      onClick: () => dashboardActions.addPanel(),
    },
    {
      id: insightTypes.heatmap,
      label: insightTypeLabels[insightTypes.heatmap],
      onClick: () => dashboardActions.addHeatmapPanel(),
    },
  ];

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      height='100%'
      flexDirection='column'
    >
      <h3>Add Insight</h3>
      <Box display='flex' gridGap='8px'>
        {items.map((item) => (
          <Button
            key={item.id}
            onClick={() => item.onClick()}
          >
            {item.label}
          </Button>
        ))}
      </Box>
    </Box>
  )
};

export default AddInsightPanel;
