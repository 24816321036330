import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useActiveDashboard = () => {
  const { id } = useParams();
  const dashboard = useSelector((state) => state.dashboards.items[id]);

  return dashboard;
};

export default useActiveDashboard;
