import React, { useMemo } from 'react';
import { findPriority, useCategoryPerms } from '@v1/lib/usePermissions';
import clx from 'clsx';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import CANONICAL_TAG from '@/utils/canonicalTag';
import { useGetTagsQuery } from '@/store/apis/tagsApi';
import { paramNames } from '@/constants';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import useGPTExplainRecs from '@/hooks/useGPTExplainRecs';
import { Overlay, Skeleton } from '@/components';
import { serializeInsightsParams } from '@/utils';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';
import CategoryData from './CategoryData';
import ResumedCategoryData from './ResumedCategoryData';
import { tagTypesBySelectedCategory } from './constants';
import useTagsFetcher from './useTagsFetcher';
import validateQueryError from '../helpers/validateQueryError';
import PanelBodyError from '../PanelBodyError';
import styles from './PanelBodyExplore.module.scss';

const CategoryDataSkeleton = ({ conciseView }) => (
  <div className={styles.categoryData}>
    <div className={styles.overlay} />
    <div className={styles.categoryHeader}>
      <Skeleton variant="text" width={150} height={30} />
      {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
      <Skeleton variant="rectangular" width={30} height={30} style={{ marginLeft: 'auto' }} />
    </div>
    <Skeleton variant="text" width="100%" height={20} />
    <div className={styles.separator} />
    <Skeleton variant="rectangular" width="100%" height={50} />
    <div className={styles.separator} />
    {!conciseView && <Skeleton variant="rectangular" width="100%" height={150} />}
  </div>
);

export const CategoryDataView = ({
  category,
  onSelect,
  baseParams,
  conciseView,
  isEmptyBaseQuery,
  panel,
}) => {
  const filters = useMemo(() => ({
    parentType: category,
    take: 5,
    tags: CANONICAL_TAG,
    ...baseParams,
    ...panel.params,
  }), [category, baseParams, panel.params]);

  const {
    data: tagsResult,
    isLoading: isLoadingTags,
    error: tagsError,
  } = useGetTagsQuery(filters);

  const serializedInsightsParams = useMemo(() => {
    const tempParams = {
      ...panel.params,
      [paramNames.filterType]: category,
      [paramNames.take]: 3,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, category, panel.params]);

  const {
    data: entitiesResult,
    isLoading: isLoadingEntities,
    error: insightsError,
  } = useGetInsightQuery(serializedInsightsParams);

  const {
    result: gptResult,
    isLoading: isLoadingGPT,
    error: gptError,
  } = useGPTExplainRecs({ category, baseParams, panelParams: panel.params });

  const errorData = useMemo(() => {
    const errors = [insightsError, tagsError, gptError];
    return validateQueryError({ errors });
  }, [insightsError, tagsError, gptError]);
  const tagsFetcher = useTagsFetcher();
  const tagTypes = tagTypesBySelectedCategory[category] || [];

  useDeepCompareEffect(() => {
    if (tagTypes.length) {
      tagsFetcher.fetchByTagTypes({
        baseParams: { ...baseParams, ...panel.params },
        pagination: {
          [paramNames.offset]: 0,
          [paramNames.take]: 9,
        },
        selectedCategory: category,
        tagTypes,
      });
    }
  }, [baseParams, category, panel.params]);

  if (isLoadingEntities || isLoadingGPT || isLoadingTags) {
    return <CategoryDataSkeleton conciseView={conciseView} />;
  }

  const categoryColor = `var(--category-${formatCategoryUrnToCssVar(category)})`;

  return (
    <>
      {!conciseView && (
        <CategoryData
          category={category}
          onSelect={onSelect}
          tagsResult={tagsResult}
          entitiesResult={entitiesResult}
          gptResult={gptResult}
          error={errorData}
          isEmptyBaseQuery={isEmptyBaseQuery}
          tagTypes={tagTypes}
          tagsFetcher={tagsFetcher}
        />
      )}
      {conciseView && (
        <ResumedCategoryData
          category={category}
          onSelect={onSelect}
          tagsResult={tagsResult}
          entitiesResult={entitiesResult}
          categoryColor={categoryColor}
          error={errorData}
          isEmptyBaseQuery={isEmptyBaseQuery}
        />
      )}
    </>
  );
};

const CategoriesDataList = ({
  onSelect,
  baseParams,
  panel,
}) => {
  const categories = Object.keys(useCategoryPerms()).sort((a, b) => {
    const aPriority = findPriority(a);
    const bPriority = findPriority(b);
    if (aPriority !== bPriority) {
      return aPriority - bPriority;
    }
    return a.localeCompare(b);
  });
  const { isExploreConciseView } = useSelector((state) => state.dashboards);
  const isEmptyBaseQuery = useMemo(
    () => Object.keys(baseParams).length === 0
      || Object.values(baseParams).every(
        (value) => (Array.isArray(value) && value.length === 0) || !value,
      ),
    [baseParams],
  );

  return (
    <div className={styles.mainContainer}>
      <div className={clx(
        styles.categoriesDataListGrid,
        { [styles.categoriesDataListGridConcise]: isExploreConciseView },
      )}
      >
        {categories.map((category) => {
          return (
            <CategoryDataView
              baseParams={baseParams}
              category={category}
              key={category}
              panel={panel}
              onSelect={onSelect}
              conciseView={isExploreConciseView}
              isEmptyBaseQuery={isEmptyBaseQuery}
            />
          );
        })}
      </div>
      {isEmptyBaseQuery && (
        <Overlay>
          <PanelBodyError message="At least one base parameter is required to show the entities response." />
        </Overlay>
      )}
    </div>
  );
};

export default CategoriesDataList;
