import React from 'react';
import { Skeleton } from '@/components';

import styles from '../DashboardPanel.module.scss';

const PanelBodySkeleton = ({ disabled }) => (
  <div className={styles.panelBodyContainer}>
    <div style={{ height: '100%', width: '100%' }}>
      <Skeleton disabled={disabled} height={'98%'} />
    </div>
  </div>
);

export default PanelBodySkeleton;
