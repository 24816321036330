import { AudienceText } from '@/components';
import getAgeLabel from '@/utils/getAgeLabel';
import { paramNames, ageRangeLabels } from '@/constants';
import getGenderLabel from '../helpers/getGenderLabel';

import styles from './QueryBuilder.module.scss';

const DemographicsLabel = ({ params }) => {
  const ageLabel = getAgeLabel(params[paramNames.signalDemographicsAge], ageRangeLabels.length);
  const genderLabel = getGenderLabel(params);

  const audienceIds = params[paramNames.audiences] || [];

  if ((!ageLabel || !ageLabel.length) && !genderLabel && !audienceIds.length) {
    return 'Anyone';
  }

  const audiencesLabel = audienceIds.map((audienceId, index) => (
    <AudienceText key={audienceId} audienceId={audienceId} index={index} />
  ));

  return (
    <span className={styles.demographicsLabel}>
      {!!genderLabel && <span>{genderLabel}</span>}
      {!!ageLabel && <span>{ageLabel}</span>}
      {!!audienceIds.length && <span>{audiencesLabel}</span>}
    </span>
  );
};

export default DemographicsLabel;
