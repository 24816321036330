import React from 'react';
import { Table } from '@/components';

import { useDashboardActionsContext } from '../DashboardActionsProvider';
import { getColumns } from './helpers';
import styles from './DashboardPanel.module.scss';

const PanelBodyTable = ({
  data,
  panelId,
  filterType,
  tableControls,
  isFetching,
}) => {
  const dashboardActions = useDashboardActionsContext();
  const columns = getColumns(filterType);

  const handleSortChange = (nextSort) => {
    const nextSettings = {
      tableControls: {
        ...tableControls || {},
        sorting: nextSort(),
      },
    };

    dashboardActions.changePanelSettings({ panelId, nextSettings });
  };

  const tableData = data && data?.length ? data : [];

  return (
    <div className={styles.tableContainer}>
      <Table
        isLoading={isFetching}
        columns={columns}
        data={tableData}
        getRowId={({ row }) => row.entity_id}
        onSortingChange={handleSortChange}
        renderEmptyRowsFallback={() => <div />}
        sorting={tableControls?.sorting}
      />
    </div>
  );
};

export default PanelBodyTable;
