import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFetching: 0,
};

const fetchingSlice = createSlice({
  name: 'fetching',
  initialState,
  reducers: {
    incrementFetching(state) {
      /// redux uses immer under the hood, so we can mutate the state directly
      // eslint-disable-next-line no-param-reassign
      state.isFetching += 1;
    },
    decrementFetching(state) {
      if (state.isFetching > 0) {
        /// redux uses immer under the hood, so we can mutate the state directly
        // eslint-disable-next-line no-param-reassign
        state.isFetching -= 1;
      }
    },
  },
});

export const {
  incrementFetching,
  decrementFetching,
} = fetchingSlice.actions;

export default fetchingSlice;
