import React, { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { api } from '@/constants';
import { useBatchedRequests } from '@/hooks';
import { mergeAudiences } from '../store/slices/audiencesSlice';

const fetchAudiences = async (audienceIds) => {
  const url = `${api.hostname}/api/${api.server}/v2/audiences?filter.ids=${audienceIds.join(',')}`;

  const response = await fetch(url);
  if (response.ok) {
    const result = await response.json();
    return result?.results?.audiences || [];
  }

  return response.text();
};

const AudienceBatchedRequest = createContext(null);

export const AudienceBatchedRequestProvider = ({ children }) => {
  const dispatch = useDispatch();
  const onDone = (audiences) => {
    dispatch(mergeAudiences(audiences));
  };

  const audienceBatchedRequests = useBatchedRequests({ callback: fetchAudiences, onDone });
  return (
    <AudienceBatchedRequest.Provider value={audienceBatchedRequests}>
      {children}
    </AudienceBatchedRequest.Provider>
  );
};

export const useAudienceBatchedRequestsContext = () => useContext(AudienceBatchedRequest);
