import { useSelector } from 'react-redux';
import { useDashboardActionsContext } from '@/screens/Dashboard/DashboardActionsProvider';
import CategoriesDataList, { CategoryDataView } from './CategoriesDataList';
import SelectedCategoryContent from './SelectedCategoryContent';
import styles from './PanelBodyExplore.module.scss';

const PanelBodyExplore = ({
  baseParams, updateHasMoreExplorePages, pagination, panel, panelId,
}) => {
  const dashboardActions = useDashboardActionsContext();
  const exploreControls = panel.settings?.exploreControls || {};

  const handleSelectedCategory = (newSelectedCategory) => () => {
    dashboardActions.changePanelSettings(
      { panelId, nextSettings: { exploreControls: { ...exploreControls, selectedCategory: newSelectedCategory } } },
    );
  };

  const { isExploreConciseView } = useSelector((state) => state.dashboards);
  const { selectedCategory } = exploreControls;
  const { initialSelectedCategory } = exploreControls;

  if (selectedCategory) {
    return (
      <div className={styles.container}>
        <SelectedCategoryContent
          panelId={panelId}
          baseParams={baseParams}
          pagination={pagination}
          panel={panel}
          exploreControls={exploreControls}
          selectedCategory={selectedCategory}
          handleSelectedCategory={handleSelectedCategory}
          updateHasMoreExplorePages={updateHasMoreExplorePages}
        />
      </div>
    );
  }

  if (initialSelectedCategory) {
    return (
      <CategoryDataView
        baseParams={baseParams}
        category={initialSelectedCategory}
        onSelect={handleSelectedCategory}
        panel={panel}
        conciseView={isExploreConciseView}
      />
    );
  }

  return (
    <CategoriesDataList baseParams={baseParams} onSelect={handleSelectedCategory} panel={panel} />
  );
};

export default PanelBodyExplore;
