import filterTypes from '../constants/filterTypes';

const tableFilterTypesBitMap = {
  [filterTypes.artist]: true,
  [filterTypes.author]: true,
  [filterTypes.book]: true,
  [filterTypes.brand]: true,
  [filterTypes.destination]: true,
  [filterTypes.person]: true,
  [filterTypes.place]: true,
  [filterTypes.podcast]: true,
  [filterTypes.tvShow]: true,
  [filterTypes.videogame]: true,
};

const isTableFilterType = (filterType) => !!tableFilterTypesBitMap[filterType];

export default isTableFilterType;
