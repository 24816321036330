import DashboardPanel from '../DashboardPanel/DashboardPanel';
import AddInsightPanel from './AddInsightPanel';

const DashboardLayoutItem = ({ item, panel, dashboard }) => {
  if (item.i === 'add-insight-panel') {
    return <AddInsightPanel />;
  }

  return (
    <DashboardPanel dashboard={dashboard} panel={panel} />
  );
};

export default DashboardLayoutItem;
