import React from 'react';

import Helmet from 'react-helmet';
import Header from '../Header/index';

const TITLE = import.meta.env.VITE_SITE_TITLE || 'Qloo Insights';

const RouteAndTitle = ({ Component, title, ...props }) => (
  <div>
    <Header />
    <Helmet
      titleTemplate={`%s - ${TITLE}`}
      defaultTitle={TITLE}
    >
      <title>{title}</title>
    </Helmet>
    <Component {...props} />
    {/* <LogOut /> */}
  </div>
);

export default RouteAndTitle;
