'use client';

import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { VeltProvider } from '@veltdev/react';
import { ErrorBoundary } from '@/components';
import Router from './Router';
import AppErrorFallback from './AppErrorFallback';

import './styles/index.scss';
import '@qloo/design/src/colors.css';

dayjs.extend(relativeTime);

const App = () => (
  <ErrorBoundary FallbackComponent={AppErrorFallback}>
    <VeltProvider apiKey={import.meta.env.VITE_VELT_API_KEY}>
      <Router />
    </VeltProvider>
  </ErrorBoundary>
);

export default App;
