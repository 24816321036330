import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import { useCreateDashboardMutation } from '@/store/apis/dashboardsApi';
import { TooltipWarning } from "@/components/Tooltip";

const DashboardCreateButton = ({ isAuthorized, setCreateError }) => {
  const navigate = useNavigate();
  const [createDashboard, { isLoading, isError }] = useCreateDashboardMutation();

  const handleCreate = async () => {
    const { data } = await createDashboard();
    if (isError) {
      setCreateError(true)
      return;
    }
    navigate(`/dashboards/view/${data.id}`);
  };

  return (
    <TooltipWarning placement="top-start" message="You don't have permission to create a new dashboard." conditionalRender={!isAuthorized}>
      <div>
        <Button
          onClick={handleCreate}
          disabled={isLoading || !isAuthorized}
          isLoading={isLoading}
          variant="contained"
        >
          New Dashboard
        </Button>
      </div>
  </TooltipWarning>
  );
};

export default DashboardCreateButton;
