import { useDispatch, useSelector } from 'react-redux';
import { useVeltClient } from '@veltdev/react';
import { toggleDarkMode } from '@/store/slices/appSlice';

const useTheme = () => {
  const dispatch = useDispatch();
  const { isDarkMode } = useSelector((state) => state.app);
  const { client: veltClient } = useVeltClient();

  const toggleTheme = () => {
    document.documentElement.classList.toggle('dark');

    // remove scrollbars
    document.documentElement.style.overflow = 'hidden';
    // trigger reflow so that overflow style is applied
    document.body.clientWidth;
    // change scheme
    document.documentElement.setAttribute(
      'data-color-scheme',
      isDarkMode ? 'light' : 'dark',
    );
    // remove overflow style, which will bring back the scrollbar with the correct scheme
    document.documentElement.style.overflow = '';

    veltClient?.setDarkMode(!isDarkMode);
    dispatch(toggleDarkMode());
  };

  return {
    isDarkMode,
    toggleTheme,
  };
};

export default useTheme;
