import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import formatSubCategory from '@/utils/formatSubCategory';
import { useAudienceBatchedRequestsContext } from '@/contexts/AudienceBatchedRequestContext';
import { fetchAudienceStart } from '@/store/slices/audiencesSlice';

const AudienceText = ({ audienceId, className, index }) => {
  const audienceBatchedRequests = useAudienceBatchedRequestsContext();
  const dispatch = useDispatch();
  const audience = useSelector((state) => state.audiences.byId[audienceId]);

  const fetchIfNeeded = () => {
    if (!audience) {
      dispatch(fetchAudienceStart(audienceId));
      audienceBatchedRequests.queueArg(audienceId);
    }
  };

  useEffect(() => {
    fetchIfNeeded();
  }, []);

  const formattedAudienceId = formatSubCategory(audienceId);
  const displayText = formattedAudienceId.length > 11
    ? `${formattedAudienceId.slice(0, 11)}...`
    : formattedAudienceId;

  return (
    <>
      {typeof index === 'number' && index > 0 ? <span>{', '}</span> : null}
      <span className={clsx({ [className]: className })}>
        {audience?.name || displayText}
      </span>
    </>
  );
};

export default AudienceText;
