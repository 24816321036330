import useStateWithDebounce from './useStateWithDebounce';

const useSlider = ({
  value,
  defaultValue,
  min,
  max,
  debounceDelay,
  onChange,
}) => {
  const isRange = Array.isArray(value || defaultValue);
  const [sliderValue, setSliderValue] = useStateWithDebounce({
    baseValue: value || defaultValue,
    onChange,
    debounceDelay,
  });

  const [sliderMin, sliderMax] = isRange ? sliderValue : [];

  const handleSliderChange = (e, nextValue) => {
    setSliderValue(nextValue);
  };

  const handleInputChange = (nextValue) => {
    setSliderValue(Number(nextValue));
  };

  const updateSliderValue = (nextValue, otherBoundary, boundaryMin, boundaryMax, isMin) => {
    const nextBoundary = Number(nextValue);

    if (nextBoundary < boundaryMin || nextBoundary > boundaryMax) {
      setSliderValue(isMin ? [boundaryMin, otherBoundary] : [otherBoundary, boundaryMax]);
    } else {
      setSliderValue(
        isMin
          ? [Math.min(nextBoundary, otherBoundary), Math.max(nextBoundary, otherBoundary)]
          : [Math.min(otherBoundary, nextBoundary), Math.max(otherBoundary, nextBoundary)],
      );
    }
  };

  const handleMinChange = (nextValue) => {
    updateSliderValue(nextValue, sliderMax, min, max, true);
  };

  const handleMaxChange = (nextValue) => {
    updateSliderValue(nextValue, sliderMin, min, max, false);
  };

  const handleMarkClick = (markValue) => {
    if (!Array.isArray(sliderValue)) {
      setSliderValue(markValue);
      return;
    }

    const isClosestToMin = Math.abs(markValue - sliderMin) < Math.abs(markValue - sliderMax);
    setSliderValue(isClosestToMin ? [markValue, sliderMax] : [sliderMin, markValue]);
  };

  return {
    sliderValue,
    isRange,
    handleSliderChange,
    handleInputChange,
    handleMinChange,
    handleMaxChange,
    handleMarkClick,
  };
};

export default useSlider;
