import React, { useEffect, useMemo, useState } from 'react';
import { urnsToDisplayName } from '@qloo/categories';
import { useDebounce } from '@/hooks';
import { useGetTagsQuery } from '@/store/apis/tagsApi';
import SearchSelect from '@/components/SearchSelect/SearchSelect';

const CANONICAL_TAG = 'urn:tag:collection:canonical';

const SubCategorySelect = ({
  onChange,
  category,
  selectedSubCategories,
}) => {
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search);

  const filters = useMemo(() => ({
    query: debouncedSearch, parentType: category, take: 500, tags: CANONICAL_TAG,
  }), [debouncedSearch, category]);

  const { data: searchedTags, isFetching: isFetchingGetTags } = useGetTagsQuery(filters);

  const handleChangeSearch = (value) => {
    setSearch(value);
  };

  const handleClear = () => {
    setSearch('');
  };

  useEffect(() => {
    setSearch('');
  }, [category]);

  return (
    <SearchSelect
      search={search}
      name="subcategory"
      onChange={onChange}
      onClear={handleClear}
      isFetching={isFetchingGetTags}
      placeholder="Find a subcategory"
      ariaLabel="subcategory selection"
      data={searchedTags?.results?.tags}
      onChangeSearch={handleChangeSearch}
      title={urnsToDisplayName(category)}
      selectedData={selectedSubCategories}
    />
  );
};

export default SubCategorySelect;
