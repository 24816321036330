import { Box, Button, Typography } from '@mui/material';
import MuiSlider from '@mui/material/Slider';
import { useSlider } from '@/hooks';
import { InputV2 } from '../Input';

const MarkLabel = ({ label, onClick }) => (
  <Button color="inherit" variant="minimal" onClick={onClick} disableRipple>
    {label}
  </Button>
);

const Slider = ({
  value,
  min,
  max,
  marks,
  step = 1,
  defaultValue,
  debounceDelay,
  withInput,
  inputProps,
  onChange,
  ...props
}) => {
  const {
    sliderValue,
    isRange,
    handleSliderChange,
    handleInputChange,
    handleMinChange,
    handleMaxChange,
    handleMarkClick,
  } = useSlider({
    value, defaultValue, min, max, debounceDelay, onChange,
  });

  return (
    <Box
      display="flex"
      gap={isRange ? 4 : 1}
      flexDirection={isRange ? 'column' : 'row'}
      sx={{ width: '100%' }}
      alignItems={!isRange && 'center'}
    >
      <Box flex={1} display="flex" alignItems="center" padding={marks ? 2 : 0}>
        <MuiSlider
          value={sliderValue}
          min={min}
          max={max}
          step={step}
          fullWidth
          onChange={handleSliderChange}
          marks={
            marks?.map((mark) => ({
              ...mark,
              label: <MarkLabel label={mark.label} onClick={() => handleMarkClick(mark.value)} />,
            })) || undefined
          }
          {...props}
        />
      </Box>
      {withInput && isRange && (
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="body1">Min</Typography>
          <InputV2
            value={sliderValue[0]}
            defaultValue={defaultValue[0]}
            min={min}
            max={max}
            type="number"
            size="small"
            fullWidth
            step={step}
            onChange={handleMinChange}
            {...inputProps}
          />
          <Typography variant="body1">Max</Typography>
          <InputV2
            value={sliderValue[1]}
            defaultValue={defaultValue[1]}
            min={min}
            max={max}
            type="number"
            size="small"
            fullWidth
            step={step}
            onChange={handleMaxChange}
            {...inputProps}
          />
        </Box>
      )}
      {withInput && !isRange && (
        <InputV2
          value={sliderValue}
          defaultValue={defaultValue}
          min={min}
          max={max}
          step={step}
          type="number"
          size="small"
          onChange={handleInputChange}
          {...inputProps}
        />
      )}
    </Box>
  );
};

export default Slider;
