import { useRef } from 'react';
import debouncePromise from 'awesome-debounce-promise';
import { useAsync } from 'react-async-hook/dist/react-async-hook.cjs.development';
import defaultFetch from './defaultFetch';

const randomKey = () => [...Array(30)].map(() => Math.random().toString(36)[2]).join('');

// https://github.com/Andarist/use-constant
const useConstant = (fn) => {
  const ref = useRef();

  if (!ref.current) {
    ref.current = { v: fn() };
  }

  return ref.current.v;
};

const useKey = (key) => {
  const rKey = useConstant(() => randomKey());
  const toReturn = typeof key === 'string' ? key : Array.isArray(key) ? key.join('') : rKey;
  return toReturn;
};

const useFetch = (url, {
  fetchFn = defaultFetch, key: uKey, required, depends, ...config
} = {}) => {
  const key = useKey(uKey);
  const requiredOk = !(required?.findIndex((e) => !e) > -1);

  const { loading: isLoading, ...rest } = useAsync(() => {
    if (!requiredOk) {
      // console.log('useFetch indexed false', uKey);
      return null;
    }
    // console.log('useFetch indexed true', uKey);
    return fetchFn(key, url, config);
  }, depends ? [...depends, requiredOk] : [uKey, requiredOk], {
    setLoading: (state) => ({ ...state, loading: true }),
  });
  // console.log('useFetch', key, url, depends, isLoading, defaultFetch === fetchFn);
  return { isLoading, ...rest };
};

export default useFetch;

export const useFetchDebounced = (url, {
  debounceDelay = 500, fetchFn = defaultFetch, ...config
} = {}) => {
  const fetchDebounced = useConstant(() => debouncePromise(
    fetchFn,
    debounceDelay,
    {
      key: (ki) => ki,
      onlyResolvesLast: false,
    },
  ));
  return useFetch(url, { ...config, fetchFn: fetchDebounced });
};
