import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import MuiRadioGroup from '@mui/material/RadioGroup';
import './RadioGroup.scss';

const RadioGroup = ({ onChange, options, value }) => {
  const handleClickRadio = (event) => {
    onChange(value === event.target.value ? null : event.target.value);
  };

  return (
    <FormControl className="radio-group">
      <MuiRadioGroup value={value}>
        {options.map((option) => (
          <FormControlLabel
            key={`${option.label}_${option.value}`}
            control={
              <Radio onClick={handleClickRadio} size="small" />
            }
            label={option.label}
            value={option.value}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};

export default RadioGroup;
