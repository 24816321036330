import { capitalize } from '@/utils';

function formatSubCategory(urn) {
  const parts = urn.split(':');

  const label = parts.at(-1);

  return capitalize(label.replace(/_/g, ' '));
}

export default formatSubCategory;
