import React from 'react';
import { barChartTagTypesBitmap } from './constants';
import styles from './PanelBodyExplore.module.scss';

const CategoryDataTagsNames = ({ result, tagType }) => {
  if (barChartTagTypesBitmap[tagType]) {
    return (
      <div className={styles.categoryDataTagItemNamesBarChart}>
        {result
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(((tag) => (
            <div
              className={styles.categoryDataTagItemNamesBarChartItem}
              style={{ height: `${(tag.query?.affinity || 0) * 100}%` }}
            />
          )))}
      </div>
    );
  }
  return (
    <div className={styles.categoryDataTagItemNames}>
      {result.slice(0, 4).map((tag, index) => (
        <span className={styles.categoryDataTagItemName}>
          {index > 0 ? <span>{', '}</span> : null}
          <span>{tag.name}
          </span>
        </span>
      ))}
    </div>
  );
};

export default CategoryDataTagsNames;
