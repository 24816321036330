import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const getOneWeekAgoUTC = () => {
    return dayjs().subtract(7, 'day').utc().toISOString();
};

export default getOneWeekAgoUTC;
