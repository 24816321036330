import bestGuessUrnName from './bestGuessUrnName';

const formatAudienceId = (value) => {
  if (Array.isArray(value)) {
    return value.map((audienceId) => bestGuessUrnName(audienceId)?.pop()?.replace(/_/g, ' ')).filter(Boolean).join(', ');
  }

  return bestGuessUrnName(value)?.pop()?.replace(/_/g, ' ');
};

export default formatAudienceId;
