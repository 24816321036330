const findOrganizationId = (identity) => {
  if (identity?.hierarchy) {
    const orgIdentity = identity.hierarchy
      .find((hierarchyItem) => hierarchyItem.childTypeTagId === 'identity.type.company.unit');
    if (orgIdentity) {
      return orgIdentity.parentIdentityId;
    }
  }

  return null;
};

export default findOrganizationId;
