/* eslint-disable arrow-body-style */
import React from 'react';

import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import IdentityAware from './IdentityAware';

const SessionAuthRoute = ({
  element,
  ...options
}) => {
  return (
    <SessionAuth>
      <IdentityAware element={element} {...options} />
    </SessionAuth>
  );
};

export default SessionAuthRoute;
