/* eslint-disable prefer-destructuring */

/**
 * A typedef for simplifying the reference to WindowHandlerInterface.
 * @typedef {import('supertokens-website/utils/windowHandler/types').WindowHandlerInterface} WindowHandler
 */

/**
 * This example app uses HashRouter from react-router-dom. The SuperTokens SDK relies on
 * some window properties like location hash, query params etc. Because HashRouter places
 * everything other than the website base in the location hash, we need to add custom
 * handling for some of the properties of the Window API
 * @param {WindowHandler} original The original window handler
 * @returns {WindowHandler} The modified window handler
 */

export default function getWindowHandler(original) {
  return {
    ...original,
    location: {
      ...original.location,
      getSearch() {
        const currentURL = window.location.href;
        const firstQuestionMarkIndex = currentURL.indexOf('?');

        if (firstQuestionMarkIndex !== -1) {
          // Return the query string from the url
          let queryString = currentURL.substring(firstQuestionMarkIndex);

          // Remove any hash
          if (queryString.includes('#')) {
            queryString = queryString.split('#')[0];
          }

          return queryString;
        }

        return '';
      },
      getHash() {
        // Location hash always starts with a #, when returning we prepend it
        let locationHash = window.location.hash;

        if (locationHash === '') {
          return '#';
        }

        if (locationHash.startsWith('#')) {
          // Remove the starting pound symbol
          locationHash = locationHash.substring(1);
        }

        if (!locationHash.includes('#')) {
          // The remaining string did not have any "#" character
          return '#';
        }

        const locationSplit = locationHash.split('#');

        if (locationSplit.length < 2) {
          // The string contains a "#" but is followed by nothing
          return '#';
        }

        return `#${ locationSplit[1]}`;
      },
      getPathName() {
        let locationHash = window.location.hash;

        if (locationHash === '') {
          return '';
        }

        if (locationHash.startsWith('#')) {
          // Remove the starting pound symbol
          locationHash = locationHash.substring(1);
        }

        if (locationHash.includes('?')) {
          // Remove query
          locationHash = locationHash.split('?')[0];
        }

        if (locationHash.includes('#')) {
          // Remove location hash
          locationHash = locationHash.split('#')[0];
        }

        return locationHash;
      },
      getOrigin() {
        const origin = window.location.origin;
        return origin;
      },
      getHostName() {
        const hostName = window.location.host;
        return hostName;
      },
    },
  };
}

// new URL(window.location?.hash?.substr(1) || '/', window.location.origin)
