// TODO FIx eslint disabled line
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect, useRef } from 'react';
// TODO Fix eslint disabled line
// eslint-disable-next-line import/extensions
import generateIdenticon from './index.js';

const Jazzicon = ({ diameter, seed }) => {
  const ref = useRef(null);

  useEffect(() => {
    const container = generateIdenticon(diameter, seed);
    ref.current.innerHTML = '';
    ref.current.appendChild(container);
  }, [diameter, seed]);

  return <div ref={ref} style={{ width: diameter, height: diameter }} />;
};

export default Jazzicon;
