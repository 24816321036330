import clsx from 'clsx';
import React from 'react';
import { urnsToCSSClasses, urnToCategoryData } from '@qloo/categories';
import { AiOutlineTag } from 'react-icons/ai';
import { formatTagCategories, getColorForTag } from '@/utils';
import { useGetGenreMusicQuery, useGetExplainConceptQuery } from '../../store/apis/gIApi';
import OmnisearchResultsTopAdditionalElement from './OmnisearchResultsTopAdditionalElement';
import styles from './Omnisearch.module.scss';
import Button from '../Button';

const commaAnd = (strings) => {
  const count = strings.length;
  return strings.reduce(
    (result, s, index) => {
      return `${result}${index > 0 ? `${count > 2 ? ',' : ''} ${index === count - 1 ? 'and ' : ''}` : ''}${s}`;
    },
    '',
  );
};

const getIsMusicGenre = (urn) => {
  return typeof urn === 'string' && urn.indexOf('urn:tag:genre:music') > -1;
};

const OmnisearchResultsTopResult = ({ tag, isSelected, itemProps }) => {
  const { data: explainConceptData } = useGetExplainConceptQuery({
    name: tag.name, urn: tag.id,
  });
  const shortExplanation = explainConceptData?.explain_short;

  const isMusicGenre = getIsMusicGenre(tag.id);

  const { data: genreResult } = useGetGenreMusicQuery({ genre: tag.name }, { skip: !isMusicGenre });
  const notableArtists = genreResult?.notableArtists || [];
  const parentGenres = genreResult?.parentGenres || [];
  const parentGenresDescription = tag.name
    && parentGenres.length ? `${tag.name} is a form of ${commaAnd(parentGenres)}` : '';

  const name = tag.name || '';
  const types = tag.types || [];

  const description = tag?.properties.description
    || genreResult?.description
    || shortExplanation
    || parentGenresDescription
    || '';

  return (
    <Button className={styles.topResultContainer} color="transparent" type="button" {...itemProps}>
      <div className={styles.infoContainer}>
        <div className={clsx({
          [styles.topResultImage]: true,
          [urnsToCSSClasses(types)]: true,
        })}
        >
          <AiOutlineTag size={32} color={urnToCategoryData(tag.id)?.colors?.primary || 'hsl(var(--gray))'} />
        </div>
        <div className={styles.topResultTopRight}>
          <div className={styles.topResultName}>{name}</div>
          <div className={styles.topResultCategories} style={{ color: `hsl(var(--${getColorForTag(tag)}-tag-border))` }}>
            {formatTagCategories(tag.type || '')}
          </div>
        </div>
      </div>
      {description && <OmnisearchResultsTopAdditionalElement title="Description" content={description} />}
      {notableArtists && notableArtists.length > 0 && (
        <OmnisearchResultsTopAdditionalElement
          title="Notable Artists"
          content={notableArtists.join(', ')}
        />
      )}
      <Button className={styles.topResultSelectButton} color="primary" variant="text">
        {isSelected ? 'Deselect' : 'Select'}
      </Button>
    </Button>
  );
};

export default OmnisearchResultsTopResult;
