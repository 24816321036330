import { Box } from '@mui/material';
import Button from '../Button';
import LocationSelector from './LocationSelector';
import RadiusSelector from './RadiusSelector';

import styles from './MapDrawShape.module.scss';

const filterLocationResults = (results) => results.filter(
  (r) => !!r?.geometry && r.geometry.type !== 'LineString',
);

const MapControls = ({
  location,
  color,
  size,
  onLocationSelect,
  onRadiusChange,
  onReset,
}) => {
  const isPoint = location.geoJson?.type === 'Point';

  return (
    <Box className={styles.mapControls} height={size === 'small' ? '40px' : '64px'}>
      <LocationSelector
        location={location}
        className={styles.locationSelector}
        onSelected={onLocationSelect}
        filterResults={filterLocationResults}
        color={color}
        size={size}
      />
      {isPoint && <RadiusSelector radius={location.radius} onChange={onRadiusChange} />}
      {!!onReset && !!location.geoJson && (
        <Button
          color="secondary"
          variant="text"
          className={styles.resetButton}
          onClick={onReset}
        >
          Clear
        </Button>
      )}
    </Box>
  );
};

export default MapControls;
