const Map = () => (
  <svg
    version="1.1"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m88.594 40h-15.938c3.5938-6.25 6.7188-12.812 6.7188-17.031 0.15625-10-8.125-18.281-18.281-18.281s-18.281 8.2812-18.281 18.281c0 4.2188 3.125 10.938 6.7188 17.031h-38.125c-3.2812 0-5.9375 2.6562-5.9375 5.9375v43.594c0 3.2812 2.6562 5.9375 5.9375 5.9375h77.031c3.2812 0 5.9375-2.6562 5.9375-5.9375v-43.594c0-3.2812-2.6562-5.9375-5.7812-5.9375zm2.6562 7.6562v29.844l-11.719-5.625zm-30.156-39.844c8.4375 0 15.156 6.875 15.156 15.156 0 6.5625-10 21.875-15.156 29.062-5.1562-7.1875-15.156-22.5-15.156-29.062 0-8.2812 6.875-15.156 15.156-15.156zm-1.25 47.812c0.3125 0.46875 0.78125 0.625 1.25 0.625s0.9375-0.15625 1.25-0.625 4.375-5.7812 8.4375-12.344h9.6875l-11.406 23.594-49.062-23.75h31.562c4.0625 6.5625 7.9688 12.031 8.2812 12.5zm-48.438-12.5h1.25l56.562 27.188c0.15625 0.15625 0.46875 0.15625 0.625 0.15625 0.625 0 1.0938-0.3125 1.4062-0.9375l12.656-26.406h4.5312c0.46875 0 0.9375 0.15625 1.25 0.3125l-13.594 28.438c-0.3125 0.78125 0 1.7188 0.78125 2.0312l14.375 6.875v5.9375l-49.219-23.594c-0.78125-0.3125-1.7188 0-2.0312 0.78125l-13.594 28.281h-5.9375l14.688-30.625c0.15625-0.3125 0.15625-0.78125 0-1.25s-0.46875-0.78125-0.78125-0.9375l-25.625-12.188v-1.25c0-1.5625 1.25-2.8125 2.6562-2.8125zm-2.6562 46.25v-38.75l22.969 11.094-14.688 30.469h-5.625c-1.4062 0-2.6562-1.25-2.6562-2.8125zm79.844 2.8125h-58.75l12.344-25.469 49.062 23.594c-0.46875 1.0938-1.5625 1.875-2.6562 1.875z" />
      <path d="m70.156 22.969c0-5-4.0625-9.0625-9.0625-9.0625s-9.0625 4.0625-9.0625 9.0625 4.0625 9.0625 9.0625 9.0625 9.0625-4.0625 9.0625-9.0625zm-14.844 0c0-3.2812 2.6562-5.9375 5.9375-5.9375s5.9375 2.6562 5.9375 5.9375-2.6562 5.9375-5.9375 5.9375-5.9375-2.6562-5.9375-5.9375z" />
    </g>
  </svg>
);

export default Map;
