const Donut = () => (
  <svg
    version="1.1"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m50 12c-10.086-0.027344-19.766 3.9688-26.898 11.102-7.1328 7.1328-11.129 16.812-11.102 26.898-0.027344 10.086 3.9688 19.766 11.102 26.898 7.1328 7.1328 16.812 11.129 26.898 11.102 10.086 0.027344 19.766-3.9688 26.898-11.102 7.1328-7.1328 11.129-16.812 11.102-26.898 0.027344-10.086-3.9688-19.766-11.102-26.898-7.1328-7.1328-16.812-11.129-26.898-11.102zm24.699 11.801-12.098 12.199c-3.7539-3.2773-8.6211-4.9961-13.602-4.8008v-17.199h1c9.1875-0.023438 18.027 3.4844 24.699 9.8008zm-24.699 9.3984c5.2227 0.011719 10.141 2.4531 13.312 6.6055 3.168 4.1484 4.2266 9.5391 2.8633 14.578-1.3633 5.043-4.9922 9.1641-9.8203 11.152-4.8281 1.9883-10.309 1.6172-14.824-1.0039-4.5195-2.6172-7.5625-7.1914-8.2344-12.371-0.67188-5.1758 1.1055-10.375 4.8047-14.059 3.1523-3.1602 7.4375-4.9219 11.898-4.9023zm-25.5-8.6992c6.0234-6.0352 14-9.7227 22.5-10.398v17.297c-3.9062 0.63281-7.5156 2.4883-10.301 5.3008-3.457 3.3672-5.4375 7.9727-5.5 12.801l-17.199-1.5c0.50391-8.8555 4.2383-17.219 10.5-23.5zm51 51c-6.75 6.7773-15.922 10.586-25.488 10.59-9.5664 0-18.738-3.8008-25.496-10.574-6.7539-6.7695-10.539-15.949-10.516-25.516l17.199 1.5c0.39453 4.457 2.3438 8.6328 5.5 11.801 3.5273 3.5273 8.3125 5.5117 13.301 5.5117s9.7734-1.9844 13.301-5.5117c3.3945-3.418 5.3555-8 5.4844-12.812 0.13281-4.8125-1.582-9.4961-4.7852-13.09l12.199-12.098c6.4219 6.8203 9.9375 15.875 9.8047 25.242-0.12891 9.3672-3.8945 18.316-10.504 24.957z" />
  </svg>
);

export default Donut;
