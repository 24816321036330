const validateQueryError = ({ errors } = {}) => {
  if (!errors) {
    return null;
  }
  const hasSignalInterestError = errors.some(
    (error) => error?.data?.errors?.some((e) => e.path === 'signal.interests.entities'),
  );
  if (hasSignalInterestError) {
    return null;
  }

  const hasDestinationSubtypeError = errors.some(
    (error) => error?.data?.errors?.[0]?.subtype === 'urn:entity:destination',
  );
  if (hasDestinationSubtypeError) {
    return null;
  }

  if (
    errors.some(
      (error) => error?.data?.errors?.[0]?.message
        === 'at least one valid signal or filter is required for entity insights',
    )
  ) {
    return null;
  }

  // Finds and returns the first error in the `errors` array that contains either a `path` or `message`
  // property within its nested errors.
  const firstErrorWithPathOrMessage = errors.find(
    (error) => error?.data?.errors?.some((e) => e.path || e.message),
  );

  return firstErrorWithPathOrMessage?.data?.errors[0] || null;
};

export default validateQueryError;
