import { paramNames } from '@/constants';
import hasGeo from './hasGeo';

const DEFAULT_FILTER_PARAM_NAMES = [
  paramNames.filterRating,
  paramNames.filterPrice_level,
  paramNames.filterPopularity,
  paramNames.filterExternalExists,
];

const getFilterParamNames = (type) => {
  const isGeo = hasGeo(type);

  if (isGeo) {
    return [
      ...DEFAULT_FILTER_PARAM_NAMES,
      paramNames.filterLocation,
    ];
  }

  return DEFAULT_FILTER_PARAM_NAMES;
};

export default getFilterParamNames;
