import React from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';

const Tooltip = ({
  arrow, children, followCursor, placement, ...props
}) => {
  return (
    <MuiTooltip arrow={arrow} followCursor={followCursor} placement={placement} {...props}>
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
