// Unified helper function to format range labels
function formatRange(rangeKey) {
  switch (rangeKey) {
    case '24_and_younger':
      return 'up to 24';
    case '25_to_29':
      return '25 to 29';
    case '30_to_34':
      return '30 to 34';
    case '35_to_44':
      return '35 to 44';
    case '45_to_54':
      return '45 to 54';
    case '55_and_older':
      return '55 and older';
    case '35_and_younger':
      return '35 and younger';
    case '36_to_55':
      return 'between 36 and 55';
    case '36_and_older':
      return '36 and older';
    case '55_and_younger':
      return '55 and younger';
    case '-35_and_55+':
      return '35 and younger or 55 and older';
    default:
      return '';
  }
}
// Define the current age ranges
const ageRanges = {
  '24_and_younger': { min: 0, max: 24 },
  '25_to_29': { min: 25, max: 29 },
  '30_to_34': { min: 30, max: 34 },
  '35_to_44': { min: 35, max: 44 },
  '45_to_54': { min: 45, max: 54 },
  '55_and_older': { min: 55, max: Infinity },
};

// Define the legacy age ranges
const legacyAgeRanges = {
  '35_and_younger': { min: 0, max: 35 },
  '36_to_55': { min: 36, max: 55 },
  '36_and_older': { min: 36, max: Infinity },
  '55_and_younger': { min: 0, max: 55 },
  '-35_and_55+': {
    min: 0, max: 35, alternateMin: 55, alternateMax: Infinity,
  },
};

// Combine all ranges into a single object
const allAgeRanges = { ...ageRanges, ...legacyAgeRanges };

// Function to summarize selected age ranges
function getAgeLabel(selectedRangesValue) {
  const selectedRanges = selectedRangesValue || [];
  if (selectedRanges?.length === 0) return ''; // Show nothing if no age range is selected
  if (selectedRanges?.length === 1) return `aged ${formatRange(selectedRanges[0])}`;

  // Create a sorted copy of selectedRanges
  const sortedRanges = [...selectedRanges].sort((a, b) => {
    const rangeA = allAgeRanges[a];
    const rangeB = allAgeRanges[b];
    if (rangeA && rangeB) {
      return rangeA.min - rangeB.min;
    }
    return 0;
  });

  const groups = [];
  let currentGroup = [sortedRanges[0]];

  for (let i = 1; i < sortedRanges?.length; i += 1) {
    const prevRange = allAgeRanges[sortedRanges[i - 1]];
    const currentRange = allAgeRanges[sortedRanges[i]];

    // Check if ranges are contiguous or overlapping
    if (
      prevRange && currentRange
      && (prevRange.max >= (currentRange.min - 1)
        || (prevRange.alternateMax && prevRange.alternateMax >= currentRange.min - 1))
    ) {
      currentGroup.push(sortedRanges[i]);
    } else {
      groups.push(currentGroup);
      currentGroup = [sortedRanges[i]];
    }
  }
  groups.push(currentGroup);

  // Summarize each group
  const summaries = groups.map((group) => {
    const firstRange = allAgeRanges[group[0]];
    const lastRange = allAgeRanges[group[(group?.length ?? 0) - 1]];

    if (!firstRange || !lastRange) {
      return ''; // Safety check to prevent undefined access
    }

    const minAge = firstRange.min;
    const maxAge = lastRange.max;
    const alternateMinAge = firstRange.alternateMin;

    // Handle future non-contiguous groups if needed using alternateMaxAge
    /* eslint-disable-next-line no-unused-vars */
    const alternateMaxAge = lastRange.alternateMax;

    if (minAge === 0 && maxAge === Infinity) return 'all ages';
    if (minAge === 0) return `up to ${maxAge}`;
    if (maxAge === Infinity) return `${minAge} and older`;
    if (alternateMinAge !== undefined) return `${minAge} and younger or ${alternateMinAge} and older`;
    return `${minAge} to ${maxAge}`;
  }).filter((summary) => summary !== ''); // Filter out any empty summaries

  // Join the summaries
  if (summaries.includes('all ages')) {
    return 'all ages';
  } if (summaries?.length === 1) {
    return `aged ${summaries[0]}`;
  }
  const lastSummary = summaries.pop();
  return `aged ${summaries.join(', ')} and ${lastSummary}`;
}

export default getAgeLabel;
