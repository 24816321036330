import { useSelector } from 'react-redux';

const useUserDashboardIdentity = (dashboardId) => {
  const { identity } = useSelector((state) => state.app);

  const dashboard = useSelector((state) => state.dashboards.items[dashboardId]);

  return dashboard?.identityLinks?.find((identityLink) => identityLink.identityId === identity.identityId);
};

export default useUserDashboardIdentity;
