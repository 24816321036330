import React, { useState } from 'react';
import { Avatar, Box, Typography, Checkbox } from '@mui/material';
import Jazzicon from '../../../../../../src/lib/jazzicon/Jazzicon.js';
import style from './Share.module.scss'
import ChangePermissionUser from './ChangePermissionUser'
import toHash from '@/utils/toHash'

const diameter = 36;

const ShareUserChipAvatar = ({ dashboardId, user, onClick, showSelectPermission, isSelected }) => {
    const { identityId, fullName, name, grantedPermissionTagIds, loginEmailAddress: email } = user;
    const [avatarUrl, setAvatarUrl] = useState('');
    const nameUser = fullName || name;

    return (
        <Box className={style.container}>
            <Box className={style.containerName} onClick={!showSelectPermission && onClick}>
                <Avatar
                    src={avatarUrl}
                    alt={nameUser}
                    sx={{
                        width: diameter, height: diameter, position: 'absolute',
                    }}
                >
                    {!avatarUrl && (
                        <Jazzicon
                            diameter={diameter}
                            seed={toHash(identityId || dashboardId)}
                        />
                    )}
                </Avatar>
                {!avatarUrl && (
                    <Box className={style.avatarLabel} width={diameter} height={diameter}>
                        {nameUser?.slice(0, 1)}
                    </Box>
                )}
                <Box display='flex' flexDirection={'column'}>
                    <Typography
                        ml={1}
                        variant="subtitle2"
                        sx={{ color: 'hsl(var(--gray))', whiteSpace: 'nowrap' }}
                    >
                        {nameUser}
                    </Typography>
                    <Typography
                        ml={1}
                        variant="subtitle2"
                        fontSize={12}
                        sx={{ color: 'hsl(var(--gray))', whiteSpace: 'nowrap' }}
                    >
                        {email}
                    </Typography>
                </Box>
            </Box>

            <Box>
                {showSelectPermission && !isSelected && (
                <ChangePermissionUser dashboardId={dashboardId} identityId={identityId} currentPermission={grantedPermissionTagIds} isRemovePermission />
                )}
                {!showSelectPermission && (
                    <Checkbox
                        checked={isSelected}
                        onChange={onClick}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{
                            '& .MuiSvgIcon-root': { fontSize: 21 },
                            width: 26,
                            height: 26,
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};

export default ShareUserChipAvatar;
