import stringSimilarity from 'string-similarity';

const getEntitiesWithoutMatchingFirstTag = ({ entityResults, tagResults }) => {
  if (entityResults.length && tagResults.length) {
    const firstEntityName = entityResults[0].name.toLowerCase();
    const firstTagName = tagResults[0].name.toLowerCase();

    if (firstEntityName === firstTagName) {
      return entityResults.slice(1);
    }
  }

  return entityResults;
};

const getBestMatchedTag = ({ tagResults, search }) => tagResults.reduce((bestMatch, tag) => {
  const similarity = stringSimilarity.compareTwoStrings(search.toLowerCase(), tag.name.toLowerCase());
  return (similarity > bestMatch.similarity) ? { tag, similarity } : bestMatch;
}, { tag: null, similarity: 0 });

const getTopResultEntitiesAndTags = ({
  bestMatchedTag,
  entities,
  shouldTopResultBeTag,
  tagResults,
}) => {
  if (shouldTopResultBeTag) {
    return {
      entities,
      tags: tagResults.filter((tag) => bestMatchedTag.tag.id !== tag.id),
      topResult: bestMatchedTag.tag,
    };
  }

  if (entities.length) {
    return {
      entities: entities.slice(1),
      tags: tagResults,
      topResult: entities[0],
    };
  }

  if (tagResults.length) {
    return {
      entities,
      tags: tagResults.slice(1),
      topResult: tagResults[0],
    };
  }

  return {
    entities,
    tags: tagResults,
    topResult: null,
  };
};

const getOmnisearchResults = ({
  entityResults,
  search,
  tagResults,
}) => {
  const bestMatchedTag = getBestMatchedTag({ tagResults, search });
  const shouldTopResultBeTag = bestMatchedTag.tag && bestMatchedTag.similarity > 0.8;
  return getTopResultEntitiesAndTags({
    bestMatchedTag,
    entities: entityResults,
    shouldTopResultBeTag,
    tagResults,
  });
};

export default getOmnisearchResults;
