import React from 'react';
import MuiButton from '@mui/material/Button';

import styles from './ModalResetButton.module.scss';

const ModalResetButton = ({ onReset, children }) => {
  return (
    <div className={styles.resetWrapper}>
      <MuiButton
        color="muted"
        variant="underlined"
        uppercase
        size="xs"
        font="Inter"
        onClick={onReset}
      >
        {children ?? 'Clear'}
      </MuiButton>
    </div>
  );
};

export default ModalResetButton;
