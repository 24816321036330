import { IconButton, InputBase, Paper } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { TooltipWarning } from '@/components/Tooltip';

const DashboardSearchInput = ({ onChange, value, disabled }) => (
  <TooltipWarning placement="bottom" message="You don't have dashboards to search" conditionalRender={disabled}>
    <Paper
      sx={{
        width: '70%',
        p: '3px 6px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search your dashboards"
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
    </Paper>
  </TooltipWarning>
);

export default DashboardSearchInput;
