import fetcherWithKey from './fetcherWithKey';
import fetcherJson from './fetcherJson';

const debugFetch = (...params) => {
  console.debug('Fetching', ...params);
  return fetch(...params).finally(() => { console.debug('fetch complete'); });
};

// const debugFetchWrap = (fn) => (...params) => {
//   console.debug('Fetching start', ...params);
//   return fn(...params);
// };

// debugFetchWrap()
const defaultFetch = fetcherWithKey(fetcherJson(debugFetch));

export default defaultFetch;
