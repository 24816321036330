import React, { createContext, useContext, useMemo } from 'react';

const SessionContext = createContext(null);

export const SessionContextProvider = ({ children, claimValue, sessionContext }) => {
  const value = useMemo(() => ({
    claimValue,
    sessionContext,
  }), [claimValue, sessionContext]);

  return (
    <SessionContext.Provider value={value}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext = () => useContext(SessionContext);
