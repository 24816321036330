import { paramNames } from '@/constants';
import {
  Bar,
  Binomial,
  Bubble,
  Chord,
  Donut,
  Line,
  Map,
  Radar,
  Table,
} from "@/icons";

export const panelTypeValues = {
  bar: 'bar',
  binomial: 'binomial',
  bubble: 'bubble',
  chord: 'chord',
  donut: 'donut',
  line: 'line',
  map: 'map',
  radar: 'radar',
  table: 'table',
};

export const panelTypeOptions = [
  {
    value: panelTypeValues.table,
    label: "Basic Table",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    icon: <Table />,
  },
  {
    value: panelTypeValues.donut,
    label: "Donut | Pie Chart",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    icon: <Donut />,
  },
  {
    value: panelTypeValues.binomial,
    label: "Binomial Distribution",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    icon: <Binomial />,
  },
  {
    value: panelTypeValues.bar,
    label: "Bar Chart",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    icon: <Bar />,
  },
  {
    value: panelTypeValues.radar,
    label: "Radar Chart",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    icon: <Radar />,
  },
  {
    value: panelTypeValues.chord,
    label: "Chord Diagram",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    icon: <Chord />,
  },
  {
    value: panelTypeValues.map,
    label: "Map View",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    icon: <Map />,
  },
  {
    value: panelTypeValues.line,
    label: "Line Graph",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    icon: <Line />,
  },
  {
    value: panelTypeValues.bubble,
    label: "Bubble Chart",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    icon: <Bubble />,
  },
];

export const iconByPanelType = panelTypeOptions.reduce(
  (obj, option) => ({ ...obj, [option.value]: option.icon }),
  {}
);

export const demographicsParamNames = [
  paramNames.audiences,
  paramNames.signalDemographicsAge,
  paramNames.signalDemographicsGender,
];
