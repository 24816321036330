import { Tooltip, Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useSelector } from 'react-redux';
import DashboardUserChipAvatarGroup from '../DashboardChipAvatar/DashboardUserChipAvatarGroup';
import style from './Share.module.scss';
import { getDashboardSharedWithText } from '../helpers/dashboardHelpers';

const SharedWith = ({ data, userIdentity }) => {
  const existingIdentity = useSelector((state) => state.identities);
  const otherIdentities = data?.identities?.filter((el) => el?.identityId !== userIdentity?.identityId);

  const sharedText = useMemo(() => {
    if (otherIdentities) {
      return getDashboardSharedWithText(otherIdentities, existingIdentity);
    }
    return 'Shared with You';
  }, [otherIdentities, existingIdentity]);

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%" gap={1}>
      <Box display="flex" alignItems="center" justifyContent="center" width="100%" gap={1}>
        <PeopleAltIcon color="disabled" />
        <Tooltip
          title={(
            <DashboardUserChipAvatarGroup
              data={otherIdentities}
              fontSizeLabel="1.5em"
            />
          )}
          arrow
          placement="top-end"
          classes={{
            tooltip: style.customTooltip,
            arrow: style.customTooltipArrow,
          }}
        >
          <Typography variant="caption" width="100%" color="gray" sx={{ cursor: 'pointer' }}>
            {sharedText}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default SharedWith;
