import React from 'react';

import styles from './DashboardPanel.module.scss';

const PanelBodyError = ({ error, message }) => (
  <div className={styles.panelErrorContainer}>
    {/* <AlertTriangle className={styles.panelErrorIcon} size={40} /> */}
    {error || message ? (
      <pre className={styles.panelErrorMessage}>{error ?? message}</pre>
    ) : (
      <div className={styles.panelErrorText}>
        This query returned no results.
      </div>
    )}
  </div>
);

export default PanelBodyError;
