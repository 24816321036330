import React, { useMemo } from 'react';
import { Divider } from '@mui/material';
import { filterTypes, paramNames } from '@/constants';
import FilterTagsContainer from '../FilterTags/FilterTagsContainer';
import PanelParamCategory from './PanelParamCategory';
import PanelParamLocation from './PanelParamLocation';
import PanelParamsModifiers from './PanelParamsModifiers';

import getFilterParamNames from '../helpers/getFilterParamNames';
import { useDashboardActionsContext } from '../DashboardActionsProvider';

import styles from './DashboardPanel.module.scss';

const PanelParam = ({
  filterType, panelParams, handleParamsChange, handleParamChange, handleParamDelete, enforceInlineBehavior,
}) => {
  switch (filterType) {
    case filterTypes.heatmap:
      return (
        <PanelParamLocation
          location={panelParams?.[paramNames.filterLocation]}
          onChange={(nextLocation) => handleParamChange({ key: paramNames.filterLocation, value: nextLocation })}
          onReset={() => handleParamDelete(paramNames.filterLocation)}
        />
      );
    default:
      return (
        <PanelParamCategory
          panelParams={panelParams}
          onParamsChange={handleParamsChange}
          enforceInlineBehavior={enforceInlineBehavior}
        />
      );
  }
};

const PanelParams = ({
  baseParams, panelId, panelParams,
}) => {
  const dashboardActions = useDashboardActionsContext();

  const filterType = panelParams?.[paramNames.filterType];
  const filterParamNames = useMemo(() => getFilterParamNames(filterType), [filterType]);
  const isHeatmap = filterType === filterTypes.heatmap;

  const handleParamChange = ({ key, value }) => {
    dashboardActions.changePanelParams({ panelId, nextParams: { [key]: value } });
  };

  const handleParamsChange = (nextParams) => {
    dashboardActions.changePanelParams({ panelId, nextParams });
  };

  const handleParamDelete = (key) => {
    dashboardActions.deletePanelParam({ panelId, key });
  };

  const handleReset = () => {
    dashboardActions.deletePanelParams({ panelId, paramNames: filterParamNames });
  };

  return (
    <div className={styles.panelParams}>
      <div className={styles.panelParamsFields}>
        <PanelParamsModifiers
          baseParams={baseParams}
          panelId={panelId}
          panelParams={panelParams}
        />
        <Divider />
        {filterType !== filterTypes.explore ? (
          <div className={styles.panelParamAndFiltersWrapper}>
            <div className={styles.panelParamCategoryFilters}>
              <PanelParam
                enforceInlineBehavior
                filterType={filterType}
                panelParams={panelParams}
                handleParamsChange={handleParamsChange}
                handleParamChange={handleParamChange}
                handleParamDelete={handleParamDelete}
              />
            </div>

            <div className={styles.panelParamsFilters}>
              {filterType && !isHeatmap ? (
                <FilterTagsContainer
                  params={panelParams}
                  onParamsChange={handleParamChange}
                  onParamDelete={handleParamDelete}
                  onReset={handleReset}
                />
              ) : null}
              {/* <div className={styles.panelParamsMakeCrashButton}>
            <MakeCrashButton message="🐞 Panel Content Error: Make Crash executed" title="Make Crash" />
          </div> */}

            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PanelParams;
