const guidRe = /^(\{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\}{0,1})$/;
export const isGuid = (id) => guidRe.test(id);

export const randomString = (length = 7) => Math.random().toString(36).substring(length);

export const coerceArray = (arr) => (Array.isArray(arr) ? arr : [arr]);

// With omnisearch results, we get tags and entities mixed.
// The convention is all over the place right now, with tags following
// { entity_id: urn } or { tag_id: urn }
export const isTag = (obj) => !!obj?.tag_id || obj?.entity_id?.startsWith('urn:');

export const getResults = (results) => (
  Array.isArray(results) ? results
    : results?.entities ? results.entities
      : results?.heatmap ? results.heatmap
        : results?.trends ? results.trends
          : []);

const firstResultIsEntity = (results) => !!(Array.isArray(results) && results?.[0]?.entity_id);
const firstResultIsHeatmap = (results) => !!(Array.isArray(results) && results?.[0]?.lat);

// this is usually correct but should be deprecated since there could be
// multiple types in one request someday.
export const getResultType = (results) => (
  firstResultIsEntity(results) || results?.entities ? 'entity'
    : firstResultIsHeatmap(results) || results?.heatmap ? 'heatmap'
      : results?.trends ? 'trends'
        : results?.tags ? 'tags'
          : 'unknown type');

export const getAllEntitiesInResults = (requestBody) => [].concat(
  // results: [] can be other things like heatmaps at the moment
  firstResultIsEntity(requestBody?.results) && requestBody?.results,
  // and sometimes its in results.entities: []
  requestBody?.results?.entities,
).filter((n) => !!n);

// TODO: rename getAllEntitiesInResponse, and similar for other function names below
export const getAllEntitiesInRequest = (requestBody) => (
  [].concat(
    // sometimes the primary entity is in query.entity: {}
    [requestBody?.query?.entity],
    // sometimes its in query.entities: [{}]
    requestBody?.query?.entities,
    getAllEntitiesInResults(requestBody),
  ).filter((n) => !!n)
);

export const getAllTrendsInRequests = (requestBody) => requestBody?.results?.trends;
export const getAllHeatmapsInRequests = (requestBody) => (
  (firstResultIsHeatmap(requestBody?.results) && requestBody?.results) || requestBody?.results?.heatmap
);

export const getResultStats = (request) => {
  const requestBody = request?.body;
  const results = requestBody?.results;
  const heatmaps = getAllHeatmapsInRequests(requestBody);
  const trends = getAllTrendsInRequests(requestBody);

  // We want to know if there are any empty arrays, since that works like a type hint in our results.
  // we could look at the route, but the json output is subject to change, so it's better to inspect the data.
  const hasEntities = (
    !!requestBody?.query?.entity
    || Array.isArray(requestBody?.query?.entities)
    || Array.isArray(results)
    || Array.isArray(results?.entities));
  const hasHeatmap = Array.isArray(heatmaps);
  const hasTrends = Array.isArray(trends);

  return {
    entities: hasEntities ? getAllEntitiesInRequest(requestBody).length : false,
    heatmaps: hasHeatmap ? heatmaps?.length || 0 : false,
    trends: hasTrends ? trends?.length || 0 : false,
  };
};

export const countTagsInEntities = (entities) => {
  const tags = {};
  entities?.forEach((e) => e?.tags?.forEach(({ tag_id }) => { tags[tag_id] = true; }));
  return Object.keys(tags).length;
};
