import clsx from 'clsx';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { DashboardPermissionedControl, OwnershipOverlay } from '@/components';
import { useOwnership } from '@/hooks';
import { USER_ROLES } from '@/constants/roles';
import { useDashboardActionsContext } from '../DashboardActionsProvider';
import { useDashboardVeltContext } from '../DashboardVeltProvider';

// Components
import QueryBuilderPopover from '../QueryBuilderPopover';
import MoreOptionsDashboard from '../../HomePage/MoreOptionsDashboard';
import QueryBuilder from '../QueryBuilder/QueryBuilder';

// Hooks

import styles from './DashboardHeader.module.scss';

const DashboardHeader = ({ dashboard }) => {
  const { getQueryBuilderVeltLocation, setVeltLocation } = useDashboardVeltContext();
  const dashboardActions = useDashboardActionsContext();
  const { baseParams, id } = dashboard;

  // Ownership
  const tag = `${id}-header`;
  const headerOwnership = useOwnership(tag);

  const navigate = useNavigate();
  const { dashboardIds } = useSelector((state) => state.dashboardTabs);
  // Clear ownership on unmount
  useEffect(() => () => {
    headerOwnership.clear();
  }, []);

  // Popover
  const [selectedPopover, setSelectedPopover] = useState(null);
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'demoPopper',
  });

  const handleOpen = useCallback((popover) => (e) => {
    setSelectedPopover(popover);
    popupState.open(e);
  }, [popupState]);

  const handleClose = () => {
    setSelectedPopover(null);
    popupState.close();
  };

  const handleRedirectDashboard = () => {
    const otherDashboard = Object.values(dashboardIds).find((d) => d.id !== id && !d.deletedAt);
    if (otherDashboard && otherDashboard !== id) {
      navigate(`/dashboards/view/${otherDashboard}`);
    } else {
      navigate('/dashboards/view/');
    }
  };

  const handleChange = ({ key, value }) => {
    dashboardActions.changeBaseParam({ key, value });
  };

  const handleReset = (paramNames) => {
    const nextBaseParams = { ...baseParams };
    paramNames.forEach((paramName) => {
      nextBaseParams[paramName] = null;
    });

    dashboardActions.updateBaseParams(nextBaseParams);
  };

  const veltLocation = useMemo(
    () => getQueryBuilderVeltLocation({ dashboardId: dashboard.id }),
    [dashboard.id],
  );

  useEffect(
    () => {
      setVeltLocation(veltLocation);
    },
    [veltLocation],
  );

  return (
    <OwnershipOverlay tag={tag}>
      <div className={clsx(['comment-able', styles.container])}>
        <div className={styles.queryBuilder}>
          <DashboardPermissionedControl
            dashboardId={dashboard.id}
            requiredPermissions={[USER_ROLES.EDITOR]}
          >
            <QueryBuilder params={baseParams} onOpen={handleOpen} veltLocation={veltLocation}>
              {({ DemographicsComponent, InterestsComponent, LocationComponent }) => (
                <div className={styles.baseQuery}>
                  <DemographicsComponent color="orange" className={styles.picker} />
                &nbsp;
                  <div>from</div>
                &nbsp;
                  <LocationComponent color="purple" className={styles.picker} />
                &nbsp;
                  <div>that like{baseParams?.gender ? '' : 's'}</div>
                &nbsp;
                  <InterestsComponent color="blue" className={styles.picker} />
                </div>
              )}
            </QueryBuilder>
          </DashboardPermissionedControl>
          <QueryBuilderPopover
            lock={headerOwnership.set}
            popupState={popupState}
            onChange={handleChange}
            onClose={handleClose}
            onReset={handleReset}
            selectedPopover={selectedPopover}
            params={baseParams}
            releaseLock={headerOwnership.clear}
          />
        </div>
        <MoreOptionsDashboard dashboard={dashboard} showFavorite onConfirmDelete={handleRedirectDashboard} />
      </div>
    </OwnershipOverlay>
  );
};

export default DashboardHeader;
