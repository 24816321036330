// eslint-disable-next-line prefer-const
let reqs = {};

// This is a failsafe to make sure the cache object never grows out of control.
const MAXIMUM_CACHED_OBJECTS = 500;

export const clearCache = (key) => {
  if (key) {
    return delete reqs[key];
  }

  reqs = {};

  return true;
};

const checkCache = () => {
  setTimeout(() => {
    Object.entries(reqs).forEach(([key, val]) => {
      const now = Date.now();
      if (val.expires < now) {
        console.debug('fetcherWithKey', key, `request expired ${val.expires} < ${now}, removing`, reqs[key].url);
        delete reqs[key];
      }
      // console.debug('Request objects', Object.keys(reqs).length);
    });
    checkCache();
  }, 3000)?.unref?.();
};

checkCache();

// function nextFrame() {
//   return new Promise((resolve) => {
//     requestAnimationFrame(resolve);
//   });
// }

const finallyFn = (fn) => {
  const [key, prom] = fn();
  const toReturn = (results) => {
    // The following doesn't work, but there could be some conditions where components sharing a fetch-by-key collide
    // Because one component aborts the request, and the other component needs the newer promise.
    //
    // if (results?.errorName === 'AbortError' && reqs?.[key]?.prom !== prom) {
    //   console.log('Promise', key, 'was aborted, return newer Promise', reqs?.[key]?.prom);
    //   // return nextFrame().finally(() => )
    //   return Promise.resolve(reqs?.[key]?.prom);
    // }

    console.debug('fetcherWithKey promise', key, 'is done', results, prom);

    // Remove this promise from reqs object unless another one
    // has already taken its place.
    // This makes sure that simultaneous, duplicate requests will refer to same promise.
    if (reqs?.[key]?.prom === prom) {
      // If there's no expiry time or the request failed, we remove immediately on completion.
      // We probably don't want to cache bad requests, generally speaking,
      // as sometimes subsequent requests can work.
      const expires = reqs[key]?.expires;
      const status = results?.status;
      if (!expires || status !== 200) {
        console.debug(
          'fetcherWithKey',
          key,
          [
            !expires && `has no expiry (${expires})`,
            status !== 200 && `has bad status (${status})`,
          ].filter((n) => !!n).join('and'),
          'cleaning up',
          reqs[key].url,
        );
        delete reqs[key];
      }
    }

    return false;
  };
  return [toReturn, toReturn];
};

const fetcherWithKey = (fetcher = fetch) => (key, url, { expires = 0, ...config } = {}) => {
  // console.log('fetcherWithKey starting', key, url);

  const fingerprint = JSON.stringify({ url, config });

  if (reqs[key]) {
    if (reqs[key].fingerprint === fingerprint) {
      console.debug('fetcherWithKey', key, 'is same fetch, returning old promise', { url: reqs[key].url, reqs });
      return reqs[key].prom;
    }
    console.debug('fetcherWithKey', key, 'has new params, cancelling old one', {
      oldFingerPrint: reqs[key].fingerprint,
      newFingerprint: fingerprint,
    });
    reqs[key].controller.abort();
  }

  console.debug('fetcherWithKey', key, 'returning new promise');

  const controller = new AbortController();
  const { signal } = controller;
  const prom = fetcher(url, { ...config, signal });
  prom.then(...finallyFn(() => [key, prom]));

  reqs[key] = {
    fingerprint, prom, url, expires: Date.now() + (expires * 1000), controller,
  };

  if (Object.keys(reqs).length > MAXIMUM_CACHED_OBJECTS) {
    delete reqs[Object.keys(reqs)[0]];
  }

  return prom;
};

export default fetcherWithKey;
