import { createSlice } from '@reduxjs/toolkit';
import keyBy from '@/utils/keyBy';

export const audiencesSlice = createSlice({
  name: 'audiences',
  initialState: {
    byId: {},
  },
  reducers: {
    fetchAudienceStart: (state, action) => {
      const { byId } = state;
      const audienceId = action.payload;

      return {
        ...state,
        byId: {
          ...byId,
          [audienceId]: {
            ...(byId[audienceId] || {}),
          },
        },
      };
    },
    mergeAudiences: (state, action) => {
      const audiences = action.payload;

      return {
        ...state,
        byId: { ...state.byId, ...keyBy(audiences, 'id') },
      };
    },
  },
});

export const {
  fetchAudienceStart,
  mergeAudiences,
} = audiencesSlice.actions;
