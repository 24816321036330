import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useUpdateIdentityDashboardIsFavoriteMutation } from '@/store/apis/dashboardsApi';
import { useUserDashboardIdentity } from '@/hooks';

const MenuItemFavorite = ({ dashboard, hideLabel, ...props }) => {
  const userDashboardIdentity = useUserDashboardIdentity(dashboard.id);
  const [updateIdentityDashboardIsFavorite] = useUpdateIdentityDashboardIsFavoriteMutation();

  const isFavorite = !!userDashboardIdentity?.isFavorite;

  const handleClick = (event) => {
    event.stopPropagation();
    const { dashboardId } = dashboard;
    const { identityId } = userDashboardIdentity;
    updateIdentityDashboardIsFavorite({ dashboardId, identityId, isFavorite: !isFavorite });
  };

  return (
    <MenuItem onClick={handleClick} {...props}>
      <ListItemIcon>
        {isFavorite ? <StarIcon color="yellow" variant="inverse" /> : <StarBorderIcon />}
      </ListItemIcon>
      {!hideLabel && <ListItemText>Favorite</ListItemText>}
    </MenuItem>
  );
};

export default MenuItemFavorite;
