import { Box } from '@material-ui/core';
import PanelParamsSkeleton from './PanelParamsSkeleton';
import PanelBodySkeleton from './PanelBodySkeleton';

const PanelContentSkeleton = (props) => (
  <Box display='flex' flexDirection='column' height='100%'>
    <PanelParamsSkeleton {...props} />
    <PanelBodySkeleton {...props} />
  </Box>
);

export default PanelContentSkeleton;
