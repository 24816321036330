import clsx from 'clsx';
import React from 'react';
import TagsList from '@/components/TagsList';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';
import CategoryDataTagsNames from './CategoryDataTagsNames';
import formatTagType from './formatTagType';
import styles from './PanelBodyExplore.module.scss';
import { barChartTagTypesBitmap } from './constants';

const defaultTagType = 'Default';

const CategoryDataTags = ({
  category, tagsResult, tagTypes, tagsFetcher,
}) => {
  if (tagTypes.length) {
    const tagTypesWithDefault = [defaultTagType, ...tagTypes];
    return (
      <div className={styles.categoryDataTags}>
        {tagTypesWithDefault.map((tagType) => {
          const label = tagType === defaultTagType ? 'Top' : formatTagType(tagType);
          const result = tagType === defaultTagType
            ? tagsResult?.results?.tags || []
            : tagsFetcher.state[tagType]?.result || [];

          if (!result.length) {
            return null;
          }

          return (
            <div
              className={clsx({
                [styles.categoryDataTagsItem]: true,
                [styles.categoryDataTagsItemBarChart]: barChartTagTypesBitmap[tagType],
              })}
              style={{
                '--dynamic-category-color':
                  `var(--category-${formatCategoryUrnToCssVar(category)})`,
              }}
            >
              <span className={styles.categoryDataTagsItemLabel}>{label}</span>
              <CategoryDataTagsNames result={result} tagType={tagType} />
            </div>
          );
        })}
      </div>
    );
  }
  if (tagsResult?.results?.tags) {
    return <TagsList tags={tagsResult?.results?.tags} useCategoryColor category={category} />;
  }

  return null;
};

export default CategoryDataTags;
