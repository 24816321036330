import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { LinearProgress } from '@material-ui/core';

import { filterTypes, paramNames } from '@/constants';
import { Overlay } from '@/components';
import PanelBodyTable from './PanelBodyTable';
import PanelBodyHeatmap from './PanelBodyHeatmap';
import PanelBodyExplore from './PanelBodyExplore';
import PanelBodyError from './PanelBodyError';

import { getHumanizedQueryError } from '../FilterTags/helpers';
import styles from './DashboardPanel.module.scss';

const ErrorOverlay = ({ error }) => (
  <Overlay>
    <PanelBodyError error={error} />
  </Overlay>
);

const FetchingOverlay = () => (
  <Overlay>
    <LinearProgress />
  </Overlay>
);

const PanelBodyWrapper = ({
  serializedInsightsParams,
  panelError,
  panel,
  ...props
}) => {
  const { params } = panel;
  const filterType = params[paramNames.filterType];
  const error = getHumanizedQueryError(panelError);
  const hasError = Boolean(error);
  const hasData = Boolean(props.panelData.length);

  const panelBodyId = useMemo(
    () => `dashboardPanelBody-${props.panelId}`,
    [props.panelId],
  );

  useEffect(() => {
    if (!props.isFetching) {
      const panelBody = document.getElementById(panelBodyId);
      panelBody.scrollTop = 0;
    }
  }, [props.isFetching, panelBodyId, serializedInsightsParams]);

  return (
    <div
      className={clsx({
        [styles.panelBodyContainer]: true,
        [styles.explorePanel]: filterType === filterTypes.explore,
      })}
    >
      <div
        id={panelBodyId}
        className={clsx({
          [styles.dashboardPanelBody]: true,
          [styles.explorePanel]: filterType === filterTypes.explore,
        })}
      >
        <PanelBody filterType={filterType} panel={panel} {...props} />
        {(hasError || (!hasData && filterType !== filterTypes.explore)) && (
          <ErrorOverlay error={error} />
        )}
        {props.isFetching && <FetchingOverlay />}
      </div>
    </div>
  );
};

const PanelBody = ({
  panelId,
  panel,
  panelData,
  filterType,
  baseParams,
  updateHasMoreExplorePages,
  explorePagination,
  isFetching,
}) => {
  const { settings } = panel;
  const heatmapControls = settings?.heatmapControls;
  const tableControls = settings?.tableControls;

  if (filterType === filterTypes.heatmap) {
    return (
      <PanelBodyHeatmap
        data={panelData}
        panelId={panelId}
        heatmapControls={heatmapControls}
        isFetching={isFetching}
      />
    );
  }

  if (filterType === filterTypes.explore) {
    const exploreParams = baseParams;
    delete exploreParams[paramNames.filterType];

    return (
      <PanelBodyExplore
        data={panelData}
        panel={panel}
        panelId={panelId}
        baseParams={exploreParams}
        pagination={explorePagination}
        updateHasMoreExplorePages={updateHasMoreExplorePages}
      />
    );
  }

  return (
    <PanelBodyTable
      data={panelData}
      panelId={panelId}
      filterType={filterType}
      tableControls={tableControls}
      isFetching={isFetching}
    />
  );
};

export default PanelBodyWrapper;
