/* eslint-disable max-len */
import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { Button } from '@/components';
import extractErrorMessage from '@/utils/extractErrors';

import getHttpPhrase from '@/utils/getHttpPhrase';
import { useRestoreDashboardMutation } from '@/store/apis/dashboardsApi';
import styles from './DashboardErrorFallback.module.scss';

const errorTitles = {
  403: 'No access',
  404: 'Dashboard not found',
  500: 'Internal Server Error',
  460: 'Dashboard deleted',
  423: 'Request access',
};

/// Please see https://app.asana.com/0/0/1207677692484210/1207678518821561/f
/// for details on the meaning of http status codes at qloo.
const getErrorMessages = (error) => {
  if (error.status === 460) {
    return `Dashboard with id ${error.dashboardId} has been deleted by ${error.data.fullName} on ${dayjs(error.data.deletedOn)}. \nIf you want to restore it, please click on the 'Restore' button.`;
  }

  if (error.status === 404) {
    return `Dashboard with id ${error.dashboardId} not found. \nIf you think this is an error, please contact support.`;
  }

  if (error.status === 403) {
    return `You don't access to the dashboard with id ${error.dashboardId}. \nPlease request access from one of the owners of this dashboard.`;
  }

  if (error.status === 423) {
    return "You don't have access to use dashboards.\nPlease have your company representative contact our support to enable the dashboard feature for you.";
  }

  if (error instanceof Error) {
    return extractErrorMessage(error);
  }

  if (typeof error === 'string') {
    return error;
  }

  return 'An error occurred. Please contact support';
};

const DashboardErrorFallback = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();
  const [restoreDashboard, { isLoading }] = useRestoreDashboardMutation();

  const defaultTitle = 'Error';
  const httpTitle = error.status ? `${error.status} - ${getHttpPhrase(error.status)}` : null;
  const title = errorTitles[error.status] || httpTitle || defaultTitle;

  const handleRestore = async () => {
    await restoreDashboard({ dashboardId: error.data.dashboardId });
    resetErrorBoundary();
  };

  return (
    <Box className={styles.container}>
      <Typography variant="h5" fontWeight="bold">{title}</Typography>
      <Box className={styles.message}>
        <Box component="pre">{getErrorMessages(error)}</Box>
      </Box>
      <Box className={styles.actions}>
        <Button onClick={() => navigate('/dashboards')} variant="outlined">
          Go to Home Page
        </Button>
        {!!error.status && error.status === 460 && (
          <Button onClick={handleRestore} isLoading={isLoading}>
            Restore
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default DashboardErrorFallback;
