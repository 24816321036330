import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

const ErrorBoundary = ({ children, onError, ...props }) => {
  const onErrorHandler = (error, info) => {
    if (onError) {
      onError(error);
    }

    const { componentStack } = info;

    Sentry.captureException(error, {
      captureContext: {
        contexts: { react: { componentStack } },
      },
    });
  };

  return (
    <ReactErrorBoundary onError={onErrorHandler} {...props}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
