import React, { useState } from 'react';
import {
  IconButton, ListItemIcon, ListItemText, Menu, MenuItem,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { api, filterTypes } from '@/constants';
import { isTableFilterType } from '@/utils';
import { useDashboardActionsContext } from '../../DashboardActionsProvider';
import MenuItemExportData from './MenuItemExportData';

const PanelContextMenu = ({ panelId, serializedInsightsParams, filterType }) => {
  const dashboardActions = useDashboardActionsContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePanelDelete = () => {
    dashboardActions.deletePanel(panelId);
  };

  const handlePanelClone = () => {
    dashboardActions.clonePanel(panelId);
  };

  const handleOpenInRequestBrowser = () => {
    const requestUrl = `${api.server}/v2/insights?${serializedInsightsParams}`;
    window.open(
      `/#/requests?requests=${encodeURIComponent(requestUrl)}`,
      '_blank',
    );
  };

  const isTable = isTableFilterType(filterType);
  const isHeatmap = filterType === filterTypes.heatmap;

  return (
    <>
      <IconButton
        aria-label="panel-menu"
        onClick={handleOpen}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={handlePanelClone}>
          <ListItemIcon><FileCopyOutlinedIcon /></ListItemIcon>
          <ListItemText>Clone Panel</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenInRequestBrowser}>
          <ListItemIcon><OpenInNewIcon /></ListItemIcon>
          <ListItemText>Open in request browser</ListItemText>
        </MenuItem>
        <MenuItem onClick={handlePanelDelete}>
          <ListItemIcon><DeleteOutlineIcon /></ListItemIcon>
          <ListItemText>Delete Panel</ListItemText>
        </MenuItem>
        {(isTable || isHeatmap) && (
          <MenuItemExportData
            panelId={panelId}
            serializedInsightsParams={serializedInsightsParams}
            onDownloaded={handleClose}
          />
        )}
      </Menu>
    </>
  );
};

export default PanelContextMenu;
