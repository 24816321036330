import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { audiencesApi } from './apis/audiencesApi';
import { dashboardsApi } from './apis/dashboardsApi';
import { gIApi } from './apis/gIApi';
import { insightsApi } from './apis/insightsApi';
import { recommendationsApi } from './apis/recommendationsApi';
import { identityApi } from './apis/identityApi';
import { tagApi } from './apis/tagApi';
import { tagsApi } from './apis/tagsApi';
import { openstreetmapApi } from './apis/openstreetmapApi';
import { dashboardsSlice } from './slices/dashboardsSlice';
import { dashboardTabsSlice } from './slices/dashboardTabsSlice';
import { appSlice } from './slices/appSlice';
import { audiencesSlice } from './slices/audiencesSlice';
import { entitiesSlice } from './slices/entitiesSlice';
import { entityTrendsSlice } from './slices/entityTrendsSlice';
import { identitiesSlice } from './slices/identitiesSlice';
import { tagSlice } from './slices/tagSlice';
import { tagsSlice } from './slices/tagsSlice';
import { mapboxApi } from './apis/mapboxApi';
import { corporateApi } from './apis/corporateApi';
import fetchingSlice from './slices/fetchingSlice';
import errorSlice from './slices/errorSlice';

const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    audiences: audiencesSlice.reducer,
    dashboards: dashboardsSlice.reducer,
    dashboardTabs: dashboardTabsSlice.reducer,
    entities: entitiesSlice.reducer,
    entityTrends: entityTrendsSlice.reducer,
    identities: identitiesSlice.reducer,
    tags: tagsSlice.reducer,
    tag: tagSlice.reducer,
    fetching: fetchingSlice.reducer,
    errors: errorSlice.reducer,
    [gIApi.reducerPath]: gIApi.reducer,
    [audiencesApi.reducerPath]: audiencesApi.reducer,
    [dashboardsApi.reducerPath]: dashboardsApi.reducer,
    [corporateApi.reducerPath]: corporateApi.reducer,
    [identityApi.reducerPath]: identityApi.reducer,
    [insightsApi.reducerPath]: insightsApi.reducer,
    [recommendationsApi.reducerPath]: recommendationsApi.reducer,
    [tagApi.reducerPath]: tagApi.reducer,
    [tagsApi.reducerPath]: tagsApi.reducer,
    [openstreetmapApi.reducerPath]: openstreetmapApi.reducer,
    [mapboxApi.reducerPath]: mapboxApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    audiencesApi.middleware,
    dashboardsApi.middleware,
    corporateApi.middleware,
    gIApi.middleware,
    identityApi.middleware,
    insightsApi.middleware,
    recommendationsApi.middleware,
    tagApi.middleware,
    tagsApi.middleware,
    openstreetmapApi.middleware,
    mapboxApi.middleware,
  ),
});

setupListeners(store.dispatch);

export default store;
