const Bubble = () => (
  <svg
    version="1.1"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m28.398 44.199c-6.6992 0-12.102 5.3984-12.102 12.102 0 6.6992 5.3984 12.102 12.102 12.102 6.6992 0 12.102-5.3984 12.102-12.102 0-6.6992-5.3984-12.102-12.102-12.102zm0 21c-4.8008 0-8.8984-4.1016-8.8984-8.8984 0-4.8984 4-8.8984 8.8984-8.8984 4.8984 0 8.8984 4 8.8984 8.8984 0.003906 4.8008-4.0977 8.8984-8.8984 8.8984z" />
      <path d="m67.301 16.898c-9.1992 0-16.398 7.1992-16.398 16.398s7.1992 16.398 16.398 16.398 16.398-7.1992 16.398-16.398c0-8.9961-7.3984-16.398-16.398-16.398zm0 29.703c-7.3008 0-13.301-6-13.301-13.301s6-13.301 13.301-13.301 13.301 6 13.301 13.301-6 13.301-13.301 13.301z" />
      <path d="m61.102 69.5c-3.6992 0-6.6992 3-6.6992 6.6992s3 6.6992 6.6992 6.6992c3.6992 0 6.6992-3 6.6992-6.6992s-3-6.6992-6.6992-6.6992zm0 10.699c-2.1992 0-4-1.8984-4-4 0-2.1016 1.8984-4 4-4 2.1016 0 4 1.8984 4 4 0 2.1016-1.8008 4-4 4z" />
    </g>
  </svg>
);

export default Bubble;
