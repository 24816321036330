const colors = [
  '#8C78B4', // music, songs, albums
  '#F08C78', // film, movies
  '#F0D264', // videogames, games
  '#C8648C', // podcasts
  '#148CC8', // tv, shows
  '#DCB478', // brands
  '#F06464', // books
  '#EFB4B5', // people
  '#DCB478', // fashion, brands
  '#EFB4B5', // authors
  '#F08C78', // actors, directors
  '#8C78B4', // artists
  '#64B4C8', // places
  '#00A08C', // dining, restaurants
  '#C6658C', // nightlife
  '#64B4C8', // hotels, destinations
  '#B5DB7D', // products
  '#7BD2C8', // sports
  '#67B4C7', // travel
  '#EFC75A', // media
];

export default colors;
