import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import isEqual from 'lodash.isequal';
import usePrevious from './usePrevious';

const useStateWithDebounce = ({ baseValue, onChange, debounceDelay = 500 }) => {
  const [value, setValue] = useState(baseValue);
  const debouncedCallback = useDebouncedCallback(onChange, debounceDelay);
  const previousBaseValue = usePrevious(baseValue);

  useEffect(() => {
    if (!isEqual(previousBaseValue, baseValue) && !isEqual(baseValue, value)) {
      setValue(baseValue);
    }
  }, [value, previousBaseValue, baseValue]);

  const handleChange = useCallback((nextValue) => {
    setValue(nextValue);
    debouncedCallback(nextValue);
  }, [debouncedCallback]);

  return [value, handleChange];
};

export default useStateWithDebounce;
