import React from 'react';
import TooltipWarning from '@/components/Tooltip/TooltipWarning';

const FeatureNoAccessTooltip = ({ children, disabled }) => {
  return (
    <TooltipWarning
      conditionalRender={disabled}
      placement="top"
      message="You don't have permission to access this feature."
    >
      {children}
    </TooltipWarning>
  );
};

export default FeatureNoAccessTooltip;
