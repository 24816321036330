const Line = () => (
  <svg version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="m90.16 73.438c-3.9844 0-7.5703-2.3984-9.0938-6.0742-1.5234-3.6797-0.68359-7.9102 2.1328-10.727 2.8164-2.8164 7.0469-3.6562 10.727-2.1328 3.6758 1.5234 6.0742 5.1133 6.0742 9.0938 0 2.6094-1.0352 5.1133-2.8828 6.957-1.8438 1.8477-4.3477 2.8828-6.957 2.8828zm0-16.332c-2.6367-0.042969-5.0352 1.5117-6.0742 3.9336-1.0391 2.418-0.50781 5.2266 1.3398 7.1055 1.8438 1.8789 4.6445 2.4531 7.082 1.457 2.4375-0.99609 4.0312-3.3711 4.0312-6.0039-0.011718-3.5391-2.8438-6.418-6.3789-6.4922z" />
      <path d="m63.395 46.273c-3.9922 0.011718-7.6016-2.3867-9.1367-6.0742-1.5352-3.6875-0.69922-7.9375 2.1211-10.766 2.8203-2.8281 7.0703-3.6758 10.758-2.1484 3.6914 1.5234 6.1016 5.125 6.1016 9.1172 0 5.4414-4.4023 9.8555-9.8438 9.8711zm0-16.332c-2.625 0-4.9961 1.582-5.9961 4.0117-1.0039 2.4297-0.44531 5.2227 1.418 7.0742 1.8594 1.8555 4.6562 2.4023 7.082 1.3906 2.4219-1.0117 3.9961-3.3867 3.9844-6.0156 0-1.7188-0.68359-3.3633-1.9023-4.5781-1.2148-1.2109-2.8672-1.8906-4.5859-1.8828z" />
      <path d="m36.633 70.57c-3.9805 0-7.5703-2.3984-9.0938-6.0781-1.5234-3.6758-0.67969-7.9102 2.1328-10.727 2.8164-2.8125 7.0469-3.6562 10.727-2.1328 3.6758 1.5234 6.0742 5.1133 6.0742 9.0938 0 5.4375-4.4062 9.8438-9.8398 9.8438zm0-16.332c-2.625 0-4.9922 1.5781-5.9961 4.0039-1.0039 2.4258-0.44922 5.2188 1.4062 7.0742 1.8555 1.8555 4.6484 2.4102 7.0742 1.4062 2.4219-1.0039 4.0039-3.3711 4.0039-5.9961 0-3.582-2.9062-6.4883-6.4883-6.4883z" />
      <path d="m9.8672 58.234c-3.9922 0.007813-7.5977-2.3867-9.1367-6.0742-1.5352-3.6875-0.69531-7.9375 2.125-10.766 2.8203-2.8281 7.0664-3.6758 10.758-2.1484 3.6914 1.5234 6.0977 5.125 6.0977 9.1172 0 5.4414-4.4023 9.8555-9.8438 9.8711zm0-16.359c-2.625-0.011719-5 1.5625-6.0117 3.9883-1.0156 2.4219-0.46484 5.2188 1.3867 7.082 1.8555 1.8594 4.6484 2.4219 7.0781 1.418 2.4258-1.0039 4.0117-3.3711 4.0117-6 0-3.5742-2.8906-6.4727-6.4648-6.4883z" />
      <path d="m82.836 57.855c-0.44141 0.003906-0.86328-0.16797-1.1797-0.48047l-12.629-12.469c-0.66016-0.66016-0.66016-1.7305 0-2.3867 0.3125-0.31641 0.73438-0.49219 1.1797-0.49219 0.44141 0 0.86719 0.17578 1.1797 0.49219l12.629 12.469c0.5 0.47266 0.65625 1.2031 0.39453 1.8398-0.26172 0.63672-0.88672 1.043-1.5742 1.0273z" />
      <path d="m43.254 56.438c-0.46875 0.011719-0.92188-0.18359-1.2305-0.53906-0.66016-0.66016-0.66016-1.7266 0-2.3867l12.844-11.664c0.66016-0.65625 1.7266-0.65625 2.3867 0 0.32422 0.3125 0.50391 0.74219 0.50391 1.1953 0 0.44922-0.17969 0.87891-0.50391 1.1914l-12.844 11.828c-0.32422 0.26562-0.73828 0.39844-1.1562 0.375z" />
      <path d="m28.586 58.93c-0.25781 0-0.51172-0.054688-0.75-0.16016l-9.6016-4.6953c-0.39844-0.1875-0.70312-0.53125-0.85156-0.94922-0.14453-0.41797-0.11719-0.875 0.074219-1.2734 0.19141-0.40625 0.53906-0.71875 0.96094-0.87109 0.42188-0.15234 0.88672-0.12891 1.293 0.066406l9.6016 4.6914c0.68359 0.35156 1.043 1.125 0.875 1.875-0.17188 0.75391-0.83203 1.293-1.6016 1.3164z" />
    </g>
  </svg>
);

export default Line;
