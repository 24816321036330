export const tagTypesBySelectedCategory = {
  'urn:entity:artist': [
    'urn:tag:artist:qloo',
    'urn:tag:audience:qloo',
    'urn:tag:complexity:qloo',
    'urn:tag:instrument:qloo',
    'urn:tag:music:qloo',
    'urn:tag:obscurity:qloo',
    'urn:tag:style:qloo',
    'urn:tag:theme:qloo',
  ],
  'urn:entity:brand': [
    'urn:tag:aesthetic_property:qloo',
    'urn:tag:emotional_tone:qloo',
    'urn:tag:lifestyle:qloo',
    'urn:tag:personal_style:qloo',
  ],
  'urn:entity:movie': [
    'urn:tag:archetype:qloo',
    'urn:tag:audience:qloo',
    'urn:tag:character:qloo',
    'urn:tag:keyword:qloo',
    'urn:tag:plot:qloo',
    'urn:tag:style:qloo',
    'urn:tag:subgenre:qloo',
  ],
  'urn:entity:place': [
    'urn:tag:ambience:qloo',
    'urn:tag:amenity:qloo',
    'urn:tag:cuisine:qloo',
    'urn:tag:decor:qloo',
    'urn:tag:good_for:qloo',
    'urn:tag:setting:qloo',
  ],
  'urn:entity:podcast': [
    'urn:tag:archetype:qloo',
    'urn:tag:content:qloo',
    'urn:tag:style:qloo',
    'urn:tag:subgenre:qloo',
    'urn:tag:theme:qloo',
  ],
  'urn:entity:tv_show': [
    'urn:tag:plot:qloo',
    'urn:tag:style:qloo',
    'urn:tag:archetype:qloo',
    'urn:tag:subgenre:qloo',
    'urn:tag:character:qloo',
    'urn:tag:keyword:qloo',
    'urn:tag:theme:qloo',
  ],
};

export const barChartTagTypesBitmap = {
  'urn:tag:complexity:qloo': 1,
  'urn:tag:obscurity:qloo': 1,
};
