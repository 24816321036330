import { USER_ROLES } from '@/constants/roles';
import useUserDashboardIdentity from './useUserDashboardIdentity';

const useUserDashboardPermissions = (dashboardId) => {
  const userDashboardIdentity = useUserDashboardIdentity(dashboardId);

  const permissions = userDashboardIdentity?.grantedPermissionTagIds || [];
  const permissionsBitMap = permissions.reduce((acc, permission) => {
    acc[permission] = true;
    return acc;
  }, {});

  const isUserOwner = !!permissionsBitMap[USER_ROLES.OWNER];

  return {
    isUserOwner,
    permissions,
    permissionsBitMap,
    hasSharePermission: permissionsBitMap[USER_ROLES.ADMIN] || permissionsBitMap[USER_ROLES.OWNER],
    hasRenamePermission: permissionsBitMap[USER_ROLES.EDITOR],
    hasClonePermission: permissionsBitMap[USER_ROLES.CLONE],
    hasDeletePermission: permissionsBitMap[USER_ROLES.DELETER],
    hasLayoutPermission: permissionsBitMap[USER_ROLES.EDITOR],
  };
};

export default useUserDashboardPermissions;
