import BaseError, { missingMessage } from './BaseError.mjs';

/**
 * Represents an error where a method or function call did not meet its expected
 * contract.
 */
// eslint-disable-next-line no-class/no-class
class MethodContractError extends BaseError {
  static ErrorType = 'MethodContractError';

  /**
   * Guard to verify the error is an instance of MethodContractError.
   * @param {Error | undefined} error - The error to check.
   * @returns {boolean} True if the error is an instance of MethodContractError.
   */
  static isMethodContractError(error) {
    return error instanceof MethodContractError;
  }

  /**
   * Creates a new MethodContractError instance.
   * @param {object} options - The error options.
   * @param {string} options.method - The method that was called.
   * @param {string} options.parameter - The paramter that was invalid.
   * @param {string} options.message - The technical error message.
   * @param {string} [options.errorCode] - An optional error code for
   * categorizing the error.
   * @param {string} [options.userMessage] - Optional user-friendly error
   * message.
   * @param {object} [options.details] - An optional object that can contain any
   * details about the error.
   */
  constructor({
    method,
    parameter = undefined,
    message,
    errorCode = undefined,
    userMessage = undefined,
    details = {},
  }) {
    super({
      message,
      errorCode,
      userMessage,
      details,
    });
    this.name = MethodContractError.ErrorType;
    this.method = method;
    this.parameter = parameter;
  }

  /**
   * Serializes the error into a JSON object.
   * @returns {object} An object representing the error.
   */
  serialize() {
    return {
      ...super.serialize(),
      method: this.method,
      parameter: this.parameter,
    };
  }

  /**
   * Deserializes a JSON object into an error instance.
   * @param {object} errorObject - The error object to convert.
   * @returns {MethodContractError} An instance of MethodContractError with properties populated from the errorObject.
   */
  static deserialize(errorObject) {
    const error = new this({
      message: errorObject.message
        || missingMessage(MethodContractError.ErrorType),
      method: errorObject.method,
      parameter: errorObject.parameter,
      errorCode: errorObject.errorCode,
      userMessage: errorObject.userMessage,
      details: errorObject.details,
    });
    error.stack = errorObject.stack;
    return error;
  }
}

export default MethodContractError;
