import categories from '@qloo/categories';
import { TABS } from './pages';
import ImageHighlightMissing from '../components/ImageHighlightMissing';
import Image from '../components/Image';
import usePermission, { usePermissionObject } from './usePermissionsBase';

const PAGE_KEYS = Object.keys(TABS);
const PAGE_PERM_KEYS = Object.keys(TABS).map((k) => `page.${k}`);
export const usePagePerms = () => {
  const pagePerms = usePermission(...PAGE_PERM_KEYS);

  // The exiting router logic does a Navigate to={firstUsablePage.to}
  // and we can't wrap that with the SessionAuth as the SessionAuth
  // react component. So, we need to verify the user is logged in here
  // and navigate to the /auth page if they are not.
  const auth = {
    to: '/auth',
  };

  /// No permssions means we are not logged in.
  /// WARNING: This is only the case if PAGE_PERM_KEYS is non-empty. If
  /// PAGE_PERM_KEYS is empty, then we are always going to be sent to the
  /// login page until PAGE_PERM_KEYS is not empty.
  if (pagePerms.length === 0) {
    return { validPages: [auth], hasMultiplePages: false, firstUsablePage: auth };
  }

  const hasMultiplePages = pagePerms.filter((f) => f === true).length > 1;
  const firstUsablePage = TABS[PAGE_KEYS[pagePerms.indexOf(true)]];
  const validPages = pagePerms.map((f, i) => (f ? TABS[PAGE_KEYS[i]] : false)).filter((f) => f !== false);
  return { validPages, hasMultiplePages, firstUsablePage };
};

// deprecated categories
const INVALID_CATEGORIES_KEYS = [
  'books/authors',
  'destination',
  'film/actors',
  'film/directors',
  'music/albums',
];

const PRIORITY_CATEGORIES = {
  'urn:entity:brand': 0,
  'urn:entity:place': 1,
};

export const findPriority = (category) => (PRIORITY_CATEGORIES[category] !== undefined
  ? PRIORITY_CATEGORIES[category] : Object.keys(PRIORITY_CATEGORIES).length);

const CATEGORY_URNS = Object.values(categories)
  .filter((filter) => !INVALID_CATEGORIES_KEYS.includes(filter.key))
  .map(({ urn }) => urn);
const CATEGORY_PERM_KEYS = CATEGORY_URNS.map((urn) => `api.category.${urn}`);

const CATEGORY_URNS_WITH_TAG = [...CATEGORY_URNS, 'urn:tag'];
const CATEGORY_PERM_KEYS_WITH_TAG = CATEGORY_URNS_WITH_TAG.map((urn) => `api.category.${urn}`);

const formatCategoryPerms = (categoryPerms) => Object.fromEntries(
  Object.entries(categoryPerms)
    .sort(([urn, enabled], [urnb, enabledb]) => ((enabled === enabledb) ? urn.localeCompare(urnb) : enabled ? -1 : 1))
    // remove 'api.category.' from 'api.category.urn:entity:actor'
    .map(([cat, enabled]) => [cat.substr(13), enabled]),
);

export const useCategoryPerms = () => {
  const categoryPerms = usePermissionObject(...CATEGORY_PERM_KEYS);
  return formatCategoryPerms(categoryPerms);
};

export const useCategoryPermsWithTag = () => {
  const categoryPerms = usePermissionObject(...CATEGORY_PERM_KEYS_WITH_TAG);
  return formatCategoryPerms(categoryPerms);
};

// An array of urns from enabled categories
export const useCategoryPermUrns = () => {
  const catPerms = useCategoryPerms();

  return catPerms ? Object.keys(catPerms).filter((key) => catPerms[key]) : [];
};

export const useEntityImageComponent = () => {
  const [useQAImageComponent] = usePermission('feature.entity.qa-image-component');
  return useQAImageComponent ? ImageHighlightMissing : Image;
};
