import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const openstreetmapApi = createApi({
  reducerPath: 'openstreetmapApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://nominatim.openstreetmap.org' }),
  endpoints: (builder) => ({
    searchMap: builder.query({
      query: (query) => `/search?q=${query}&format=geojson&polygon_geojson=1`,
    }),
  }),
});

export const {
  useSearchMapQuery,
} = openstreetmapApi;
