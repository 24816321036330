const Bar = () => (
  <svg
    version="1.1"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m87.5 71.5h-75c-0.55469 0-1 0.44922-1 1v5c0 0.55078 0.44531 1 1 1h75c0.55469 0 1-0.44922 1-1v-5c0-0.55078-0.44531-1-1-1zm-1 5h-73v-3h73z" />
      <path d="m15 68.5h10c0.55469 0 1-0.44922 1-1v-15c0-0.55078-0.44531-1-1-1h-10c-0.55469 0-1 0.44922-1 1v15c0 0.55078 0.44531 1 1 1zm1-15h8v13h-8z" />
      <path d="m30 68.5h10c0.55469 0 1-0.44922 1-1v-22.5c0-0.55078-0.44531-1-1-1h-10c-0.55469 0-1 0.44922-1 1v22.5c0 0.55078 0.44531 1 1 1zm1-22.5h8v20.5h-8z" />
      <path d="m45 68.5h10c0.55469 0 1-0.44922 1-1v-30c0-0.55078-0.44531-1-1-1h-10c-0.55469 0-1 0.44922-1 1v30c0 0.55078 0.44531 1 1 1zm1-30h8v28h-8z" />
      <path d="m60 68.5h10c0.55469 0 1-0.44922 1-1v-37.5c0-0.55078-0.44531-1-1-1h-10c-0.55469 0-1 0.44922-1 1v37.5c0 0.55078 0.44531 1 1 1zm1-37.5h8v35.5h-8z" />
      <path d="m75 68.5h10c0.55469 0 1-0.44922 1-1v-45c0-0.55078-0.44531-1-1-1h-10c-0.55469 0-1 0.44922-1 1v45c0 0.55078 0.44531 1 1 1zm1-45h8v43h-8z" />
    </g>
  </svg>
);

export default Bar;
