import React from 'react';
import styles from './Filters.module.scss';

const FilterWrapper = ({
  children, title, variant, renderActions,
}) => {
  if (variant === 'secondary') {
    return (
      <div className={styles.body}>
        <div className={styles.secondaryHeader}>
          <h3 className={styles.secondaryTitle}>{title}</h3>
          <div>
            {!!renderActions && renderActions()}
          </div>
        </div>
        {children}
      </div>
    );
  }

  return (
    <div>
      <div className={styles.primaryHeader}>
        <div className={styles.primaryTitle}>{title}</div>
        <div>
          {!!renderActions && renderActions()}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default FilterWrapper;
