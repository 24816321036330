const ageRangeLabels = [
  { value: '24_and_younger', label: '<= 24' },
  { value: '25_to_29', label: '25-29' },
  { value: '30_to_34', label: '30-34' },
  { value: '35_and_younger', label: '<= 35', legacy: true },
  { value: '35_to_44', label: '35-44' },
  { value: '36_and_older', label: '36+', legacy: true },
  { value: '-35_and_55+', label: '-35-55+', legacy: true },
  { value: '36_to_55', label: '36-55', legacy: true },
  { value: '45_to_54', label: '45-54' },
  { value: '55_and_younger', label: '55-', legacy: true },
  { value: '55_and_older', label: '55+' },
];

export default ageRangeLabels;
