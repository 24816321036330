import React, { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { entityUrl } from '@v1/lib/urls';
import { api } from '@/constants';
import { useBatchedRequests } from '@/hooks';
import { mergeEntities } from '../store/slices/entitiesSlice';

const fetchEntities = async (entityIds) => {
  const entityUrlPath = entityUrl({ entity_ids: entityIds.join(',') });
  const url = `${api.hostname}/api/${api.server}${entityUrlPath}`;

  const response = await fetch(url);
  if (response.ok) {
    const result = await response.json();
    return result.results;
  }

  return response.text();
};

const EntityBatchedRequest = createContext(null);

export const EntityBatchedRequestProvider = ({ children }) => {
  const dispatch = useDispatch();
  const onDone = (entities) => {
    dispatch(mergeEntities(entities));
  };

  const entityBatchedRequests = useBatchedRequests({ callback: fetchEntities, onDone });
  return (
    <EntityBatchedRequest.Provider value={entityBatchedRequests}>
      {children}
    </EntityBatchedRequest.Provider>
  );
};

export const useEntityBatchedRequestsContext = () => useContext(EntityBatchedRequest);
