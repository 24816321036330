import React, { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { api } from '@/constants';
import { useBatchedRequests } from '@/hooks';
import { mergeTags } from '../store/slices/tagsSlice';

const fetchTags = async (tagIds) => {
  const url = `${api.hostname}/api/${api.server}/v2/tags?filter.ids=${tagIds.join(',')}`;

  const response = await fetch(url);
  if (response.ok) {
    const result = await response.json();
    return result?.results?.tags || [];
  }

  return response.text();
};

const TagBatchedRequest = createContext(null);

export const TagBatchedRequestProvider = ({ children }) => {
  const dispatch = useDispatch();
  const onDone = (tags) => {
    dispatch(mergeTags(tags));
  };

  const tagBatchedRequests = useBatchedRequests({ callback: fetchTags, onDone });
  return (
    <TagBatchedRequest.Provider value={tagBatchedRequests}>
      {children}
    </TagBatchedRequest.Provider>
  );
};

export const useTagBatchedRequestsContext = () => useContext(TagBatchedRequest);
