import MuiButton from '@mui/material/Button';

const TabButton = (props) => (
  <MuiButton
    variant="contained"
    shape="square"
    size="small"
    disableElevation
    {...props}
  />
);

export default TabButton;
