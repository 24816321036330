import { AlertTriangle } from 'react-feather';
import { Button, Card, ErrorFallback } from '@/components';
import extractErrorMessage from '@/utils/extractErrors';
import PanelContentSkeleton from './skeleton/PanelContentSkeleton';

import styles from './DashboardPanel.module.scss';

const ErrorCard = ({ error, resetErrorBoundary }) => {
  return (
    <Card
      className={styles.errorContainer}
      title={<><AlertTriangle color="hsl(var(--status-danger))" /> Error:</>}
      actions={[
        <Button variant="link" noPadding href="mailto:saas@qloo.com?subject=query help&body=Hi!, I need help with...">
          Contact Support
        </Button>,
        <Button onClick={resetErrorBoundary}>Retry</Button>,
      ]}
    >
      <div className={styles.errorMessage}>
        <pre>{extractErrorMessage(error)}</pre>
      </div>
    </Card>
  );
};

const PanelContentErrorFallback = (props) => (
  <ErrorFallback
    backgroundRenderer={() => <PanelContentSkeleton disabled />}
    errorRenderer={() => <ErrorCard {...props} />}
  />
);

export default PanelContentErrorFallback;
